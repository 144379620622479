import styled from 'styled-components';
import { rgba } from 'polished';

const TextArea = styled.textarea<{ hasError: boolean, disableInterActivity: boolean }>`
    width: 100%;
    min-height: 100px;
    padding: 6px !important;
    resize: none;
    position: relative;
    color: ${({ theme, hasError }) => (!hasError ? theme.colors.textColor : theme.colors.errorRed)} !important;
    border-radius: ${({ theme }) => theme.radii['br-4']};
    border: 1px solid ${({ theme, disableInterActivity }) => (disableInterActivity ? 'transparent' : theme.colors.gray)};
    border-color: ${({ theme, hasError, disableInterActivity }) => (disableInterActivity ? 'transparent' : !hasError ? theme.colors.gray : theme.colors.errorRed)} !important;
    background-color: ${({ theme, hasError, disableInterActivity }) => (disableInterActivity ? 'transparent' : theme.colors.white)} !important;
    transition: all 0.2s ease;
    transition-property: box-shadow, border-color;

    &:focus {
        border-color: transparent !important;
        box-shadow: 0 0 0px 2.5px ${({ theme, hasError, disableInterActivity }) => (disableInterActivity ? 'transparent' : !hasError ? '#2684ff' : theme.colors.errorRed)} !important;
        z-index: 10;
    }

    &::placeholder {
      padding-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${({ theme, hasError }) => (!hasError ? theme.colors.placeholder : theme.colors.errorRed)} !important;
      font-size: 13px;
    }
`;

const TextArea__Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;

export const Styled = {
  TextArea,
  TextArea__Wrapper,
};
