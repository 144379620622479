import styled, { css } from 'styled-components';
import {
  variant, space, layout, border, flexbox,
} from 'styled-system';

/** BUTTON BASE CSS * */

const ButtonBase = css`
    display: inline-flex;
    align-items:center;
    justify-content: center;
    letter-spacing: 0.75px;
    padding: 0 10px;
    border: 1px solid;
    cursor: pointer;
    outline: none;
`;

/** BUTTON VARIANTS CONFIG * */

const IconButtonSize = variant({
  prop: 'size',
  key: 'button.sizes.iconButton',
});

const IconButtonRadius = variant({
  prop: 'radius',
  key: 'button.radius.iconButton',
});

const ButtonSize = variant({
  prop: 'size',
  key: 'button.sizes.button',
});

const ButtonVariant = variant({
  prop: 'variant',
  key: 'button.variants',
});

/** BUTTON STYLES * */

const Button = styled.button<{width: any, size: string, variant: string, isLoading?: boolean}>`
    ${ButtonBase}
    ${ButtonVariant}
    ${ButtonSize}
    border-radius: ${({ theme }) => theme.radii.button};
    width: ${(props) => props.width};

    &:hover {
        background-color: ${({ theme: { button } }) => button.hover.backgroundColor};
        border-color: ${({ theme: { button } }) => button.hover.borderColor};
        color: ${({ theme: { button } }) => button.hover.color};
    }

    &:disabled {
        background-color: ${({ theme: { button } }) => button.disabled.backgroundColor} !important;
        border-color: ${({ theme: { button } }) => button.disabled.borderColor} !important;
        color: #565656 !important;
        cursor: ${(props) => (props.isLoading ? 'progress' : 'not-allowed')};

        &:hover {
          background-color: ${({ theme: { button } }) => button.disabled.backgroundColor} !important;
          border-color: ${({ theme: { button } }) => button.disabled.borderColor} !important;
          color: #565656 !important;
        }
    }

    ${border}
    ${space}
    ${layout}
    ${flexbox}
`;

const ButtonText = styled.span`
    font-size: inherit;
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 1;
`;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;

    ${Button}:disabled & {
       opacity: 0.7;
    }
`;

const IconButton = styled(Button)`
    padding: 0;
    flex-shrink: 0;
    ${IconButtonSize}
    ${IconButtonRadius}
    ${border}
`;

/** BUTTON GROUP * */

const ButtonGroup = styled.div`
   display: flex;
   ${space}

   & > button {
      margin-right: 0 !important;

      &:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
   }
`;

export const Styled = {
  Button,
  ButtonContent,
  ButtonGroup,
  ButtonText,
  IconButton,
};
