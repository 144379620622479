import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faArrowLeft,
  faArrowRightFromBracket,
  faShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useReactBasket } from '@basket/hooks/useReactBasket';
import { useNavigateWithParams } from '@ess/hooks/useNavigateWithParams';
import { useAuth } from '@ess/auth-provider';

import Drawer from '@ess/ui/Drawer';

import { User } from '@ess/components/User';

// @ts-ignore
import { ReactComponent as Logo } from '@merlin/assets/logo_white.svg';

import { Menu, MenuItem } from '../types';

import { Section } from './Section';

import { Styled } from './MobileMenu.style';

type MenuProps = {
  menu: Menu[];
  onMenuItemClick: (event: React.MouseEvent, item: MenuItem) => void;
}

const drawerStyle = {
  width: '90%',
  border: 'none',
};

const MobileMenu = ({ menu, onMenuItemClick }: MenuProps) => {
  const { t } = useTranslation();
  const { toggleBasket } = useReactBasket();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigateWithParams();
  const logout = useAuth(useShallow((state) => state.logout));
  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen((state) => !state);
  };

  const onItemClick = (event: React.MouseEvent, item: MenuItem) => {
    onMenuItemClick(event, item);
    setIsOpen(false);
  };

  return (
    <>
      <Styled.MobileMenu__Icon onClick={toggleMenu}>
        <FontAwesomeIcon
          icon={faBars}
          size="2xl"
        />
      </Styled.MobileMenu__Icon>
      <Drawer
        position="left"
        isOpen={isOpen}
        onClose={closeMenu}
        showOverlay
        containerStyles={drawerStyle}
      >
        <Styled.MobileMenu__Container>
          <Styled.MobileMenu__Header>
            <Styled.MobileMenu__Header__Logo>
              <Logo/>
            </Styled.MobileMenu__Header__Logo>
            <Styled.MobileMenu__Header__CloseIcon onClick={closeMenu}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="2xl"
              />
            </Styled.MobileMenu__Header__CloseIcon>
          </Styled.MobileMenu__Header>

          <Styled.MobileMenu__Wrapper>
            <Styled.MobileMenu__Scroll>
              <Styled.MobileMenu__User>
                <User
                  avatarSize={42}
                  displayedName="name"
                  menuEnabled={false}
                  showAgency
                />
              </Styled.MobileMenu__User>

              <Styled.MobileMenu__Sections>
                {menu.map((menuSection, parentIndex) => {
                  const parentKey = `parent-${parentIndex}`;
                  return (
                    <Section
                      key={parentKey}
                      onClick={onItemClick}
                      menu={menuSection}
                    />
                  );
                })}
              </Styled.MobileMenu__Sections>
            </Styled.MobileMenu__Scroll>
            <Styled.MobileMenu__Buttons>
              <Styled.MobileMenu__Buttons__Item onClick={() => {
                toggleBasket();
                closeMenu();
              }}
              >
                <Styled.MobileMenu__Buttons__Item__Icon>
                  <FontAwesomeIcon icon={faShoppingCart} size="xl"/>
                </Styled.MobileMenu__Buttons__Item__Icon>
                <span>{t('lbl_basket')}</span>
              </Styled.MobileMenu__Buttons__Item>
              <Styled.MobileMenu__Buttons__Item onClick={() => {
                logout({
                  onSuccess: () => navigate('/login'),
                });
              }}
              >
                <Styled.MobileMenu__Buttons__Item__Icon>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} size="xl"/>
                </Styled.MobileMenu__Buttons__Item__Icon>
                <span>{t('lbl_logout')}</span>
              </Styled.MobileMenu__Buttons__Item>
            </Styled.MobileMenu__Buttons>
          </Styled.MobileMenu__Wrapper>
        </Styled.MobileMenu__Container>
      </Drawer>
    </>
  );
};

export {
  MobileMenu,
};
