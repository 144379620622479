import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy, isArray, orderBy } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

import { CHECK_ONLINE_ENDPOINT, ONLINE_SERVICE_TIMEOUT, SEARCH_API } from '@ess/constants/api';

import { ISelectedRoom } from '@tourop/components/MultiRoom/types';

import { promiseRequest } from '@ess/v5-data-provider/request';

import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';
import Alert from '@ess/ui/Alert';
import Box from '@ess/ui/Box';
import { COMBINED_OFFERS } from '@ess/constants/offer';

type MultiRoomPreviewProps = {
  data: ISelectedRoom[];
  item: any;
  isLoading: boolean;
  offerId: string;
  fetchRooms: any;
  updateMultiRoomData: any;
};

type RoomsPaxTooltipProps = {
  roomsPax: any;
};

const RoomsPaxTooltip = ({ roomsPax }: RoomsPaxTooltipProps) => {
  const { t } = useTranslation();
  return (
    <FlexBox flexDirection="column" minWidth={150} maxWidth={250}>
      {roomsPax.map((room: any, index: number) => {
        const isLastChild = roomsPax.length - 1 === index;
        return (
          <FlexBox mb={!isLastChild ? 'small' : '0px'}>
            <FlexBox width={80} flexShrink={0}>
              <Text fontWeight="bold">{`${t('room')} ${index + 1}:`}</Text>
            </FlexBox>

            <FlexBox as="span">
              {Object.keys(room.pax).map((personType, personIndex) => {
                const isLastChild = Object.keys(room.pax).length - 1 === personIndex;
                const value = room.pax[personType].value;

                return (
                  <>
                    {value ? (
                      <>
                        {isLastChild && <Text mx="tiny">•</Text>}
                        <Text mr="tiny">{value}</Text>
                        <Text>
                          {t(`${personType.toLowerCase()}_interval`, {
                            postProcess: 'interval',
                            count: value,
                          }).toLowerCase()}
                        </Text>
                      </>
                    ) : null}
                  </>
                );
              })}
            </FlexBox>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

const MultiRoomPreview = ({
  item,
  data,
  offerId,
  fetchRooms,
  isLoading,
  updateMultiRoomData,
}: MultiRoomPreviewProps) => {
  const { t } = useTranslation();
  const [offersToCheck, setOffersToCheck] = useState<string[]>([]);
  const [details, setDetails] = useState<any[]>([]);

  const isCombinedOffer = COMBINED_OFFERS.includes(
    item?.offer?.Accommodation?.Type?.Id as string,
  );

  /**
   * Check room status handler.
   * @param offerHash
   */
  const checkAvailRequest = async (offerHash: string) => {
    const requestData = {
      actions: ['checkstatus'],
      offerIds: [offerHash],
      includeTFG: false,
    };

    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => promiseRequest(
      `${SEARCH_API}${CHECK_ONLINE_ENDPOINT}`,
      requestData,
      3,
      undefined,
      ONLINE_SERVICE_TIMEOUT,
    )
      .then((response) => {
        resolve(response?.results);
      })
      .catch((error) => {
        reject(error);
      }),
    );
  };

  const fetchRoomsHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isCombinedOffer) {
      fetchRooms(offerId);
    }
  };

  useEffect(() => {
    const grouped = groupBy(
      data,
      (room) => `${room.Offer?.Accommodation?.Room?.Id}_${room.Offer?.Accommodation?.Service?.Id}`,
    );
    const multiRoomDetails = Object.keys(grouped).map((item, index) => {
      const roomsPax = grouped[item].map((item) => item.Group);
      const total = roomsPax.reduce(
        (acc, room) => {
          const { Adult, Child } = room.pax;

          return {
            ...acc,
            Adult: Adult.value + acc.Adult,
            Child: Child.value + acc.Child,
          };
        },
        { Adult: 0, Child: 0 },
      );

      return {
        amount: grouped[item].length,
        participants: {
          roomsPax,
          total,
        },
        name: grouped[item][0].Offer.Accommodation?.Room?.Name,
        service: grouped[item][0].Offer.Accommodation?.Service?.Name,
        key: `${index}_${grouped[item][0].Offer.Base?.OfferId}`,
      };
    });

    const sortedByAmount = orderBy(multiRoomDetails, ['amount'], ['desc']);

    setDetails(sortedByAmount);
  }, [data]);

  useEffect(() => {
    if (offersToCheck.length) {
      (async () => {
        Promise.all(offersToCheck.map((item) => checkAvailRequest(item))).then(
          (results: any) => {
            const selectedRooms = data.map((selectedRoom: any, index) => {
              const item = results[index][0].offer;
              return {
                ...selectedRoom,
                Group: {
                  ...selectedRoom.Group,
                  description: `${t('room')} ${index + 1}`,
                },
                Offer: {
                  ...selectedRoom.Offer,
                  Base: {
                    ...selectedRoom.Offer.Base,
                    Availability: {
                      ...selectedRoom.Offer.Base.Availability,
                      ...item.Base.Availability,
                    },
                    Price: {
                      ...selectedRoom.Offer.Base.Price,
                      ...(item?.Base?.Price
                        ? {
                          FirstPerson: {
                            ...selectedRoom.Offer.Base.Price.FirstPerson,
                            amount: item.Base.Price.FirstPerson.amount,
                          },
                          Total: {
                            ...selectedRoom.Offer.Base.Price.Total,
                            amount: item.Base.Price.Total.amount,
                          },
                        }
                        : {}),
                    },
                  },
                },
              };
            });

            updateMultiRoomData(offerId, selectedRooms);
          },
        );
      })();
    }
  }, [offersToCheck]);

  useEffect(() => {
    const offersToCheck = data
      ? data.filter(
        (item: ISelectedRoom) => item.Offer.Base?.Availability === undefined,
      )
      : [];
    setOffersToCheck(
      offersToCheck.map((item) => item.Offer.Base?.OfferId as string),
    );
  }, [data]);

  if (isCombinedOffer) {
    return (
      <FlexBox mt="tiny">
        <Alert size="small" showBorder={false} severity="error">
          {t('lbl_multi_room_unavailable_for_offers_with_extra_hotel')}
        </Alert>
      </FlexBox>
    );
  }

  if (isLoading) {
    return (
      <FlexBox alignItems="center" height={30} width="100%">
        <Loader type="dots" size="20px" />
        <Text fontSize="12px" ml="small">
          {t('checking_recommended_rooms')}
        </Text>
      </FlexBox>
    );
  }

  return details.length > 0 ? (
    <FlexBox flexDirection="column">
      {details.map((item: any) => (
        <FlexBox key={item.key} flexDirection="column" mt="tiny">
          <FlexBox alignItems="flex-start">
            <FlexBox
              mr="tiny"
              alignItems="center"
              flexShrink={0}
              justifyContent="center"
              style={{
                width: 20,
                height: 20,
                backgroundColor: '#ddd',
                borderRadius: 4,
              }}
            >
              <Text fontSize={12} color="#333333">{`${item.amount}x`}</Text>
            </FlexBox>
            <Box>
              <Text as="span" fontWeight="bold">{`${item.name}`}</Text>
              <Text as="span" mx="tiny">
                /
              </Text>
              <Text as="span">{item.service}</Text>
              <Text as="span" mx="tiny">
                /
              </Text>
              <FlexBox alignItems="center" display="inline-flex" as="span">
                <Tooltip
                  content={
                    <RoomsPaxTooltip roomsPax={item.participants.roomsPax} />
                  }
                >
                  <Box>
                    <Text fontSize={11} as="span" mr="tiny">
                      <FontAwesomeIcon icon={faUser} />
                    </Text>
                    {Object.keys(item.participants.total).map(
                      (personType, index) => {
                        const isLastChild = Object.keys(item.participants.total).length - 1
                          === index;

                        return (
                          <FlexBox
                            alignItems="center"
                            display="inline-flex"
                            as="span"
                          >
                            <Text as="span">
                              {item.participants.total[personType]}
                            </Text>
                            {!isLastChild && <Text as="span">:</Text>}
                          </FlexBox>
                        );
                      },
                    )}
                  </Box>
                </Tooltip>
              </FlexBox>
            </Box>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
  ) : !isLoading && isArray(data) && !data.length ? (
    <FlexBox mt="tiny">
      <Alert size="small" showBorder={false} severity="error">
        {t('not_all_groups_found')}
      </Alert>
    </FlexBox>
  ) : (
    <FlexBox mt="tiny" onClick={fetchRoomsHandler}>
      <Alert size="small" showBorder={false} severity="info">
        {t('show_recommended_rooms')}
      </Alert>
    </FlexBox>
  );
};

export default MultiRoomPreview;
