/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const SearchForm = $root.SearchForm = (() => {

    /**
     * Properties of a SearchForm.
     * @exports ISearchForm
     * @interface ISearchForm
     * @property {Array.<string>|null} [Base_Operator] SearchForm Base_Operator
     * @property {Array.<string>|null} [Base_DestinationLocation_Country] SearchForm Base_DestinationLocation_Country
     * @property {Array.<string>|null} [Base_DestinationLocation_Region] SearchForm Base_DestinationLocation_Region
     * @property {Array.<string>|null} [Base_ComponentsCombinations_Transport] SearchForm Base_ComponentsCombinations_Transport
     * @property {Array.<string>|null} [Base_DepartureLocation] SearchForm Base_DepartureLocation
     * @property {Array.<string>|null} [Base_ComponentsCombinations_ObjectType] SearchForm Base_ComponentsCombinations_ObjectType
     * @property {number|null} [Custom_TripRange] SearchForm Custom_TripRange
     * @property {Array.<number>|null} [Base_NightsBeforeReturn] SearchForm Base_NightsBeforeReturn
     * @property {Array.<number>|null} [Accommodation_XService] SearchForm Accommodation_XService
     * @property {string|null} [Base_Catalog] SearchForm Base_Catalog
     * @property {SearchForm.IRangeFloat|null} [Accommodation_Rating] SearchForm Accommodation_Rating
     * @property {SearchForm.IRangeFloat|null} [Accommodation_Category] SearchForm Accommodation_Category
     * @property {Array.<SearchForm.DayOfWeek>|null} [Base_StartDateDayOfWeek] SearchForm Base_StartDateDayOfWeek
     * @property {string|null} [Custom_HotelName] SearchForm Custom_HotelName
     * @property {Array.<number>|null} [Custom_AgentAttributes] SearchForm Custom_AgentAttributes
     * @property {number|null} [Base_Candy] SearchForm Base_Candy
     * @property {string|null} [Base_Promotion] SearchForm Base_Promotion
     * @property {SearchForm.IRooms|null} [Base_ParticipantsList] SearchForm Base_ParticipantsList
     * @property {Array.<SearchForm.Attribute>|null} [Accommodation_Attributes] SearchForm Accommodation_Attributes
     * @property {Array.<SearchForm.Availability>|null} [Custom_Availability] SearchForm Custom_Availability
     * @property {boolean|null} [Custom_Senior] SearchForm Custom_Senior
     * @property {boolean|null} [Accommodation_BookBeds] SearchForm Accommodation_BookBeds
     * @property {boolean|null} [Content_DescriptionRequired] SearchForm Content_DescriptionRequired
     * @property {boolean|null} [Content_PhotosRequired] SearchForm Content_PhotosRequired
     * @property {boolean|null} [Custom_Rating] SearchForm Custom_Rating
     * @property {boolean|null} [Custom_DistanceToBeach] SearchForm Custom_DistanceToBeach
     * @property {SearchForm.IRangeInt|null} [Accommodation_DistanceToBeach] SearchForm Accommodation_DistanceToBeach
     * @property {SearchForm.IRangeInt|null} [Accommodation_DistanceToSlope] SearchForm Accommodation_DistanceToSlope
     * @property {number|null} [Custom_FullPrice] SearchForm Custom_FullPrice
     * @property {SearchForm.IPriceRanges|null} [Custom_PriceRange] SearchForm Custom_PriceRange
     * @property {string|null} [Custom_StartDateRange_StartDateFrom] SearchForm Custom_StartDateRange_StartDateFrom
     * @property {string|null} [Custom_StartDateRange_StartDateTo] SearchForm Custom_StartDateRange_StartDateTo
     * @property {Array.<string>|null} [Accommodation_ExtAgentAttributes] SearchForm Accommodation_ExtAgentAttributes
     * @property {Array.<number>|null} [Accommodation_Camp_Theme] SearchForm Accommodation_Camp_Theme
     * @property {Array.<boolean>|null} [Base_DatasetInfo_Private] SearchForm Base_DatasetInfo_Private
     * @property {Array.<string>|null} [Custom_OfferAttributes] SearchForm Custom_OfferAttributes
     * @property {string|null} [Base_XCity] SearchForm Base_XCity
     * @property {Array.<number>|null} [Accommodation_XCode] SearchForm Accommodation_XCode
     * @property {Array.<string>|null} [Accommodation_Rooms] SearchForm Accommodation_Rooms
     * @property {string|null} [Accommodation_BuildRenovationYear] SearchForm Accommodation_BuildRenovationYear
     */

    /**
     * Constructs a new SearchForm.
     * @exports SearchForm
     * @classdesc Represents a SearchForm.
     * @implements ISearchForm
     * @constructor
     * @param {ISearchForm=} [properties] Properties to set
     */
    function SearchForm(properties) {
        this.Base_Operator = [];
        this.Base_DestinationLocation_Country = [];
        this.Base_DestinationLocation_Region = [];
        this.Base_ComponentsCombinations_Transport = [];
        this.Base_DepartureLocation = [];
        this.Base_ComponentsCombinations_ObjectType = [];
        this.Base_NightsBeforeReturn = [];
        this.Accommodation_XService = [];
        this.Base_StartDateDayOfWeek = [];
        this.Custom_AgentAttributes = [];
        this.Accommodation_Attributes = [];
        this.Custom_Availability = [];
        this.Accommodation_ExtAgentAttributes = [];
        this.Accommodation_Camp_Theme = [];
        this.Base_DatasetInfo_Private = [];
        this.Custom_OfferAttributes = [];
        this.Accommodation_XCode = [];
        this.Accommodation_Rooms = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SearchForm Base_Operator.
     * @member {Array.<string>} Base_Operator
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_Operator = $util.emptyArray;

    /**
     * SearchForm Base_DestinationLocation_Country.
     * @member {Array.<string>} Base_DestinationLocation_Country
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_DestinationLocation_Country = $util.emptyArray;

    /**
     * SearchForm Base_DestinationLocation_Region.
     * @member {Array.<string>} Base_DestinationLocation_Region
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_DestinationLocation_Region = $util.emptyArray;

    /**
     * SearchForm Base_ComponentsCombinations_Transport.
     * @member {Array.<string>} Base_ComponentsCombinations_Transport
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_ComponentsCombinations_Transport = $util.emptyArray;

    /**
     * SearchForm Base_DepartureLocation.
     * @member {Array.<string>} Base_DepartureLocation
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_DepartureLocation = $util.emptyArray;

    /**
     * SearchForm Base_ComponentsCombinations_ObjectType.
     * @member {Array.<string>} Base_ComponentsCombinations_ObjectType
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_ComponentsCombinations_ObjectType = $util.emptyArray;

    /**
     * SearchForm Custom_TripRange.
     * @member {number} Custom_TripRange
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_TripRange = 0;

    /**
     * SearchForm Base_NightsBeforeReturn.
     * @member {Array.<number>} Base_NightsBeforeReturn
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_NightsBeforeReturn = $util.emptyArray;

    /**
     * SearchForm Accommodation_XService.
     * @member {Array.<number>} Accommodation_XService
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_XService = $util.emptyArray;

    /**
     * SearchForm Base_Catalog.
     * @member {string} Base_Catalog
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_Catalog = "";

    /**
     * SearchForm Accommodation_Rating.
     * @member {SearchForm.IRangeFloat|null|undefined} Accommodation_Rating
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_Rating = null;

    /**
     * SearchForm Accommodation_Category.
     * @member {SearchForm.IRangeFloat|null|undefined} Accommodation_Category
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_Category = null;

    /**
     * SearchForm Base_StartDateDayOfWeek.
     * @member {Array.<SearchForm.DayOfWeek>} Base_StartDateDayOfWeek
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_StartDateDayOfWeek = $util.emptyArray;

    /**
     * SearchForm Custom_HotelName.
     * @member {string} Custom_HotelName
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_HotelName = "";

    /**
     * SearchForm Custom_AgentAttributes.
     * @member {Array.<number>} Custom_AgentAttributes
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_AgentAttributes = $util.emptyArray;

    /**
     * SearchForm Base_Candy.
     * @member {number} Base_Candy
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_Candy = 0;

    /**
     * SearchForm Base_Promotion.
     * @member {string} Base_Promotion
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_Promotion = "";

    /**
     * SearchForm Base_ParticipantsList.
     * @member {SearchForm.IRooms|null|undefined} Base_ParticipantsList
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_ParticipantsList = null;

    /**
     * SearchForm Accommodation_Attributes.
     * @member {Array.<SearchForm.Attribute>} Accommodation_Attributes
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_Attributes = $util.emptyArray;

    /**
     * SearchForm Custom_Availability.
     * @member {Array.<SearchForm.Availability>} Custom_Availability
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_Availability = $util.emptyArray;

    /**
     * SearchForm Custom_Senior.
     * @member {boolean} Custom_Senior
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_Senior = false;

    /**
     * SearchForm Accommodation_BookBeds.
     * @member {boolean} Accommodation_BookBeds
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_BookBeds = false;

    /**
     * SearchForm Content_DescriptionRequired.
     * @member {boolean} Content_DescriptionRequired
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Content_DescriptionRequired = false;

    /**
     * SearchForm Content_PhotosRequired.
     * @member {boolean} Content_PhotosRequired
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Content_PhotosRequired = false;

    /**
     * SearchForm Custom_Rating.
     * @member {boolean} Custom_Rating
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_Rating = false;

    /**
     * SearchForm Custom_DistanceToBeach.
     * @member {boolean} Custom_DistanceToBeach
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_DistanceToBeach = false;

    /**
     * SearchForm Accommodation_DistanceToBeach.
     * @member {SearchForm.IRangeInt|null|undefined} Accommodation_DistanceToBeach
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_DistanceToBeach = null;

    /**
     * SearchForm Accommodation_DistanceToSlope.
     * @member {SearchForm.IRangeInt|null|undefined} Accommodation_DistanceToSlope
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_DistanceToSlope = null;

    /**
     * SearchForm Custom_FullPrice.
     * @member {number} Custom_FullPrice
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_FullPrice = 0;

    /**
     * SearchForm Custom_PriceRange.
     * @member {SearchForm.IPriceRanges|null|undefined} Custom_PriceRange
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_PriceRange = null;

    /**
     * SearchForm Custom_StartDateRange_StartDateFrom.
     * @member {string} Custom_StartDateRange_StartDateFrom
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_StartDateRange_StartDateFrom = "";

    /**
     * SearchForm Custom_StartDateRange_StartDateTo.
     * @member {string} Custom_StartDateRange_StartDateTo
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_StartDateRange_StartDateTo = "";

    /**
     * SearchForm Accommodation_ExtAgentAttributes.
     * @member {Array.<string>} Accommodation_ExtAgentAttributes
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_ExtAgentAttributes = $util.emptyArray;

    /**
     * SearchForm Accommodation_Camp_Theme.
     * @member {Array.<number>} Accommodation_Camp_Theme
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_Camp_Theme = $util.emptyArray;

    /**
     * SearchForm Base_DatasetInfo_Private.
     * @member {Array.<boolean>} Base_DatasetInfo_Private
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_DatasetInfo_Private = $util.emptyArray;

    /**
     * SearchForm Custom_OfferAttributes.
     * @member {Array.<string>} Custom_OfferAttributes
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Custom_OfferAttributes = $util.emptyArray;

    /**
     * SearchForm Base_XCity.
     * @member {string} Base_XCity
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Base_XCity = "";

    /**
     * SearchForm Accommodation_XCode.
     * @member {Array.<number>} Accommodation_XCode
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_XCode = $util.emptyArray;

    /**
     * SearchForm Accommodation_Rooms.
     * @member {Array.<string>} Accommodation_Rooms
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_Rooms = $util.emptyArray;

    /**
     * SearchForm Accommodation_BuildRenovationYear.
     * @member {string} Accommodation_BuildRenovationYear
     * @memberof SearchForm
     * @instance
     */
    SearchForm.prototype.Accommodation_BuildRenovationYear = "";

    /**
     * Creates a new SearchForm instance using the specified properties.
     * @function create
     * @memberof SearchForm
     * @static
     * @param {ISearchForm=} [properties] Properties to set
     * @returns {SearchForm} SearchForm instance
     */
    SearchForm.create = function create(properties) {
        return new SearchForm(properties);
    };

    /**
     * Encodes the specified SearchForm message. Does not implicitly {@link SearchForm.verify|verify} messages.
     * @function encode
     * @memberof SearchForm
     * @static
     * @param {ISearchForm} message SearchForm message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SearchForm.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Base_Operator != null && message.Base_Operator.length)
            for (let i = 0; i < message.Base_Operator.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Base_Operator[i]);
        if (message.Base_DestinationLocation_Country != null && message.Base_DestinationLocation_Country.length)
            for (let i = 0; i < message.Base_DestinationLocation_Country.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Base_DestinationLocation_Country[i]);
        if (message.Base_DestinationLocation_Region != null && message.Base_DestinationLocation_Region.length)
            for (let i = 0; i < message.Base_DestinationLocation_Region.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Base_DestinationLocation_Region[i]);
        if (message.Base_ComponentsCombinations_Transport != null && message.Base_ComponentsCombinations_Transport.length)
            for (let i = 0; i < message.Base_ComponentsCombinations_Transport.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.Base_ComponentsCombinations_Transport[i]);
        if (message.Base_DepartureLocation != null && message.Base_DepartureLocation.length)
            for (let i = 0; i < message.Base_DepartureLocation.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.Base_DepartureLocation[i]);
        if (message.Base_ComponentsCombinations_ObjectType != null && message.Base_ComponentsCombinations_ObjectType.length)
            for (let i = 0; i < message.Base_ComponentsCombinations_ObjectType.length; ++i)
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.Base_ComponentsCombinations_ObjectType[i]);
        if (message.Custom_TripRange != null && Object.hasOwnProperty.call(message, "Custom_TripRange"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.Custom_TripRange);
        if (message.Base_NightsBeforeReturn != null && message.Base_NightsBeforeReturn.length) {
            writer.uint32(/* id 8, wireType 2 =*/66).fork();
            for (let i = 0; i < message.Base_NightsBeforeReturn.length; ++i)
                writer.int32(message.Base_NightsBeforeReturn[i]);
            writer.ldelim();
        }
        if (message.Accommodation_XService != null && message.Accommodation_XService.length) {
            writer.uint32(/* id 9, wireType 2 =*/74).fork();
            for (let i = 0; i < message.Accommodation_XService.length; ++i)
                writer.int32(message.Accommodation_XService[i]);
            writer.ldelim();
        }
        if (message.Base_Catalog != null && Object.hasOwnProperty.call(message, "Base_Catalog"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.Base_Catalog);
        if (message.Accommodation_Rating != null && Object.hasOwnProperty.call(message, "Accommodation_Rating"))
            $root.SearchForm.RangeFloat.encode(message.Accommodation_Rating, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.Accommodation_Category != null && Object.hasOwnProperty.call(message, "Accommodation_Category"))
            $root.SearchForm.RangeFloat.encode(message.Accommodation_Category, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.Base_StartDateDayOfWeek != null && message.Base_StartDateDayOfWeek.length) {
            writer.uint32(/* id 13, wireType 2 =*/106).fork();
            for (let i = 0; i < message.Base_StartDateDayOfWeek.length; ++i)
                writer.int32(message.Base_StartDateDayOfWeek[i]);
            writer.ldelim();
        }
        if (message.Custom_HotelName != null && Object.hasOwnProperty.call(message, "Custom_HotelName"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.Custom_HotelName);
        if (message.Custom_AgentAttributes != null && message.Custom_AgentAttributes.length) {
            writer.uint32(/* id 15, wireType 2 =*/122).fork();
            for (let i = 0; i < message.Custom_AgentAttributes.length; ++i)
                writer.int32(message.Custom_AgentAttributes[i]);
            writer.ldelim();
        }
        if (message.Base_Candy != null && Object.hasOwnProperty.call(message, "Base_Candy"))
            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.Base_Candy);
        if (message.Base_Promotion != null && Object.hasOwnProperty.call(message, "Base_Promotion"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.Base_Promotion);
        if (message.Base_ParticipantsList != null && Object.hasOwnProperty.call(message, "Base_ParticipantsList"))
            $root.SearchForm.Rooms.encode(message.Base_ParticipantsList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.Accommodation_Attributes != null && message.Accommodation_Attributes.length) {
            writer.uint32(/* id 19, wireType 2 =*/154).fork();
            for (let i = 0; i < message.Accommodation_Attributes.length; ++i)
                writer.int32(message.Accommodation_Attributes[i]);
            writer.ldelim();
        }
        if (message.Custom_Availability != null && message.Custom_Availability.length) {
            writer.uint32(/* id 20, wireType 2 =*/162).fork();
            for (let i = 0; i < message.Custom_Availability.length; ++i)
                writer.int32(message.Custom_Availability[i]);
            writer.ldelim();
        }
        if (message.Custom_Senior != null && Object.hasOwnProperty.call(message, "Custom_Senior"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.Custom_Senior);
        if (message.Accommodation_BookBeds != null && Object.hasOwnProperty.call(message, "Accommodation_BookBeds"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.Accommodation_BookBeds);
        if (message.Content_DescriptionRequired != null && Object.hasOwnProperty.call(message, "Content_DescriptionRequired"))
            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.Content_DescriptionRequired);
        if (message.Content_PhotosRequired != null && Object.hasOwnProperty.call(message, "Content_PhotosRequired"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.Content_PhotosRequired);
        if (message.Custom_Rating != null && Object.hasOwnProperty.call(message, "Custom_Rating"))
            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.Custom_Rating);
        if (message.Custom_DistanceToBeach != null && Object.hasOwnProperty.call(message, "Custom_DistanceToBeach"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.Custom_DistanceToBeach);
        if (message.Accommodation_DistanceToBeach != null && Object.hasOwnProperty.call(message, "Accommodation_DistanceToBeach"))
            $root.SearchForm.RangeInt.encode(message.Accommodation_DistanceToBeach, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.Accommodation_DistanceToSlope != null && Object.hasOwnProperty.call(message, "Accommodation_DistanceToSlope"))
            $root.SearchForm.RangeInt.encode(message.Accommodation_DistanceToSlope, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
        if (message.Custom_FullPrice != null && Object.hasOwnProperty.call(message, "Custom_FullPrice"))
            writer.uint32(/* id 29, wireType 0 =*/232).int32(message.Custom_FullPrice);
        if (message.Custom_PriceRange != null && Object.hasOwnProperty.call(message, "Custom_PriceRange"))
            $root.SearchForm.PriceRanges.encode(message.Custom_PriceRange, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.Custom_StartDateRange_StartDateFrom != null && Object.hasOwnProperty.call(message, "Custom_StartDateRange_StartDateFrom"))
            writer.uint32(/* id 31, wireType 2 =*/250).string(message.Custom_StartDateRange_StartDateFrom);
        if (message.Custom_StartDateRange_StartDateTo != null && Object.hasOwnProperty.call(message, "Custom_StartDateRange_StartDateTo"))
            writer.uint32(/* id 32, wireType 2 =*/258).string(message.Custom_StartDateRange_StartDateTo);
        if (message.Accommodation_ExtAgentAttributes != null && message.Accommodation_ExtAgentAttributes.length)
            for (let i = 0; i < message.Accommodation_ExtAgentAttributes.length; ++i)
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.Accommodation_ExtAgentAttributes[i]);
        if (message.Accommodation_Camp_Theme != null && message.Accommodation_Camp_Theme.length) {
            writer.uint32(/* id 34, wireType 2 =*/274).fork();
            for (let i = 0; i < message.Accommodation_Camp_Theme.length; ++i)
                writer.int32(message.Accommodation_Camp_Theme[i]);
            writer.ldelim();
        }
        if (message.Base_DatasetInfo_Private != null && message.Base_DatasetInfo_Private.length) {
            writer.uint32(/* id 35, wireType 2 =*/282).fork();
            for (let i = 0; i < message.Base_DatasetInfo_Private.length; ++i)
                writer.bool(message.Base_DatasetInfo_Private[i]);
            writer.ldelim();
        }
        if (message.Custom_OfferAttributes != null && message.Custom_OfferAttributes.length)
            for (let i = 0; i < message.Custom_OfferAttributes.length; ++i)
                writer.uint32(/* id 36, wireType 2 =*/290).string(message.Custom_OfferAttributes[i]);
        if (message.Base_XCity != null && Object.hasOwnProperty.call(message, "Base_XCity"))
            writer.uint32(/* id 37, wireType 2 =*/298).string(message.Base_XCity);
        if (message.Accommodation_XCode != null && message.Accommodation_XCode.length) {
            writer.uint32(/* id 38, wireType 2 =*/306).fork();
            for (let i = 0; i < message.Accommodation_XCode.length; ++i)
                writer.int32(message.Accommodation_XCode[i]);
            writer.ldelim();
        }
        if (message.Accommodation_Rooms != null && message.Accommodation_Rooms.length)
            for (let i = 0; i < message.Accommodation_Rooms.length; ++i)
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.Accommodation_Rooms[i]);
        if (message.Accommodation_BuildRenovationYear != null && Object.hasOwnProperty.call(message, "Accommodation_BuildRenovationYear"))
            writer.uint32(/* id 40, wireType 2 =*/322).string(message.Accommodation_BuildRenovationYear);
        return writer;
    };

    /**
     * Encodes the specified SearchForm message, length delimited. Does not implicitly {@link SearchForm.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SearchForm
     * @static
     * @param {ISearchForm} message SearchForm message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SearchForm.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SearchForm message from the specified reader or buffer.
     * @function decode
     * @memberof SearchForm
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SearchForm} SearchForm
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SearchForm.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Base_Operator && message.Base_Operator.length))
                        message.Base_Operator = [];
                    message.Base_Operator.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.Base_DestinationLocation_Country && message.Base_DestinationLocation_Country.length))
                        message.Base_DestinationLocation_Country = [];
                    message.Base_DestinationLocation_Country.push(reader.string());
                    break;
                }
            case 3: {
                    if (!(message.Base_DestinationLocation_Region && message.Base_DestinationLocation_Region.length))
                        message.Base_DestinationLocation_Region = [];
                    message.Base_DestinationLocation_Region.push(reader.string());
                    break;
                }
            case 4: {
                    if (!(message.Base_ComponentsCombinations_Transport && message.Base_ComponentsCombinations_Transport.length))
                        message.Base_ComponentsCombinations_Transport = [];
                    message.Base_ComponentsCombinations_Transport.push(reader.string());
                    break;
                }
            case 5: {
                    if (!(message.Base_DepartureLocation && message.Base_DepartureLocation.length))
                        message.Base_DepartureLocation = [];
                    message.Base_DepartureLocation.push(reader.string());
                    break;
                }
            case 6: {
                    if (!(message.Base_ComponentsCombinations_ObjectType && message.Base_ComponentsCombinations_ObjectType.length))
                        message.Base_ComponentsCombinations_ObjectType = [];
                    message.Base_ComponentsCombinations_ObjectType.push(reader.string());
                    break;
                }
            case 7: {
                    message.Custom_TripRange = reader.int32();
                    break;
                }
            case 8: {
                    if (!(message.Base_NightsBeforeReturn && message.Base_NightsBeforeReturn.length))
                        message.Base_NightsBeforeReturn = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Base_NightsBeforeReturn.push(reader.int32());
                    } else
                        message.Base_NightsBeforeReturn.push(reader.int32());
                    break;
                }
            case 9: {
                    if (!(message.Accommodation_XService && message.Accommodation_XService.length))
                        message.Accommodation_XService = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Accommodation_XService.push(reader.int32());
                    } else
                        message.Accommodation_XService.push(reader.int32());
                    break;
                }
            case 10: {
                    message.Base_Catalog = reader.string();
                    break;
                }
            case 11: {
                    message.Accommodation_Rating = $root.SearchForm.RangeFloat.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.Accommodation_Category = $root.SearchForm.RangeFloat.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    if (!(message.Base_StartDateDayOfWeek && message.Base_StartDateDayOfWeek.length))
                        message.Base_StartDateDayOfWeek = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Base_StartDateDayOfWeek.push(reader.int32());
                    } else
                        message.Base_StartDateDayOfWeek.push(reader.int32());
                    break;
                }
            case 14: {
                    message.Custom_HotelName = reader.string();
                    break;
                }
            case 15: {
                    if (!(message.Custom_AgentAttributes && message.Custom_AgentAttributes.length))
                        message.Custom_AgentAttributes = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Custom_AgentAttributes.push(reader.int32());
                    } else
                        message.Custom_AgentAttributes.push(reader.int32());
                    break;
                }
            case 16: {
                    message.Base_Candy = reader.int32();
                    break;
                }
            case 17: {
                    message.Base_Promotion = reader.string();
                    break;
                }
            case 18: {
                    message.Base_ParticipantsList = $root.SearchForm.Rooms.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    if (!(message.Accommodation_Attributes && message.Accommodation_Attributes.length))
                        message.Accommodation_Attributes = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Accommodation_Attributes.push(reader.int32());
                    } else
                        message.Accommodation_Attributes.push(reader.int32());
                    break;
                }
            case 20: {
                    if (!(message.Custom_Availability && message.Custom_Availability.length))
                        message.Custom_Availability = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Custom_Availability.push(reader.int32());
                    } else
                        message.Custom_Availability.push(reader.int32());
                    break;
                }
            case 21: {
                    message.Custom_Senior = reader.bool();
                    break;
                }
            case 22: {
                    message.Accommodation_BookBeds = reader.bool();
                    break;
                }
            case 23: {
                    message.Content_DescriptionRequired = reader.bool();
                    break;
                }
            case 24: {
                    message.Content_PhotosRequired = reader.bool();
                    break;
                }
            case 25: {
                    message.Custom_Rating = reader.bool();
                    break;
                }
            case 26: {
                    message.Custom_DistanceToBeach = reader.bool();
                    break;
                }
            case 27: {
                    message.Accommodation_DistanceToBeach = $root.SearchForm.RangeInt.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    message.Accommodation_DistanceToSlope = $root.SearchForm.RangeInt.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.Custom_FullPrice = reader.int32();
                    break;
                }
            case 30: {
                    message.Custom_PriceRange = $root.SearchForm.PriceRanges.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.Custom_StartDateRange_StartDateFrom = reader.string();
                    break;
                }
            case 32: {
                    message.Custom_StartDateRange_StartDateTo = reader.string();
                    break;
                }
            case 33: {
                    if (!(message.Accommodation_ExtAgentAttributes && message.Accommodation_ExtAgentAttributes.length))
                        message.Accommodation_ExtAgentAttributes = [];
                    message.Accommodation_ExtAgentAttributes.push(reader.string());
                    break;
                }
            case 34: {
                    if (!(message.Accommodation_Camp_Theme && message.Accommodation_Camp_Theme.length))
                        message.Accommodation_Camp_Theme = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Accommodation_Camp_Theme.push(reader.int32());
                    } else
                        message.Accommodation_Camp_Theme.push(reader.int32());
                    break;
                }
            case 35: {
                    if (!(message.Base_DatasetInfo_Private && message.Base_DatasetInfo_Private.length))
                        message.Base_DatasetInfo_Private = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Base_DatasetInfo_Private.push(reader.bool());
                    } else
                        message.Base_DatasetInfo_Private.push(reader.bool());
                    break;
                }
            case 36: {
                    if (!(message.Custom_OfferAttributes && message.Custom_OfferAttributes.length))
                        message.Custom_OfferAttributes = [];
                    message.Custom_OfferAttributes.push(reader.string());
                    break;
                }
            case 37: {
                    message.Base_XCity = reader.string();
                    break;
                }
            case 38: {
                    if (!(message.Accommodation_XCode && message.Accommodation_XCode.length))
                        message.Accommodation_XCode = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Accommodation_XCode.push(reader.int32());
                    } else
                        message.Accommodation_XCode.push(reader.int32());
                    break;
                }
            case 39: {
                    if (!(message.Accommodation_Rooms && message.Accommodation_Rooms.length))
                        message.Accommodation_Rooms = [];
                    message.Accommodation_Rooms.push(reader.string());
                    break;
                }
            case 40: {
                    message.Accommodation_BuildRenovationYear = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SearchForm message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SearchForm
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SearchForm} SearchForm
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SearchForm.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SearchForm message.
     * @function verify
     * @memberof SearchForm
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SearchForm.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Base_Operator != null && message.hasOwnProperty("Base_Operator")) {
            if (!Array.isArray(message.Base_Operator))
                return "Base_Operator: array expected";
            for (let i = 0; i < message.Base_Operator.length; ++i)
                if (!$util.isString(message.Base_Operator[i]))
                    return "Base_Operator: string[] expected";
        }
        if (message.Base_DestinationLocation_Country != null && message.hasOwnProperty("Base_DestinationLocation_Country")) {
            if (!Array.isArray(message.Base_DestinationLocation_Country))
                return "Base_DestinationLocation_Country: array expected";
            for (let i = 0; i < message.Base_DestinationLocation_Country.length; ++i)
                if (!$util.isString(message.Base_DestinationLocation_Country[i]))
                    return "Base_DestinationLocation_Country: string[] expected";
        }
        if (message.Base_DestinationLocation_Region != null && message.hasOwnProperty("Base_DestinationLocation_Region")) {
            if (!Array.isArray(message.Base_DestinationLocation_Region))
                return "Base_DestinationLocation_Region: array expected";
            for (let i = 0; i < message.Base_DestinationLocation_Region.length; ++i)
                if (!$util.isString(message.Base_DestinationLocation_Region[i]))
                    return "Base_DestinationLocation_Region: string[] expected";
        }
        if (message.Base_ComponentsCombinations_Transport != null && message.hasOwnProperty("Base_ComponentsCombinations_Transport")) {
            if (!Array.isArray(message.Base_ComponentsCombinations_Transport))
                return "Base_ComponentsCombinations_Transport: array expected";
            for (let i = 0; i < message.Base_ComponentsCombinations_Transport.length; ++i)
                if (!$util.isString(message.Base_ComponentsCombinations_Transport[i]))
                    return "Base_ComponentsCombinations_Transport: string[] expected";
        }
        if (message.Base_DepartureLocation != null && message.hasOwnProperty("Base_DepartureLocation")) {
            if (!Array.isArray(message.Base_DepartureLocation))
                return "Base_DepartureLocation: array expected";
            for (let i = 0; i < message.Base_DepartureLocation.length; ++i)
                if (!$util.isString(message.Base_DepartureLocation[i]))
                    return "Base_DepartureLocation: string[] expected";
        }
        if (message.Base_ComponentsCombinations_ObjectType != null && message.hasOwnProperty("Base_ComponentsCombinations_ObjectType")) {
            if (!Array.isArray(message.Base_ComponentsCombinations_ObjectType))
                return "Base_ComponentsCombinations_ObjectType: array expected";
            for (let i = 0; i < message.Base_ComponentsCombinations_ObjectType.length; ++i)
                if (!$util.isString(message.Base_ComponentsCombinations_ObjectType[i]))
                    return "Base_ComponentsCombinations_ObjectType: string[] expected";
        }
        if (message.Custom_TripRange != null && message.hasOwnProperty("Custom_TripRange"))
            if (!$util.isInteger(message.Custom_TripRange))
                return "Custom_TripRange: integer expected";
        if (message.Base_NightsBeforeReturn != null && message.hasOwnProperty("Base_NightsBeforeReturn")) {
            if (!Array.isArray(message.Base_NightsBeforeReturn))
                return "Base_NightsBeforeReturn: array expected";
            for (let i = 0; i < message.Base_NightsBeforeReturn.length; ++i)
                if (!$util.isInteger(message.Base_NightsBeforeReturn[i]))
                    return "Base_NightsBeforeReturn: integer[] expected";
        }
        if (message.Accommodation_XService != null && message.hasOwnProperty("Accommodation_XService")) {
            if (!Array.isArray(message.Accommodation_XService))
                return "Accommodation_XService: array expected";
            for (let i = 0; i < message.Accommodation_XService.length; ++i)
                if (!$util.isInteger(message.Accommodation_XService[i]))
                    return "Accommodation_XService: integer[] expected";
        }
        if (message.Base_Catalog != null && message.hasOwnProperty("Base_Catalog"))
            if (!$util.isString(message.Base_Catalog))
                return "Base_Catalog: string expected";
        if (message.Accommodation_Rating != null && message.hasOwnProperty("Accommodation_Rating")) {
            let error = $root.SearchForm.RangeFloat.verify(message.Accommodation_Rating);
            if (error)
                return "Accommodation_Rating." + error;
        }
        if (message.Accommodation_Category != null && message.hasOwnProperty("Accommodation_Category")) {
            let error = $root.SearchForm.RangeFloat.verify(message.Accommodation_Category);
            if (error)
                return "Accommodation_Category." + error;
        }
        if (message.Base_StartDateDayOfWeek != null && message.hasOwnProperty("Base_StartDateDayOfWeek")) {
            if (!Array.isArray(message.Base_StartDateDayOfWeek))
                return "Base_StartDateDayOfWeek: array expected";
            for (let i = 0; i < message.Base_StartDateDayOfWeek.length; ++i)
                switch (message.Base_StartDateDayOfWeek[i]) {
                default:
                    return "Base_StartDateDayOfWeek: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
        }
        if (message.Custom_HotelName != null && message.hasOwnProperty("Custom_HotelName"))
            if (!$util.isString(message.Custom_HotelName))
                return "Custom_HotelName: string expected";
        if (message.Custom_AgentAttributes != null && message.hasOwnProperty("Custom_AgentAttributes")) {
            if (!Array.isArray(message.Custom_AgentAttributes))
                return "Custom_AgentAttributes: array expected";
            for (let i = 0; i < message.Custom_AgentAttributes.length; ++i)
                if (!$util.isInteger(message.Custom_AgentAttributes[i]))
                    return "Custom_AgentAttributes: integer[] expected";
        }
        if (message.Base_Candy != null && message.hasOwnProperty("Base_Candy"))
            if (!$util.isInteger(message.Base_Candy))
                return "Base_Candy: integer expected";
        if (message.Base_Promotion != null && message.hasOwnProperty("Base_Promotion"))
            if (!$util.isString(message.Base_Promotion))
                return "Base_Promotion: string expected";
        if (message.Base_ParticipantsList != null && message.hasOwnProperty("Base_ParticipantsList")) {
            let error = $root.SearchForm.Rooms.verify(message.Base_ParticipantsList);
            if (error)
                return "Base_ParticipantsList." + error;
        }
        if (message.Accommodation_Attributes != null && message.hasOwnProperty("Accommodation_Attributes")) {
            if (!Array.isArray(message.Accommodation_Attributes))
                return "Accommodation_Attributes: array expected";
            for (let i = 0; i < message.Accommodation_Attributes.length; ++i)
                switch (message.Accommodation_Attributes[i]) {
                default:
                    return "Accommodation_Attributes: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                    break;
                }
        }
        if (message.Custom_Availability != null && message.hasOwnProperty("Custom_Availability")) {
            if (!Array.isArray(message.Custom_Availability))
                return "Custom_Availability: array expected";
            for (let i = 0; i < message.Custom_Availability.length; ++i)
                switch (message.Custom_Availability[i]) {
                default:
                    return "Custom_Availability: enum value[] expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
        }
        if (message.Custom_Senior != null && message.hasOwnProperty("Custom_Senior"))
            if (typeof message.Custom_Senior !== "boolean")
                return "Custom_Senior: boolean expected";
        if (message.Accommodation_BookBeds != null && message.hasOwnProperty("Accommodation_BookBeds"))
            if (typeof message.Accommodation_BookBeds !== "boolean")
                return "Accommodation_BookBeds: boolean expected";
        if (message.Content_DescriptionRequired != null && message.hasOwnProperty("Content_DescriptionRequired"))
            if (typeof message.Content_DescriptionRequired !== "boolean")
                return "Content_DescriptionRequired: boolean expected";
        if (message.Content_PhotosRequired != null && message.hasOwnProperty("Content_PhotosRequired"))
            if (typeof message.Content_PhotosRequired !== "boolean")
                return "Content_PhotosRequired: boolean expected";
        if (message.Custom_Rating != null && message.hasOwnProperty("Custom_Rating"))
            if (typeof message.Custom_Rating !== "boolean")
                return "Custom_Rating: boolean expected";
        if (message.Custom_DistanceToBeach != null && message.hasOwnProperty("Custom_DistanceToBeach"))
            if (typeof message.Custom_DistanceToBeach !== "boolean")
                return "Custom_DistanceToBeach: boolean expected";
        if (message.Accommodation_DistanceToBeach != null && message.hasOwnProperty("Accommodation_DistanceToBeach")) {
            let error = $root.SearchForm.RangeInt.verify(message.Accommodation_DistanceToBeach);
            if (error)
                return "Accommodation_DistanceToBeach." + error;
        }
        if (message.Accommodation_DistanceToSlope != null && message.hasOwnProperty("Accommodation_DistanceToSlope")) {
            let error = $root.SearchForm.RangeInt.verify(message.Accommodation_DistanceToSlope);
            if (error)
                return "Accommodation_DistanceToSlope." + error;
        }
        if (message.Custom_FullPrice != null && message.hasOwnProperty("Custom_FullPrice"))
            if (!$util.isInteger(message.Custom_FullPrice))
                return "Custom_FullPrice: integer expected";
        if (message.Custom_PriceRange != null && message.hasOwnProperty("Custom_PriceRange")) {
            let error = $root.SearchForm.PriceRanges.verify(message.Custom_PriceRange);
            if (error)
                return "Custom_PriceRange." + error;
        }
        if (message.Custom_StartDateRange_StartDateFrom != null && message.hasOwnProperty("Custom_StartDateRange_StartDateFrom"))
            if (!$util.isString(message.Custom_StartDateRange_StartDateFrom))
                return "Custom_StartDateRange_StartDateFrom: string expected";
        if (message.Custom_StartDateRange_StartDateTo != null && message.hasOwnProperty("Custom_StartDateRange_StartDateTo"))
            if (!$util.isString(message.Custom_StartDateRange_StartDateTo))
                return "Custom_StartDateRange_StartDateTo: string expected";
        if (message.Accommodation_ExtAgentAttributes != null && message.hasOwnProperty("Accommodation_ExtAgentAttributes")) {
            if (!Array.isArray(message.Accommodation_ExtAgentAttributes))
                return "Accommodation_ExtAgentAttributes: array expected";
            for (let i = 0; i < message.Accommodation_ExtAgentAttributes.length; ++i)
                if (!$util.isString(message.Accommodation_ExtAgentAttributes[i]))
                    return "Accommodation_ExtAgentAttributes: string[] expected";
        }
        if (message.Accommodation_Camp_Theme != null && message.hasOwnProperty("Accommodation_Camp_Theme")) {
            if (!Array.isArray(message.Accommodation_Camp_Theme))
                return "Accommodation_Camp_Theme: array expected";
            for (let i = 0; i < message.Accommodation_Camp_Theme.length; ++i)
                if (!$util.isInteger(message.Accommodation_Camp_Theme[i]))
                    return "Accommodation_Camp_Theme: integer[] expected";
        }
        if (message.Base_DatasetInfo_Private != null && message.hasOwnProperty("Base_DatasetInfo_Private")) {
            if (!Array.isArray(message.Base_DatasetInfo_Private))
                return "Base_DatasetInfo_Private: array expected";
            for (let i = 0; i < message.Base_DatasetInfo_Private.length; ++i)
                if (typeof message.Base_DatasetInfo_Private[i] !== "boolean")
                    return "Base_DatasetInfo_Private: boolean[] expected";
        }
        if (message.Custom_OfferAttributes != null && message.hasOwnProperty("Custom_OfferAttributes")) {
            if (!Array.isArray(message.Custom_OfferAttributes))
                return "Custom_OfferAttributes: array expected";
            for (let i = 0; i < message.Custom_OfferAttributes.length; ++i)
                if (!$util.isString(message.Custom_OfferAttributes[i]))
                    return "Custom_OfferAttributes: string[] expected";
        }
        if (message.Base_XCity != null && message.hasOwnProperty("Base_XCity"))
            if (!$util.isString(message.Base_XCity))
                return "Base_XCity: string expected";
        if (message.Accommodation_XCode != null && message.hasOwnProperty("Accommodation_XCode")) {
            if (!Array.isArray(message.Accommodation_XCode))
                return "Accommodation_XCode: array expected";
            for (let i = 0; i < message.Accommodation_XCode.length; ++i)
                if (!$util.isInteger(message.Accommodation_XCode[i]))
                    return "Accommodation_XCode: integer[] expected";
        }
        if (message.Accommodation_Rooms != null && message.hasOwnProperty("Accommodation_Rooms")) {
            if (!Array.isArray(message.Accommodation_Rooms))
                return "Accommodation_Rooms: array expected";
            for (let i = 0; i < message.Accommodation_Rooms.length; ++i)
                if (!$util.isString(message.Accommodation_Rooms[i]))
                    return "Accommodation_Rooms: string[] expected";
        }
        if (message.Accommodation_BuildRenovationYear != null && message.hasOwnProperty("Accommodation_BuildRenovationYear"))
            if (!$util.isString(message.Accommodation_BuildRenovationYear))
                return "Accommodation_BuildRenovationYear: string expected";
        return null;
    };

    /**
     * Creates a SearchForm message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SearchForm
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SearchForm} SearchForm
     */
    SearchForm.fromObject = function fromObject(object) {
        if (object instanceof $root.SearchForm)
            return object;
        let message = new $root.SearchForm();
        if (object.Base_Operator) {
            if (!Array.isArray(object.Base_Operator))
                throw TypeError(".SearchForm.Base_Operator: array expected");
            message.Base_Operator = [];
            for (let i = 0; i < object.Base_Operator.length; ++i)
                message.Base_Operator[i] = String(object.Base_Operator[i]);
        }
        if (object.Base_DestinationLocation_Country) {
            if (!Array.isArray(object.Base_DestinationLocation_Country))
                throw TypeError(".SearchForm.Base_DestinationLocation_Country: array expected");
            message.Base_DestinationLocation_Country = [];
            for (let i = 0; i < object.Base_DestinationLocation_Country.length; ++i)
                message.Base_DestinationLocation_Country[i] = String(object.Base_DestinationLocation_Country[i]);
        }
        if (object.Base_DestinationLocation_Region) {
            if (!Array.isArray(object.Base_DestinationLocation_Region))
                throw TypeError(".SearchForm.Base_DestinationLocation_Region: array expected");
            message.Base_DestinationLocation_Region = [];
            for (let i = 0; i < object.Base_DestinationLocation_Region.length; ++i)
                message.Base_DestinationLocation_Region[i] = String(object.Base_DestinationLocation_Region[i]);
        }
        if (object.Base_ComponentsCombinations_Transport) {
            if (!Array.isArray(object.Base_ComponentsCombinations_Transport))
                throw TypeError(".SearchForm.Base_ComponentsCombinations_Transport: array expected");
            message.Base_ComponentsCombinations_Transport = [];
            for (let i = 0; i < object.Base_ComponentsCombinations_Transport.length; ++i)
                message.Base_ComponentsCombinations_Transport[i] = String(object.Base_ComponentsCombinations_Transport[i]);
        }
        if (object.Base_DepartureLocation) {
            if (!Array.isArray(object.Base_DepartureLocation))
                throw TypeError(".SearchForm.Base_DepartureLocation: array expected");
            message.Base_DepartureLocation = [];
            for (let i = 0; i < object.Base_DepartureLocation.length; ++i)
                message.Base_DepartureLocation[i] = String(object.Base_DepartureLocation[i]);
        }
        if (object.Base_ComponentsCombinations_ObjectType) {
            if (!Array.isArray(object.Base_ComponentsCombinations_ObjectType))
                throw TypeError(".SearchForm.Base_ComponentsCombinations_ObjectType: array expected");
            message.Base_ComponentsCombinations_ObjectType = [];
            for (let i = 0; i < object.Base_ComponentsCombinations_ObjectType.length; ++i)
                message.Base_ComponentsCombinations_ObjectType[i] = String(object.Base_ComponentsCombinations_ObjectType[i]);
        }
        if (object.Custom_TripRange != null)
            message.Custom_TripRange = object.Custom_TripRange | 0;
        if (object.Base_NightsBeforeReturn) {
            if (!Array.isArray(object.Base_NightsBeforeReturn))
                throw TypeError(".SearchForm.Base_NightsBeforeReturn: array expected");
            message.Base_NightsBeforeReturn = [];
            for (let i = 0; i < object.Base_NightsBeforeReturn.length; ++i)
                message.Base_NightsBeforeReturn[i] = object.Base_NightsBeforeReturn[i] | 0;
        }
        if (object.Accommodation_XService) {
            if (!Array.isArray(object.Accommodation_XService))
                throw TypeError(".SearchForm.Accommodation_XService: array expected");
            message.Accommodation_XService = [];
            for (let i = 0; i < object.Accommodation_XService.length; ++i)
                message.Accommodation_XService[i] = object.Accommodation_XService[i] | 0;
        }
        if (object.Base_Catalog != null)
            message.Base_Catalog = String(object.Base_Catalog);
        if (object.Accommodation_Rating != null) {
            if (typeof object.Accommodation_Rating !== "object")
                throw TypeError(".SearchForm.Accommodation_Rating: object expected");
            message.Accommodation_Rating = $root.SearchForm.RangeFloat.fromObject(object.Accommodation_Rating);
        }
        if (object.Accommodation_Category != null) {
            if (typeof object.Accommodation_Category !== "object")
                throw TypeError(".SearchForm.Accommodation_Category: object expected");
            message.Accommodation_Category = $root.SearchForm.RangeFloat.fromObject(object.Accommodation_Category);
        }
        if (object.Base_StartDateDayOfWeek) {
            if (!Array.isArray(object.Base_StartDateDayOfWeek))
                throw TypeError(".SearchForm.Base_StartDateDayOfWeek: array expected");
            message.Base_StartDateDayOfWeek = [];
            for (let i = 0; i < object.Base_StartDateDayOfWeek.length; ++i)
                switch (object.Base_StartDateDayOfWeek[i]) {
                default:
                    if (typeof object.Base_StartDateDayOfWeek[i] === "number") {
                        message.Base_StartDateDayOfWeek[i] = object.Base_StartDateDayOfWeek[i];
                        break;
                    }
                case "Mon":
                case 0:
                    message.Base_StartDateDayOfWeek[i] = 0;
                    break;
                case "Tue":
                case 1:
                    message.Base_StartDateDayOfWeek[i] = 1;
                    break;
                case "Wed":
                case 2:
                    message.Base_StartDateDayOfWeek[i] = 2;
                    break;
                case "Thu":
                case 3:
                    message.Base_StartDateDayOfWeek[i] = 3;
                    break;
                case "Fri":
                case 4:
                    message.Base_StartDateDayOfWeek[i] = 4;
                    break;
                case "Sat":
                case 5:
                    message.Base_StartDateDayOfWeek[i] = 5;
                    break;
                case "Sun":
                case 6:
                    message.Base_StartDateDayOfWeek[i] = 6;
                    break;
                }
        }
        if (object.Custom_HotelName != null)
            message.Custom_HotelName = String(object.Custom_HotelName);
        if (object.Custom_AgentAttributes) {
            if (!Array.isArray(object.Custom_AgentAttributes))
                throw TypeError(".SearchForm.Custom_AgentAttributes: array expected");
            message.Custom_AgentAttributes = [];
            for (let i = 0; i < object.Custom_AgentAttributes.length; ++i)
                message.Custom_AgentAttributes[i] = object.Custom_AgentAttributes[i] | 0;
        }
        if (object.Base_Candy != null)
            message.Base_Candy = object.Base_Candy | 0;
        if (object.Base_Promotion != null)
            message.Base_Promotion = String(object.Base_Promotion);
        if (object.Base_ParticipantsList != null) {
            if (typeof object.Base_ParticipantsList !== "object")
                throw TypeError(".SearchForm.Base_ParticipantsList: object expected");
            message.Base_ParticipantsList = $root.SearchForm.Rooms.fromObject(object.Base_ParticipantsList);
        }
        if (object.Accommodation_Attributes) {
            if (!Array.isArray(object.Accommodation_Attributes))
                throw TypeError(".SearchForm.Accommodation_Attributes: array expected");
            message.Accommodation_Attributes = [];
            for (let i = 0; i < object.Accommodation_Attributes.length; ++i)
                switch (object.Accommodation_Attributes[i]) {
                default:
                    if (typeof object.Accommodation_Attributes[i] === "number") {
                        message.Accommodation_Attributes[i] = object.Accommodation_Attributes[i];
                        break;
                    }
                case "location_mountains":
                case 0:
                    message.Accommodation_Attributes[i] = 0;
                    break;
                case "location_near_the_slope":
                case 1:
                    message.Accommodation_Attributes[i] = 1;
                    break;
                case "location_exotic_offers":
                case 2:
                    message.Accommodation_Attributes[i] = 2;
                    break;
                case "location_lakes":
                case 3:
                    message.Accommodation_Attributes[i] = 3;
                    break;
                case "location_ski_resorts":
                case 4:
                    message.Accommodation_Attributes[i] = 4;
                    break;
                case "location_seaside_resorts":
                case 5:
                    message.Accommodation_Attributes[i] = 5;
                    break;
                case "location_city_breaks":
                case 6:
                    message.Accommodation_Attributes[i] = 6;
                    break;
                case "location_directly_on_the_beach":
                case 7:
                    message.Accommodation_Attributes[i] = 7;
                    break;
                case "location_near_airport":
                case 8:
                    message.Accommodation_Attributes[i] = 8;
                    break;
                case "location_near_the_center":
                case 9:
                    message.Accommodation_Attributes[i] = 9;
                    break;
                case "location_without_passport_from_pl":
                case 10:
                    message.Accommodation_Attributes[i] = 10;
                    break;
                case "facility_free_wifi":
                case 11:
                    message.Accommodation_Attributes[i] = 11;
                    break;
                case "facility_for_adult":
                case 12:
                    message.Accommodation_Attributes[i] = 12;
                    break;
                case "facility_wellness":
                case 13:
                    message.Accommodation_Attributes[i] = 13;
                    break;
                case "facility_gay_friendly":
                case 14:
                    message.Accommodation_Attributes[i] = 14;
                    break;
                case "facility_barbecue":
                case 15:
                    message.Accommodation_Attributes[i] = 15;
                    break;
                case "facility_home_appliances":
                case 16:
                    message.Accommodation_Attributes[i] = 16;
                    break;
                case "facility_fireplace":
                case 17:
                    message.Accommodation_Attributes[i] = 17;
                    break;
                case "facility_parking":
                case 18:
                    message.Accommodation_Attributes[i] = 18;
                    break;
                case "facility_for_families_with_childrens":
                case 19:
                    message.Accommodation_Attributes[i] = 19;
                    break;
                case "facility_club_hotel":
                case 20:
                    message.Accommodation_Attributes[i] = 20;
                    break;
                case "facility_fitness_or_gym":
                case 21:
                    message.Accommodation_Attributes[i] = 21;
                    break;
                case "facility_outdoor_pool":
                case 22:
                    message.Accommodation_Attributes[i] = 22;
                    break;
                case "facility_indoor_pool":
                case 23:
                    message.Accommodation_Attributes[i] = 23;
                    break;
                case "facility_kindergarten":
                case 24:
                    message.Accommodation_Attributes[i] = 24;
                    break;
                case "facility_water_slides":
                case 25:
                    message.Accommodation_Attributes[i] = 25;
                    break;
                case "facility_facilities_for_disabled":
                case 26:
                    message.Accommodation_Attributes[i] = 26;
                    break;
                case "facility_pets":
                case 27:
                    message.Accommodation_Attributes[i] = 27;
                    break;
                case "facility_internet_access":
                case 28:
                    message.Accommodation_Attributes[i] = 28;
                    break;
                case "facility_air_conditioning":
                case 29:
                    message.Accommodation_Attributes[i] = 29;
                    break;
                case "facility_night_life":
                case 30:
                    message.Accommodation_Attributes[i] = 30;
                    break;
                case "facility_winter_equipment_rental":
                case 31:
                    message.Accommodation_Attributes[i] = 31;
                    break;
                case "activity_bicycles":
                case 32:
                    message.Accommodation_Attributes[i] = 32;
                    break;
                case "activity_animation_for_adults":
                case 33:
                    message.Accommodation_Attributes[i] = 33;
                    break;
                case "activity_sport":
                case 34:
                    message.Accommodation_Attributes[i] = 34;
                    break;
                case "activity_tennis":
                case 35:
                    message.Accommodation_Attributes[i] = 35;
                    break;
                case "activity_golf":
                case 36:
                    message.Accommodation_Attributes[i] = 36;
                    break;
                case "activity_minigolf":
                case 37:
                    message.Accommodation_Attributes[i] = 37;
                    break;
                case "activity_water_sports":
                case 38:
                    message.Accommodation_Attributes[i] = 38;
                    break;
                case "activity_diving":
                case 39:
                    message.Accommodation_Attributes[i] = 39;
                    break;
                case "activity_riding":
                case 40:
                    message.Accommodation_Attributes[i] = 40;
                    break;
                case "activity_nordic_walking":
                case 41:
                    message.Accommodation_Attributes[i] = 41;
                    break;
                case "activity_windsurfing":
                case 42:
                    message.Accommodation_Attributes[i] = 42;
                    break;
                case "activity_sailing":
                case 43:
                    message.Accommodation_Attributes[i] = 43;
                    break;
                case "activity_winter_sports":
                case 44:
                    message.Accommodation_Attributes[i] = 44;
                    break;
                }
        }
        if (object.Custom_Availability) {
            if (!Array.isArray(object.Custom_Availability))
                throw TypeError(".SearchForm.Custom_Availability: array expected");
            message.Custom_Availability = [];
            for (let i = 0; i < object.Custom_Availability.length; ++i)
                switch (object.Custom_Availability[i]) {
                default:
                    if (typeof object.Custom_Availability[i] === "number") {
                        message.Custom_Availability[i] = object.Custom_Availability[i];
                        break;
                    }
                case "available":
                case 0:
                    message.Custom_Availability[i] = 0;
                    break;
                case "notavailable":
                case 1:
                    message.Custom_Availability[i] = 1;
                    break;
                case "onrequest":
                case 2:
                    message.Custom_Availability[i] = 2;
                    break;
                }
        }
        if (object.Custom_Senior != null)
            message.Custom_Senior = Boolean(object.Custom_Senior);
        if (object.Accommodation_BookBeds != null)
            message.Accommodation_BookBeds = Boolean(object.Accommodation_BookBeds);
        if (object.Content_DescriptionRequired != null)
            message.Content_DescriptionRequired = Boolean(object.Content_DescriptionRequired);
        if (object.Content_PhotosRequired != null)
            message.Content_PhotosRequired = Boolean(object.Content_PhotosRequired);
        if (object.Custom_Rating != null)
            message.Custom_Rating = Boolean(object.Custom_Rating);
        if (object.Custom_DistanceToBeach != null)
            message.Custom_DistanceToBeach = Boolean(object.Custom_DistanceToBeach);
        if (object.Accommodation_DistanceToBeach != null) {
            if (typeof object.Accommodation_DistanceToBeach !== "object")
                throw TypeError(".SearchForm.Accommodation_DistanceToBeach: object expected");
            message.Accommodation_DistanceToBeach = $root.SearchForm.RangeInt.fromObject(object.Accommodation_DistanceToBeach);
        }
        if (object.Accommodation_DistanceToSlope != null) {
            if (typeof object.Accommodation_DistanceToSlope !== "object")
                throw TypeError(".SearchForm.Accommodation_DistanceToSlope: object expected");
            message.Accommodation_DistanceToSlope = $root.SearchForm.RangeInt.fromObject(object.Accommodation_DistanceToSlope);
        }
        if (object.Custom_FullPrice != null)
            message.Custom_FullPrice = object.Custom_FullPrice | 0;
        if (object.Custom_PriceRange != null) {
            if (typeof object.Custom_PriceRange !== "object")
                throw TypeError(".SearchForm.Custom_PriceRange: object expected");
            message.Custom_PriceRange = $root.SearchForm.PriceRanges.fromObject(object.Custom_PriceRange);
        }
        if (object.Custom_StartDateRange_StartDateFrom != null)
            message.Custom_StartDateRange_StartDateFrom = String(object.Custom_StartDateRange_StartDateFrom);
        if (object.Custom_StartDateRange_StartDateTo != null)
            message.Custom_StartDateRange_StartDateTo = String(object.Custom_StartDateRange_StartDateTo);
        if (object.Accommodation_ExtAgentAttributes) {
            if (!Array.isArray(object.Accommodation_ExtAgentAttributes))
                throw TypeError(".SearchForm.Accommodation_ExtAgentAttributes: array expected");
            message.Accommodation_ExtAgentAttributes = [];
            for (let i = 0; i < object.Accommodation_ExtAgentAttributes.length; ++i)
                message.Accommodation_ExtAgentAttributes[i] = String(object.Accommodation_ExtAgentAttributes[i]);
        }
        if (object.Accommodation_Camp_Theme) {
            if (!Array.isArray(object.Accommodation_Camp_Theme))
                throw TypeError(".SearchForm.Accommodation_Camp_Theme: array expected");
            message.Accommodation_Camp_Theme = [];
            for (let i = 0; i < object.Accommodation_Camp_Theme.length; ++i)
                message.Accommodation_Camp_Theme[i] = object.Accommodation_Camp_Theme[i] | 0;
        }
        if (object.Base_DatasetInfo_Private) {
            if (!Array.isArray(object.Base_DatasetInfo_Private))
                throw TypeError(".SearchForm.Base_DatasetInfo_Private: array expected");
            message.Base_DatasetInfo_Private = [];
            for (let i = 0; i < object.Base_DatasetInfo_Private.length; ++i)
                message.Base_DatasetInfo_Private[i] = Boolean(object.Base_DatasetInfo_Private[i]);
        }
        if (object.Custom_OfferAttributes) {
            if (!Array.isArray(object.Custom_OfferAttributes))
                throw TypeError(".SearchForm.Custom_OfferAttributes: array expected");
            message.Custom_OfferAttributes = [];
            for (let i = 0; i < object.Custom_OfferAttributes.length; ++i)
                message.Custom_OfferAttributes[i] = String(object.Custom_OfferAttributes[i]);
        }
        if (object.Base_XCity != null)
            message.Base_XCity = String(object.Base_XCity);
        if (object.Accommodation_XCode) {
            if (!Array.isArray(object.Accommodation_XCode))
                throw TypeError(".SearchForm.Accommodation_XCode: array expected");
            message.Accommodation_XCode = [];
            for (let i = 0; i < object.Accommodation_XCode.length; ++i)
                message.Accommodation_XCode[i] = object.Accommodation_XCode[i] | 0;
        }
        if (object.Accommodation_Rooms) {
            if (!Array.isArray(object.Accommodation_Rooms))
                throw TypeError(".SearchForm.Accommodation_Rooms: array expected");
            message.Accommodation_Rooms = [];
            for (let i = 0; i < object.Accommodation_Rooms.length; ++i)
                message.Accommodation_Rooms[i] = String(object.Accommodation_Rooms[i]);
        }
        if (object.Accommodation_BuildRenovationYear != null)
            message.Accommodation_BuildRenovationYear = String(object.Accommodation_BuildRenovationYear);
        return message;
    };

    /**
     * Creates a plain object from a SearchForm message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SearchForm
     * @static
     * @param {SearchForm} message SearchForm
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SearchForm.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.Base_Operator = [];
            object.Base_DestinationLocation_Country = [];
            object.Base_DestinationLocation_Region = [];
            object.Base_ComponentsCombinations_Transport = [];
            object.Base_DepartureLocation = [];
            object.Base_ComponentsCombinations_ObjectType = [];
            object.Base_NightsBeforeReturn = [];
            object.Accommodation_XService = [];
            object.Base_StartDateDayOfWeek = [];
            object.Custom_AgentAttributes = [];
            object.Accommodation_Attributes = [];
            object.Custom_Availability = [];
            object.Accommodation_ExtAgentAttributes = [];
            object.Accommodation_Camp_Theme = [];
            object.Base_DatasetInfo_Private = [];
            object.Custom_OfferAttributes = [];
            object.Accommodation_XCode = [];
            object.Accommodation_Rooms = [];
        }
        if (options.defaults) {
            object.Custom_TripRange = 0;
            object.Base_Catalog = "";
            object.Accommodation_Rating = null;
            object.Accommodation_Category = null;
            object.Custom_HotelName = "";
            object.Base_Candy = 0;
            object.Base_Promotion = "";
            object.Base_ParticipantsList = null;
            object.Custom_Senior = false;
            object.Accommodation_BookBeds = false;
            object.Content_DescriptionRequired = false;
            object.Content_PhotosRequired = false;
            object.Custom_Rating = false;
            object.Custom_DistanceToBeach = false;
            object.Accommodation_DistanceToBeach = null;
            object.Accommodation_DistanceToSlope = null;
            object.Custom_FullPrice = 0;
            object.Custom_PriceRange = null;
            object.Custom_StartDateRange_StartDateFrom = "";
            object.Custom_StartDateRange_StartDateTo = "";
            object.Base_XCity = "";
            object.Accommodation_BuildRenovationYear = "";
        }
        if (message.Base_Operator && message.Base_Operator.length) {
            object.Base_Operator = [];
            for (let j = 0; j < message.Base_Operator.length; ++j)
                object.Base_Operator[j] = message.Base_Operator[j];
        }
        if (message.Base_DestinationLocation_Country && message.Base_DestinationLocation_Country.length) {
            object.Base_DestinationLocation_Country = [];
            for (let j = 0; j < message.Base_DestinationLocation_Country.length; ++j)
                object.Base_DestinationLocation_Country[j] = message.Base_DestinationLocation_Country[j];
        }
        if (message.Base_DestinationLocation_Region && message.Base_DestinationLocation_Region.length) {
            object.Base_DestinationLocation_Region = [];
            for (let j = 0; j < message.Base_DestinationLocation_Region.length; ++j)
                object.Base_DestinationLocation_Region[j] = message.Base_DestinationLocation_Region[j];
        }
        if (message.Base_ComponentsCombinations_Transport && message.Base_ComponentsCombinations_Transport.length) {
            object.Base_ComponentsCombinations_Transport = [];
            for (let j = 0; j < message.Base_ComponentsCombinations_Transport.length; ++j)
                object.Base_ComponentsCombinations_Transport[j] = message.Base_ComponentsCombinations_Transport[j];
        }
        if (message.Base_DepartureLocation && message.Base_DepartureLocation.length) {
            object.Base_DepartureLocation = [];
            for (let j = 0; j < message.Base_DepartureLocation.length; ++j)
                object.Base_DepartureLocation[j] = message.Base_DepartureLocation[j];
        }
        if (message.Base_ComponentsCombinations_ObjectType && message.Base_ComponentsCombinations_ObjectType.length) {
            object.Base_ComponentsCombinations_ObjectType = [];
            for (let j = 0; j < message.Base_ComponentsCombinations_ObjectType.length; ++j)
                object.Base_ComponentsCombinations_ObjectType[j] = message.Base_ComponentsCombinations_ObjectType[j];
        }
        if (message.Custom_TripRange != null && message.hasOwnProperty("Custom_TripRange"))
            object.Custom_TripRange = message.Custom_TripRange;
        if (message.Base_NightsBeforeReturn && message.Base_NightsBeforeReturn.length) {
            object.Base_NightsBeforeReturn = [];
            for (let j = 0; j < message.Base_NightsBeforeReturn.length; ++j)
                object.Base_NightsBeforeReturn[j] = message.Base_NightsBeforeReturn[j];
        }
        if (message.Accommodation_XService && message.Accommodation_XService.length) {
            object.Accommodation_XService = [];
            for (let j = 0; j < message.Accommodation_XService.length; ++j)
                object.Accommodation_XService[j] = message.Accommodation_XService[j];
        }
        if (message.Base_Catalog != null && message.hasOwnProperty("Base_Catalog"))
            object.Base_Catalog = message.Base_Catalog;
        if (message.Accommodation_Rating != null && message.hasOwnProperty("Accommodation_Rating"))
            object.Accommodation_Rating = $root.SearchForm.RangeFloat.toObject(message.Accommodation_Rating, options);
        if (message.Accommodation_Category != null && message.hasOwnProperty("Accommodation_Category"))
            object.Accommodation_Category = $root.SearchForm.RangeFloat.toObject(message.Accommodation_Category, options);
        if (message.Base_StartDateDayOfWeek && message.Base_StartDateDayOfWeek.length) {
            object.Base_StartDateDayOfWeek = [];
            for (let j = 0; j < message.Base_StartDateDayOfWeek.length; ++j)
                object.Base_StartDateDayOfWeek[j] = options.enums === String ? $root.SearchForm.DayOfWeek[message.Base_StartDateDayOfWeek[j]] === undefined ? message.Base_StartDateDayOfWeek[j] : $root.SearchForm.DayOfWeek[message.Base_StartDateDayOfWeek[j]] : message.Base_StartDateDayOfWeek[j];
        }
        if (message.Custom_HotelName != null && message.hasOwnProperty("Custom_HotelName"))
            object.Custom_HotelName = message.Custom_HotelName;
        if (message.Custom_AgentAttributes && message.Custom_AgentAttributes.length) {
            object.Custom_AgentAttributes = [];
            for (let j = 0; j < message.Custom_AgentAttributes.length; ++j)
                object.Custom_AgentAttributes[j] = message.Custom_AgentAttributes[j];
        }
        if (message.Base_Candy != null && message.hasOwnProperty("Base_Candy"))
            object.Base_Candy = message.Base_Candy;
        if (message.Base_Promotion != null && message.hasOwnProperty("Base_Promotion"))
            object.Base_Promotion = message.Base_Promotion;
        if (message.Base_ParticipantsList != null && message.hasOwnProperty("Base_ParticipantsList"))
            object.Base_ParticipantsList = $root.SearchForm.Rooms.toObject(message.Base_ParticipantsList, options);
        if (message.Accommodation_Attributes && message.Accommodation_Attributes.length) {
            object.Accommodation_Attributes = [];
            for (let j = 0; j < message.Accommodation_Attributes.length; ++j)
                object.Accommodation_Attributes[j] = options.enums === String ? $root.SearchForm.Attribute[message.Accommodation_Attributes[j]] === undefined ? message.Accommodation_Attributes[j] : $root.SearchForm.Attribute[message.Accommodation_Attributes[j]] : message.Accommodation_Attributes[j];
        }
        if (message.Custom_Availability && message.Custom_Availability.length) {
            object.Custom_Availability = [];
            for (let j = 0; j < message.Custom_Availability.length; ++j)
                object.Custom_Availability[j] = options.enums === String ? $root.SearchForm.Availability[message.Custom_Availability[j]] === undefined ? message.Custom_Availability[j] : $root.SearchForm.Availability[message.Custom_Availability[j]] : message.Custom_Availability[j];
        }
        if (message.Custom_Senior != null && message.hasOwnProperty("Custom_Senior"))
            object.Custom_Senior = message.Custom_Senior;
        if (message.Accommodation_BookBeds != null && message.hasOwnProperty("Accommodation_BookBeds"))
            object.Accommodation_BookBeds = message.Accommodation_BookBeds;
        if (message.Content_DescriptionRequired != null && message.hasOwnProperty("Content_DescriptionRequired"))
            object.Content_DescriptionRequired = message.Content_DescriptionRequired;
        if (message.Content_PhotosRequired != null && message.hasOwnProperty("Content_PhotosRequired"))
            object.Content_PhotosRequired = message.Content_PhotosRequired;
        if (message.Custom_Rating != null && message.hasOwnProperty("Custom_Rating"))
            object.Custom_Rating = message.Custom_Rating;
        if (message.Custom_DistanceToBeach != null && message.hasOwnProperty("Custom_DistanceToBeach"))
            object.Custom_DistanceToBeach = message.Custom_DistanceToBeach;
        if (message.Accommodation_DistanceToBeach != null && message.hasOwnProperty("Accommodation_DistanceToBeach"))
            object.Accommodation_DistanceToBeach = $root.SearchForm.RangeInt.toObject(message.Accommodation_DistanceToBeach, options);
        if (message.Accommodation_DistanceToSlope != null && message.hasOwnProperty("Accommodation_DistanceToSlope"))
            object.Accommodation_DistanceToSlope = $root.SearchForm.RangeInt.toObject(message.Accommodation_DistanceToSlope, options);
        if (message.Custom_FullPrice != null && message.hasOwnProperty("Custom_FullPrice"))
            object.Custom_FullPrice = message.Custom_FullPrice;
        if (message.Custom_PriceRange != null && message.hasOwnProperty("Custom_PriceRange"))
            object.Custom_PriceRange = $root.SearchForm.PriceRanges.toObject(message.Custom_PriceRange, options);
        if (message.Custom_StartDateRange_StartDateFrom != null && message.hasOwnProperty("Custom_StartDateRange_StartDateFrom"))
            object.Custom_StartDateRange_StartDateFrom = message.Custom_StartDateRange_StartDateFrom;
        if (message.Custom_StartDateRange_StartDateTo != null && message.hasOwnProperty("Custom_StartDateRange_StartDateTo"))
            object.Custom_StartDateRange_StartDateTo = message.Custom_StartDateRange_StartDateTo;
        if (message.Accommodation_ExtAgentAttributes && message.Accommodation_ExtAgentAttributes.length) {
            object.Accommodation_ExtAgentAttributes = [];
            for (let j = 0; j < message.Accommodation_ExtAgentAttributes.length; ++j)
                object.Accommodation_ExtAgentAttributes[j] = message.Accommodation_ExtAgentAttributes[j];
        }
        if (message.Accommodation_Camp_Theme && message.Accommodation_Camp_Theme.length) {
            object.Accommodation_Camp_Theme = [];
            for (let j = 0; j < message.Accommodation_Camp_Theme.length; ++j)
                object.Accommodation_Camp_Theme[j] = message.Accommodation_Camp_Theme[j];
        }
        if (message.Base_DatasetInfo_Private && message.Base_DatasetInfo_Private.length) {
            object.Base_DatasetInfo_Private = [];
            for (let j = 0; j < message.Base_DatasetInfo_Private.length; ++j)
                object.Base_DatasetInfo_Private[j] = message.Base_DatasetInfo_Private[j];
        }
        if (message.Custom_OfferAttributes && message.Custom_OfferAttributes.length) {
            object.Custom_OfferAttributes = [];
            for (let j = 0; j < message.Custom_OfferAttributes.length; ++j)
                object.Custom_OfferAttributes[j] = message.Custom_OfferAttributes[j];
        }
        if (message.Base_XCity != null && message.hasOwnProperty("Base_XCity"))
            object.Base_XCity = message.Base_XCity;
        if (message.Accommodation_XCode && message.Accommodation_XCode.length) {
            object.Accommodation_XCode = [];
            for (let j = 0; j < message.Accommodation_XCode.length; ++j)
                object.Accommodation_XCode[j] = message.Accommodation_XCode[j];
        }
        if (message.Accommodation_Rooms && message.Accommodation_Rooms.length) {
            object.Accommodation_Rooms = [];
            for (let j = 0; j < message.Accommodation_Rooms.length; ++j)
                object.Accommodation_Rooms[j] = message.Accommodation_Rooms[j];
        }
        if (message.Accommodation_BuildRenovationYear != null && message.hasOwnProperty("Accommodation_BuildRenovationYear"))
            object.Accommodation_BuildRenovationYear = message.Accommodation_BuildRenovationYear;
        return object;
    };

    /**
     * Converts this SearchForm to JSON.
     * @function toJSON
     * @memberof SearchForm
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SearchForm.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SearchForm
     * @function getTypeUrl
     * @memberof SearchForm
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SearchForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SearchForm";
    };

    /**
     * AirlineType enum.
     * @name SearchForm.AirlineType
     * @enum {number}
     * @property {number} unspecified=0 unspecified value
     * @property {number} lowcost=1 lowcost value
     * @property {number} gds=2 gds value
     */
    SearchForm.AirlineType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "unspecified"] = 0;
        values[valuesById[1] = "lowcost"] = 1;
        values[valuesById[2] = "gds"] = 2;
        return values;
    })();

    SearchForm.RangeFloat = (function() {

        /**
         * Properties of a RangeFloat.
         * @memberof SearchForm
         * @interface IRangeFloat
         * @property {number|null} [min] RangeFloat min
         * @property {number|null} [max] RangeFloat max
         */

        /**
         * Constructs a new RangeFloat.
         * @memberof SearchForm
         * @classdesc Represents a RangeFloat.
         * @implements IRangeFloat
         * @constructor
         * @param {SearchForm.IRangeFloat=} [properties] Properties to set
         */
        function RangeFloat(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RangeFloat min.
         * @member {number} min
         * @memberof SearchForm.RangeFloat
         * @instance
         */
        RangeFloat.prototype.min = 0;

        /**
         * RangeFloat max.
         * @member {number} max
         * @memberof SearchForm.RangeFloat
         * @instance
         */
        RangeFloat.prototype.max = 0;

        /**
         * Creates a new RangeFloat instance using the specified properties.
         * @function create
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {SearchForm.IRangeFloat=} [properties] Properties to set
         * @returns {SearchForm.RangeFloat} RangeFloat instance
         */
        RangeFloat.create = function create(properties) {
            return new RangeFloat(properties);
        };

        /**
         * Encodes the specified RangeFloat message. Does not implicitly {@link SearchForm.RangeFloat.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {SearchForm.IRangeFloat} message RangeFloat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeFloat.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.max);
            return writer;
        };

        /**
         * Encodes the specified RangeFloat message, length delimited. Does not implicitly {@link SearchForm.RangeFloat.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {SearchForm.IRangeFloat} message RangeFloat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeFloat.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RangeFloat message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.RangeFloat} RangeFloat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeFloat.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.RangeFloat();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.min = reader.double();
                        break;
                    }
                case 2: {
                        message.max = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RangeFloat message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.RangeFloat} RangeFloat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeFloat.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RangeFloat message.
         * @function verify
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RangeFloat.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.min != null && message.hasOwnProperty("min"))
                if (typeof message.min !== "number")
                    return "min: number expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (typeof message.max !== "number")
                    return "max: number expected";
            return null;
        };

        /**
         * Creates a RangeFloat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.RangeFloat} RangeFloat
         */
        RangeFloat.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.RangeFloat)
                return object;
            let message = new $root.SearchForm.RangeFloat();
            if (object.min != null)
                message.min = Number(object.min);
            if (object.max != null)
                message.max = Number(object.max);
            return message;
        };

        /**
         * Creates a plain object from a RangeFloat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {SearchForm.RangeFloat} message RangeFloat
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RangeFloat.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.min = 0;
                object.max = 0;
            }
            if (message.min != null && message.hasOwnProperty("min"))
                object.min = options.json && !isFinite(message.min) ? String(message.min) : message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                object.max = options.json && !isFinite(message.max) ? String(message.max) : message.max;
            return object;
        };

        /**
         * Converts this RangeFloat to JSON.
         * @function toJSON
         * @memberof SearchForm.RangeFloat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RangeFloat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RangeFloat
         * @function getTypeUrl
         * @memberof SearchForm.RangeFloat
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RangeFloat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.RangeFloat";
        };

        return RangeFloat;
    })();

    /**
     * DayOfWeek enum.
     * @name SearchForm.DayOfWeek
     * @enum {number}
     * @property {number} Mon=0 Mon value
     * @property {number} Tue=1 Tue value
     * @property {number} Wed=2 Wed value
     * @property {number} Thu=3 Thu value
     * @property {number} Fri=4 Fri value
     * @property {number} Sat=5 Sat value
     * @property {number} Sun=6 Sun value
     */
    SearchForm.DayOfWeek = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Mon"] = 0;
        values[valuesById[1] = "Tue"] = 1;
        values[valuesById[2] = "Wed"] = 2;
        values[valuesById[3] = "Thu"] = 3;
        values[valuesById[4] = "Fri"] = 4;
        values[valuesById[5] = "Sat"] = 5;
        values[valuesById[6] = "Sun"] = 6;
        return values;
    })();

    /**
     * YesNoUnspec enum.
     * @name SearchForm.YesNoUnspec
     * @enum {number}
     * @property {number} unspecified=0 unspecified value
     * @property {number} no=1 no value
     * @property {number} yes=2 yes value
     */
    SearchForm.YesNoUnspec = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "unspecified"] = 0;
        values[valuesById[1] = "no"] = 1;
        values[valuesById[2] = "yes"] = 2;
        return values;
    })();

    /**
     * MultiRoomMode enum.
     * @name SearchForm.MultiRoomMode
     * @enum {number}
     * @property {number} auto=1 auto value
     * @property {number} manual=2 manual value
     */
    SearchForm.MultiRoomMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "auto"] = 1;
        values[valuesById[2] = "manual"] = 2;
        return values;
    })();

    SearchForm.Person = (function() {

        /**
         * Properties of a Person.
         * @memberof SearchForm
         * @interface IPerson
         * @property {number|null} [value] Person value
         * @property {Array.<string>|null} [dates] Person dates
         */

        /**
         * Constructs a new Person.
         * @memberof SearchForm
         * @classdesc Represents a Person.
         * @implements IPerson
         * @constructor
         * @param {SearchForm.IPerson=} [properties] Properties to set
         */
        function Person(properties) {
            this.dates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Person value.
         * @member {number} value
         * @memberof SearchForm.Person
         * @instance
         */
        Person.prototype.value = 0;

        /**
         * Person dates.
         * @member {Array.<string>} dates
         * @memberof SearchForm.Person
         * @instance
         */
        Person.prototype.dates = $util.emptyArray;

        /**
         * Creates a new Person instance using the specified properties.
         * @function create
         * @memberof SearchForm.Person
         * @static
         * @param {SearchForm.IPerson=} [properties] Properties to set
         * @returns {SearchForm.Person} Person instance
         */
        Person.create = function create(properties) {
            return new Person(properties);
        };

        /**
         * Encodes the specified Person message. Does not implicitly {@link SearchForm.Person.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.Person
         * @static
         * @param {SearchForm.IPerson} message Person message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Person.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
            if (message.dates != null && message.dates.length)
                for (let i = 0; i < message.dates.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dates[i]);
            return writer;
        };

        /**
         * Encodes the specified Person message, length delimited. Does not implicitly {@link SearchForm.Person.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.Person
         * @static
         * @param {SearchForm.IPerson} message Person message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Person.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Person message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.Person
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.Person} Person
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Person.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.Person();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.dates && message.dates.length))
                            message.dates = [];
                        message.dates.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Person message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.Person
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.Person} Person
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Person.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Person message.
         * @function verify
         * @memberof SearchForm.Person
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Person.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value))
                    return "value: integer expected";
            if (message.dates != null && message.hasOwnProperty("dates")) {
                if (!Array.isArray(message.dates))
                    return "dates: array expected";
                for (let i = 0; i < message.dates.length; ++i)
                    if (!$util.isString(message.dates[i]))
                        return "dates: string[] expected";
            }
            return null;
        };

        /**
         * Creates a Person message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.Person
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.Person} Person
         */
        Person.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.Person)
                return object;
            let message = new $root.SearchForm.Person();
            if (object.value != null)
                message.value = object.value | 0;
            if (object.dates) {
                if (!Array.isArray(object.dates))
                    throw TypeError(".SearchForm.Person.dates: array expected");
                message.dates = [];
                for (let i = 0; i < object.dates.length; ++i)
                    message.dates[i] = String(object.dates[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Person message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.Person
         * @static
         * @param {SearchForm.Person} message Person
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Person.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.dates = [];
            if (options.defaults)
                object.value = 0;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.dates && message.dates.length) {
                object.dates = [];
                for (let j = 0; j < message.dates.length; ++j)
                    object.dates[j] = message.dates[j];
            }
            return object;
        };

        /**
         * Converts this Person to JSON.
         * @function toJSON
         * @memberof SearchForm.Person
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Person.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Person
         * @function getTypeUrl
         * @memberof SearchForm.Person
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Person.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.Person";
        };

        return Person;
    })();

    SearchForm.Room = (function() {

        /**
         * Properties of a Room.
         * @memberof SearchForm
         * @interface IRoom
         * @property {Array.<string>|null} [type] Room type
         * @property {SearchForm.IPerson|null} [ADULT] Room ADULT
         * @property {SearchForm.IPerson|null} [CHILD] Room CHILD
         * @property {SearchForm.IPerson|null} [INFANT] Room INFANT
         */

        /**
         * Constructs a new Room.
         * @memberof SearchForm
         * @classdesc Represents a Room.
         * @implements IRoom
         * @constructor
         * @param {SearchForm.IRoom=} [properties] Properties to set
         */
        function Room(properties) {
            this.type = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Room type.
         * @member {Array.<string>} type
         * @memberof SearchForm.Room
         * @instance
         */
        Room.prototype.type = $util.emptyArray;

        /**
         * Room ADULT.
         * @member {SearchForm.IPerson|null|undefined} ADULT
         * @memberof SearchForm.Room
         * @instance
         */
        Room.prototype.ADULT = null;

        /**
         * Room CHILD.
         * @member {SearchForm.IPerson|null|undefined} CHILD
         * @memberof SearchForm.Room
         * @instance
         */
        Room.prototype.CHILD = null;

        /**
         * Room INFANT.
         * @member {SearchForm.IPerson|null|undefined} INFANT
         * @memberof SearchForm.Room
         * @instance
         */
        Room.prototype.INFANT = null;

        /**
         * Creates a new Room instance using the specified properties.
         * @function create
         * @memberof SearchForm.Room
         * @static
         * @param {SearchForm.IRoom=} [properties] Properties to set
         * @returns {SearchForm.Room} Room instance
         */
        Room.create = function create(properties) {
            return new Room(properties);
        };

        /**
         * Encodes the specified Room message. Does not implicitly {@link SearchForm.Room.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.Room
         * @static
         * @param {SearchForm.IRoom} message Room message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Room.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && message.type.length)
                for (let i = 0; i < message.type.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type[i]);
            if (message.ADULT != null && Object.hasOwnProperty.call(message, "ADULT"))
                $root.SearchForm.Person.encode(message.ADULT, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.CHILD != null && Object.hasOwnProperty.call(message, "CHILD"))
                $root.SearchForm.Person.encode(message.CHILD, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.INFANT != null && Object.hasOwnProperty.call(message, "INFANT"))
                $root.SearchForm.Person.encode(message.INFANT, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Room message, length delimited. Does not implicitly {@link SearchForm.Room.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.Room
         * @static
         * @param {SearchForm.IRoom} message Room message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Room.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Room message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.Room
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.Room} Room
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Room.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.Room();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.type && message.type.length))
                            message.type = [];
                        message.type.push(reader.string());
                        break;
                    }
                case 2: {
                        message.ADULT = $root.SearchForm.Person.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.CHILD = $root.SearchForm.Person.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.INFANT = $root.SearchForm.Person.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Room message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.Room
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.Room} Room
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Room.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Room message.
         * @function verify
         * @memberof SearchForm.Room
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Room.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type")) {
                if (!Array.isArray(message.type))
                    return "type: array expected";
                for (let i = 0; i < message.type.length; ++i)
                    if (!$util.isString(message.type[i]))
                        return "type: string[] expected";
            }
            if (message.ADULT != null && message.hasOwnProperty("ADULT")) {
                let error = $root.SearchForm.Person.verify(message.ADULT);
                if (error)
                    return "ADULT." + error;
            }
            if (message.CHILD != null && message.hasOwnProperty("CHILD")) {
                let error = $root.SearchForm.Person.verify(message.CHILD);
                if (error)
                    return "CHILD." + error;
            }
            if (message.INFANT != null && message.hasOwnProperty("INFANT")) {
                let error = $root.SearchForm.Person.verify(message.INFANT);
                if (error)
                    return "INFANT." + error;
            }
            return null;
        };

        /**
         * Creates a Room message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.Room
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.Room} Room
         */
        Room.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.Room)
                return object;
            let message = new $root.SearchForm.Room();
            if (object.type) {
                if (!Array.isArray(object.type))
                    throw TypeError(".SearchForm.Room.type: array expected");
                message.type = [];
                for (let i = 0; i < object.type.length; ++i)
                    message.type[i] = String(object.type[i]);
            }
            if (object.ADULT != null) {
                if (typeof object.ADULT !== "object")
                    throw TypeError(".SearchForm.Room.ADULT: object expected");
                message.ADULT = $root.SearchForm.Person.fromObject(object.ADULT);
            }
            if (object.CHILD != null) {
                if (typeof object.CHILD !== "object")
                    throw TypeError(".SearchForm.Room.CHILD: object expected");
                message.CHILD = $root.SearchForm.Person.fromObject(object.CHILD);
            }
            if (object.INFANT != null) {
                if (typeof object.INFANT !== "object")
                    throw TypeError(".SearchForm.Room.INFANT: object expected");
                message.INFANT = $root.SearchForm.Person.fromObject(object.INFANT);
            }
            return message;
        };

        /**
         * Creates a plain object from a Room message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.Room
         * @static
         * @param {SearchForm.Room} message Room
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Room.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.type = [];
            if (options.defaults) {
                object.ADULT = null;
                object.CHILD = null;
                object.INFANT = null;
            }
            if (message.type && message.type.length) {
                object.type = [];
                for (let j = 0; j < message.type.length; ++j)
                    object.type[j] = message.type[j];
            }
            if (message.ADULT != null && message.hasOwnProperty("ADULT"))
                object.ADULT = $root.SearchForm.Person.toObject(message.ADULT, options);
            if (message.CHILD != null && message.hasOwnProperty("CHILD"))
                object.CHILD = $root.SearchForm.Person.toObject(message.CHILD, options);
            if (message.INFANT != null && message.hasOwnProperty("INFANT"))
                object.INFANT = $root.SearchForm.Person.toObject(message.INFANT, options);
            return object;
        };

        /**
         * Converts this Room to JSON.
         * @function toJSON
         * @memberof SearchForm.Room
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Room.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Room
         * @function getTypeUrl
         * @memberof SearchForm.Room
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Room.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.Room";
        };

        return Room;
    })();

    SearchForm.MultiRoom = (function() {

        /**
         * Properties of a MultiRoom.
         * @memberof SearchForm
         * @interface IMultiRoom
         * @property {boolean|null} [enabled] MultiRoom enabled
         * @property {number|null} [roomsCount] MultiRoom roomsCount
         * @property {SearchForm.MultiRoomMode|null} [mode] MultiRoom mode
         */

        /**
         * Constructs a new MultiRoom.
         * @memberof SearchForm
         * @classdesc Represents a MultiRoom.
         * @implements IMultiRoom
         * @constructor
         * @param {SearchForm.IMultiRoom=} [properties] Properties to set
         */
        function MultiRoom(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MultiRoom enabled.
         * @member {boolean} enabled
         * @memberof SearchForm.MultiRoom
         * @instance
         */
        MultiRoom.prototype.enabled = false;

        /**
         * MultiRoom roomsCount.
         * @member {number} roomsCount
         * @memberof SearchForm.MultiRoom
         * @instance
         */
        MultiRoom.prototype.roomsCount = 0;

        /**
         * MultiRoom mode.
         * @member {SearchForm.MultiRoomMode} mode
         * @memberof SearchForm.MultiRoom
         * @instance
         */
        MultiRoom.prototype.mode = 1;

        /**
         * Creates a new MultiRoom instance using the specified properties.
         * @function create
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {SearchForm.IMultiRoom=} [properties] Properties to set
         * @returns {SearchForm.MultiRoom} MultiRoom instance
         */
        MultiRoom.create = function create(properties) {
            return new MultiRoom(properties);
        };

        /**
         * Encodes the specified MultiRoom message. Does not implicitly {@link SearchForm.MultiRoom.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {SearchForm.IMultiRoom} message MultiRoom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiRoom.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
            if (message.roomsCount != null && Object.hasOwnProperty.call(message, "roomsCount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomsCount);
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mode);
            return writer;
        };

        /**
         * Encodes the specified MultiRoom message, length delimited. Does not implicitly {@link SearchForm.MultiRoom.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {SearchForm.IMultiRoom} message MultiRoom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiRoom.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MultiRoom message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.MultiRoom} MultiRoom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiRoom.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.MultiRoom();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.enabled = reader.bool();
                        break;
                    }
                case 2: {
                        message.roomsCount = reader.int32();
                        break;
                    }
                case 3: {
                        message.mode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MultiRoom message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.MultiRoom} MultiRoom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiRoom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MultiRoom message.
         * @function verify
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MultiRoom.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            if (message.roomsCount != null && message.hasOwnProperty("roomsCount"))
                if (!$util.isInteger(message.roomsCount))
                    return "roomsCount: integer expected";
            if (message.mode != null && message.hasOwnProperty("mode"))
                switch (message.mode) {
                default:
                    return "mode: enum value expected";
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a MultiRoom message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.MultiRoom} MultiRoom
         */
        MultiRoom.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.MultiRoom)
                return object;
            let message = new $root.SearchForm.MultiRoom();
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            if (object.roomsCount != null)
                message.roomsCount = object.roomsCount | 0;
            switch (object.mode) {
            default:
                if (typeof object.mode === "number") {
                    message.mode = object.mode;
                    break;
                }
                break;
            case "auto":
            case 1:
                message.mode = 1;
                break;
            case "manual":
            case 2:
                message.mode = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a MultiRoom message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {SearchForm.MultiRoom} message MultiRoom
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MultiRoom.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.enabled = false;
                object.roomsCount = 0;
                object.mode = options.enums === String ? "auto" : 1;
            }
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            if (message.roomsCount != null && message.hasOwnProperty("roomsCount"))
                object.roomsCount = message.roomsCount;
            if (message.mode != null && message.hasOwnProperty("mode"))
                object.mode = options.enums === String ? $root.SearchForm.MultiRoomMode[message.mode] === undefined ? message.mode : $root.SearchForm.MultiRoomMode[message.mode] : message.mode;
            return object;
        };

        /**
         * Converts this MultiRoom to JSON.
         * @function toJSON
         * @memberof SearchForm.MultiRoom
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MultiRoom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MultiRoom
         * @function getTypeUrl
         * @memberof SearchForm.MultiRoom
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MultiRoom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.MultiRoom";
        };

        return MultiRoom;
    })();

    SearchForm.Rooms = (function() {

        /**
         * Properties of a Rooms.
         * @memberof SearchForm
         * @interface IRooms
         * @property {Array.<SearchForm.IRoom>|null} [rooms] Rooms rooms
         * @property {SearchForm.IMultiRoom|null} [multiRoom] Rooms multiRoom
         */

        /**
         * Constructs a new Rooms.
         * @memberof SearchForm
         * @classdesc Represents a Rooms.
         * @implements IRooms
         * @constructor
         * @param {SearchForm.IRooms=} [properties] Properties to set
         */
        function Rooms(properties) {
            this.rooms = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Rooms rooms.
         * @member {Array.<SearchForm.IRoom>} rooms
         * @memberof SearchForm.Rooms
         * @instance
         */
        Rooms.prototype.rooms = $util.emptyArray;

        /**
         * Rooms multiRoom.
         * @member {SearchForm.IMultiRoom|null|undefined} multiRoom
         * @memberof SearchForm.Rooms
         * @instance
         */
        Rooms.prototype.multiRoom = null;

        /**
         * Creates a new Rooms instance using the specified properties.
         * @function create
         * @memberof SearchForm.Rooms
         * @static
         * @param {SearchForm.IRooms=} [properties] Properties to set
         * @returns {SearchForm.Rooms} Rooms instance
         */
        Rooms.create = function create(properties) {
            return new Rooms(properties);
        };

        /**
         * Encodes the specified Rooms message. Does not implicitly {@link SearchForm.Rooms.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.Rooms
         * @static
         * @param {SearchForm.IRooms} message Rooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rooms.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rooms != null && message.rooms.length)
                for (let i = 0; i < message.rooms.length; ++i)
                    $root.SearchForm.Room.encode(message.rooms[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.multiRoom != null && Object.hasOwnProperty.call(message, "multiRoom"))
                $root.SearchForm.MultiRoom.encode(message.multiRoom, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Rooms message, length delimited. Does not implicitly {@link SearchForm.Rooms.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.Rooms
         * @static
         * @param {SearchForm.IRooms} message Rooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rooms.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Rooms message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.Rooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.Rooms} Rooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rooms.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.Rooms();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rooms && message.rooms.length))
                            message.rooms = [];
                        message.rooms.push($root.SearchForm.Room.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.multiRoom = $root.SearchForm.MultiRoom.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Rooms message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.Rooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.Rooms} Rooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rooms.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Rooms message.
         * @function verify
         * @memberof SearchForm.Rooms
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Rooms.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rooms != null && message.hasOwnProperty("rooms")) {
                if (!Array.isArray(message.rooms))
                    return "rooms: array expected";
                for (let i = 0; i < message.rooms.length; ++i) {
                    let error = $root.SearchForm.Room.verify(message.rooms[i]);
                    if (error)
                        return "rooms." + error;
                }
            }
            if (message.multiRoom != null && message.hasOwnProperty("multiRoom")) {
                let error = $root.SearchForm.MultiRoom.verify(message.multiRoom);
                if (error)
                    return "multiRoom." + error;
            }
            return null;
        };

        /**
         * Creates a Rooms message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.Rooms
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.Rooms} Rooms
         */
        Rooms.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.Rooms)
                return object;
            let message = new $root.SearchForm.Rooms();
            if (object.rooms) {
                if (!Array.isArray(object.rooms))
                    throw TypeError(".SearchForm.Rooms.rooms: array expected");
                message.rooms = [];
                for (let i = 0; i < object.rooms.length; ++i) {
                    if (typeof object.rooms[i] !== "object")
                        throw TypeError(".SearchForm.Rooms.rooms: object expected");
                    message.rooms[i] = $root.SearchForm.Room.fromObject(object.rooms[i]);
                }
            }
            if (object.multiRoom != null) {
                if (typeof object.multiRoom !== "object")
                    throw TypeError(".SearchForm.Rooms.multiRoom: object expected");
                message.multiRoom = $root.SearchForm.MultiRoom.fromObject(object.multiRoom);
            }
            return message;
        };

        /**
         * Creates a plain object from a Rooms message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.Rooms
         * @static
         * @param {SearchForm.Rooms} message Rooms
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Rooms.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rooms = [];
            if (options.defaults)
                object.multiRoom = null;
            if (message.rooms && message.rooms.length) {
                object.rooms = [];
                for (let j = 0; j < message.rooms.length; ++j)
                    object.rooms[j] = $root.SearchForm.Room.toObject(message.rooms[j], options);
            }
            if (message.multiRoom != null && message.hasOwnProperty("multiRoom"))
                object.multiRoom = $root.SearchForm.MultiRoom.toObject(message.multiRoom, options);
            return object;
        };

        /**
         * Converts this Rooms to JSON.
         * @function toJSON
         * @memberof SearchForm.Rooms
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Rooms.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Rooms
         * @function getTypeUrl
         * @memberof SearchForm.Rooms
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Rooms.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.Rooms";
        };

        return Rooms;
    })();

    /**
     * Attribute enum.
     * @name SearchForm.Attribute
     * @enum {number}
     * @property {number} location_mountains=0 location_mountains value
     * @property {number} location_near_the_slope=1 location_near_the_slope value
     * @property {number} location_exotic_offers=2 location_exotic_offers value
     * @property {number} location_lakes=3 location_lakes value
     * @property {number} location_ski_resorts=4 location_ski_resorts value
     * @property {number} location_seaside_resorts=5 location_seaside_resorts value
     * @property {number} location_city_breaks=6 location_city_breaks value
     * @property {number} location_directly_on_the_beach=7 location_directly_on_the_beach value
     * @property {number} location_near_airport=8 location_near_airport value
     * @property {number} location_near_the_center=9 location_near_the_center value
     * @property {number} location_without_passport_from_pl=10 location_without_passport_from_pl value
     * @property {number} facility_free_wifi=11 facility_free_wifi value
     * @property {number} facility_for_adult=12 facility_for_adult value
     * @property {number} facility_wellness=13 facility_wellness value
     * @property {number} facility_gay_friendly=14 facility_gay_friendly value
     * @property {number} facility_barbecue=15 facility_barbecue value
     * @property {number} facility_home_appliances=16 facility_home_appliances value
     * @property {number} facility_fireplace=17 facility_fireplace value
     * @property {number} facility_parking=18 facility_parking value
     * @property {number} facility_for_families_with_childrens=19 facility_for_families_with_childrens value
     * @property {number} facility_club_hotel=20 facility_club_hotel value
     * @property {number} facility_fitness_or_gym=21 facility_fitness_or_gym value
     * @property {number} facility_outdoor_pool=22 facility_outdoor_pool value
     * @property {number} facility_indoor_pool=23 facility_indoor_pool value
     * @property {number} facility_kindergarten=24 facility_kindergarten value
     * @property {number} facility_water_slides=25 facility_water_slides value
     * @property {number} facility_facilities_for_disabled=26 facility_facilities_for_disabled value
     * @property {number} facility_pets=27 facility_pets value
     * @property {number} facility_internet_access=28 facility_internet_access value
     * @property {number} facility_air_conditioning=29 facility_air_conditioning value
     * @property {number} facility_night_life=30 facility_night_life value
     * @property {number} facility_winter_equipment_rental=31 facility_winter_equipment_rental value
     * @property {number} activity_bicycles=32 activity_bicycles value
     * @property {number} activity_animation_for_adults=33 activity_animation_for_adults value
     * @property {number} activity_sport=34 activity_sport value
     * @property {number} activity_tennis=35 activity_tennis value
     * @property {number} activity_golf=36 activity_golf value
     * @property {number} activity_minigolf=37 activity_minigolf value
     * @property {number} activity_water_sports=38 activity_water_sports value
     * @property {number} activity_diving=39 activity_diving value
     * @property {number} activity_riding=40 activity_riding value
     * @property {number} activity_nordic_walking=41 activity_nordic_walking value
     * @property {number} activity_windsurfing=42 activity_windsurfing value
     * @property {number} activity_sailing=43 activity_sailing value
     * @property {number} activity_winter_sports=44 activity_winter_sports value
     */
    SearchForm.Attribute = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "location_mountains"] = 0;
        values[valuesById[1] = "location_near_the_slope"] = 1;
        values[valuesById[2] = "location_exotic_offers"] = 2;
        values[valuesById[3] = "location_lakes"] = 3;
        values[valuesById[4] = "location_ski_resorts"] = 4;
        values[valuesById[5] = "location_seaside_resorts"] = 5;
        values[valuesById[6] = "location_city_breaks"] = 6;
        values[valuesById[7] = "location_directly_on_the_beach"] = 7;
        values[valuesById[8] = "location_near_airport"] = 8;
        values[valuesById[9] = "location_near_the_center"] = 9;
        values[valuesById[10] = "location_without_passport_from_pl"] = 10;
        values[valuesById[11] = "facility_free_wifi"] = 11;
        values[valuesById[12] = "facility_for_adult"] = 12;
        values[valuesById[13] = "facility_wellness"] = 13;
        values[valuesById[14] = "facility_gay_friendly"] = 14;
        values[valuesById[15] = "facility_barbecue"] = 15;
        values[valuesById[16] = "facility_home_appliances"] = 16;
        values[valuesById[17] = "facility_fireplace"] = 17;
        values[valuesById[18] = "facility_parking"] = 18;
        values[valuesById[19] = "facility_for_families_with_childrens"] = 19;
        values[valuesById[20] = "facility_club_hotel"] = 20;
        values[valuesById[21] = "facility_fitness_or_gym"] = 21;
        values[valuesById[22] = "facility_outdoor_pool"] = 22;
        values[valuesById[23] = "facility_indoor_pool"] = 23;
        values[valuesById[24] = "facility_kindergarten"] = 24;
        values[valuesById[25] = "facility_water_slides"] = 25;
        values[valuesById[26] = "facility_facilities_for_disabled"] = 26;
        values[valuesById[27] = "facility_pets"] = 27;
        values[valuesById[28] = "facility_internet_access"] = 28;
        values[valuesById[29] = "facility_air_conditioning"] = 29;
        values[valuesById[30] = "facility_night_life"] = 30;
        values[valuesById[31] = "facility_winter_equipment_rental"] = 31;
        values[valuesById[32] = "activity_bicycles"] = 32;
        values[valuesById[33] = "activity_animation_for_adults"] = 33;
        values[valuesById[34] = "activity_sport"] = 34;
        values[valuesById[35] = "activity_tennis"] = 35;
        values[valuesById[36] = "activity_golf"] = 36;
        values[valuesById[37] = "activity_minigolf"] = 37;
        values[valuesById[38] = "activity_water_sports"] = 38;
        values[valuesById[39] = "activity_diving"] = 39;
        values[valuesById[40] = "activity_riding"] = 40;
        values[valuesById[41] = "activity_nordic_walking"] = 41;
        values[valuesById[42] = "activity_windsurfing"] = 42;
        values[valuesById[43] = "activity_sailing"] = 43;
        values[valuesById[44] = "activity_winter_sports"] = 44;
        return values;
    })();

    /**
     * Availability enum.
     * @name SearchForm.Availability
     * @enum {number}
     * @property {number} available=0 available value
     * @property {number} notavailable=1 notavailable value
     * @property {number} onrequest=2 onrequest value
     */
    SearchForm.Availability = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "available"] = 0;
        values[valuesById[1] = "notavailable"] = 1;
        values[valuesById[2] = "onrequest"] = 2;
        return values;
    })();

    SearchForm.RangeInt = (function() {

        /**
         * Properties of a RangeInt.
         * @memberof SearchForm
         * @interface IRangeInt
         * @property {number|null} [min] RangeInt min
         * @property {number|null} [max] RangeInt max
         */

        /**
         * Constructs a new RangeInt.
         * @memberof SearchForm
         * @classdesc Represents a RangeInt.
         * @implements IRangeInt
         * @constructor
         * @param {SearchForm.IRangeInt=} [properties] Properties to set
         */
        function RangeInt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RangeInt min.
         * @member {number} min
         * @memberof SearchForm.RangeInt
         * @instance
         */
        RangeInt.prototype.min = 0;

        /**
         * RangeInt max.
         * @member {number} max
         * @memberof SearchForm.RangeInt
         * @instance
         */
        RangeInt.prototype.max = 0;

        /**
         * Creates a new RangeInt instance using the specified properties.
         * @function create
         * @memberof SearchForm.RangeInt
         * @static
         * @param {SearchForm.IRangeInt=} [properties] Properties to set
         * @returns {SearchForm.RangeInt} RangeInt instance
         */
        RangeInt.create = function create(properties) {
            return new RangeInt(properties);
        };

        /**
         * Encodes the specified RangeInt message. Does not implicitly {@link SearchForm.RangeInt.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.RangeInt
         * @static
         * @param {SearchForm.IRangeInt} message RangeInt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeInt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.max);
            return writer;
        };

        /**
         * Encodes the specified RangeInt message, length delimited. Does not implicitly {@link SearchForm.RangeInt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.RangeInt
         * @static
         * @param {SearchForm.IRangeInt} message RangeInt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeInt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RangeInt message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.RangeInt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.RangeInt} RangeInt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeInt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.RangeInt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.min = reader.int32();
                        break;
                    }
                case 2: {
                        message.max = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RangeInt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.RangeInt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.RangeInt} RangeInt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeInt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RangeInt message.
         * @function verify
         * @memberof SearchForm.RangeInt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RangeInt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.min != null && message.hasOwnProperty("min"))
                if (!$util.isInteger(message.min))
                    return "min: integer expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (!$util.isInteger(message.max))
                    return "max: integer expected";
            return null;
        };

        /**
         * Creates a RangeInt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.RangeInt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.RangeInt} RangeInt
         */
        RangeInt.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.RangeInt)
                return object;
            let message = new $root.SearchForm.RangeInt();
            if (object.min != null)
                message.min = object.min | 0;
            if (object.max != null)
                message.max = object.max | 0;
            return message;
        };

        /**
         * Creates a plain object from a RangeInt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.RangeInt
         * @static
         * @param {SearchForm.RangeInt} message RangeInt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RangeInt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.min = 0;
                object.max = 0;
            }
            if (message.min != null && message.hasOwnProperty("min"))
                object.min = message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                object.max = message.max;
            return object;
        };

        /**
         * Converts this RangeInt to JSON.
         * @function toJSON
         * @memberof SearchForm.RangeInt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RangeInt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RangeInt
         * @function getTypeUrl
         * @memberof SearchForm.RangeInt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RangeInt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.RangeInt";
        };

        return RangeInt;
    })();

    SearchForm.RangeIntUp = (function() {

        /**
         * Properties of a RangeIntUp.
         * @memberof SearchForm
         * @interface IRangeIntUp
         * @property {number|null} [Min] RangeIntUp Min
         * @property {number|null} [Max] RangeIntUp Max
         */

        /**
         * Constructs a new RangeIntUp.
         * @memberof SearchForm
         * @classdesc Represents a RangeIntUp.
         * @implements IRangeIntUp
         * @constructor
         * @param {SearchForm.IRangeIntUp=} [properties] Properties to set
         */
        function RangeIntUp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RangeIntUp Min.
         * @member {number} Min
         * @memberof SearchForm.RangeIntUp
         * @instance
         */
        RangeIntUp.prototype.Min = 0;

        /**
         * RangeIntUp Max.
         * @member {number} Max
         * @memberof SearchForm.RangeIntUp
         * @instance
         */
        RangeIntUp.prototype.Max = 0;

        /**
         * Creates a new RangeIntUp instance using the specified properties.
         * @function create
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {SearchForm.IRangeIntUp=} [properties] Properties to set
         * @returns {SearchForm.RangeIntUp} RangeIntUp instance
         */
        RangeIntUp.create = function create(properties) {
            return new RangeIntUp(properties);
        };

        /**
         * Encodes the specified RangeIntUp message. Does not implicitly {@link SearchForm.RangeIntUp.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {SearchForm.IRangeIntUp} message RangeIntUp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeIntUp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Min != null && Object.hasOwnProperty.call(message, "Min"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Min);
            if (message.Max != null && Object.hasOwnProperty.call(message, "Max"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Max);
            return writer;
        };

        /**
         * Encodes the specified RangeIntUp message, length delimited. Does not implicitly {@link SearchForm.RangeIntUp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {SearchForm.IRangeIntUp} message RangeIntUp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeIntUp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RangeIntUp message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.RangeIntUp} RangeIntUp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeIntUp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.RangeIntUp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Min = reader.int32();
                        break;
                    }
                case 2: {
                        message.Max = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RangeIntUp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.RangeIntUp} RangeIntUp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeIntUp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RangeIntUp message.
         * @function verify
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RangeIntUp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Min != null && message.hasOwnProperty("Min"))
                if (!$util.isInteger(message.Min))
                    return "Min: integer expected";
            if (message.Max != null && message.hasOwnProperty("Max"))
                if (!$util.isInteger(message.Max))
                    return "Max: integer expected";
            return null;
        };

        /**
         * Creates a RangeIntUp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.RangeIntUp} RangeIntUp
         */
        RangeIntUp.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.RangeIntUp)
                return object;
            let message = new $root.SearchForm.RangeIntUp();
            if (object.Min != null)
                message.Min = object.Min | 0;
            if (object.Max != null)
                message.Max = object.Max | 0;
            return message;
        };

        /**
         * Creates a plain object from a RangeIntUp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {SearchForm.RangeIntUp} message RangeIntUp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RangeIntUp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.Min = 0;
                object.Max = 0;
            }
            if (message.Min != null && message.hasOwnProperty("Min"))
                object.Min = message.Min;
            if (message.Max != null && message.hasOwnProperty("Max"))
                object.Max = message.Max;
            return object;
        };

        /**
         * Converts this RangeIntUp to JSON.
         * @function toJSON
         * @memberof SearchForm.RangeIntUp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RangeIntUp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RangeIntUp
         * @function getTypeUrl
         * @memberof SearchForm.RangeIntUp
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RangeIntUp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.RangeIntUp";
        };

        return RangeIntUp;
    })();

    SearchForm.PriceRanges = (function() {

        /**
         * Properties of a PriceRanges.
         * @memberof SearchForm
         * @interface IPriceRanges
         * @property {SearchForm.IRangeIntUp|null} [FirstPerson] PriceRanges FirstPerson
         * @property {SearchForm.IRangeIntUp|null} [Total] PriceRanges Total
         */

        /**
         * Constructs a new PriceRanges.
         * @memberof SearchForm
         * @classdesc Represents a PriceRanges.
         * @implements IPriceRanges
         * @constructor
         * @param {SearchForm.IPriceRanges=} [properties] Properties to set
         */
        function PriceRanges(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceRanges FirstPerson.
         * @member {SearchForm.IRangeIntUp|null|undefined} FirstPerson
         * @memberof SearchForm.PriceRanges
         * @instance
         */
        PriceRanges.prototype.FirstPerson = null;

        /**
         * PriceRanges Total.
         * @member {SearchForm.IRangeIntUp|null|undefined} Total
         * @memberof SearchForm.PriceRanges
         * @instance
         */
        PriceRanges.prototype.Total = null;

        /**
         * Creates a new PriceRanges instance using the specified properties.
         * @function create
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {SearchForm.IPriceRanges=} [properties] Properties to set
         * @returns {SearchForm.PriceRanges} PriceRanges instance
         */
        PriceRanges.create = function create(properties) {
            return new PriceRanges(properties);
        };

        /**
         * Encodes the specified PriceRanges message. Does not implicitly {@link SearchForm.PriceRanges.verify|verify} messages.
         * @function encode
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {SearchForm.IPriceRanges} message PriceRanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceRanges.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.FirstPerson != null && Object.hasOwnProperty.call(message, "FirstPerson"))
                $root.SearchForm.RangeIntUp.encode(message.FirstPerson, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.Total != null && Object.hasOwnProperty.call(message, "Total"))
                $root.SearchForm.RangeIntUp.encode(message.Total, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PriceRanges message, length delimited. Does not implicitly {@link SearchForm.PriceRanges.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {SearchForm.IPriceRanges} message PriceRanges message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceRanges.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PriceRanges message from the specified reader or buffer.
         * @function decode
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchForm.PriceRanges} PriceRanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceRanges.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchForm.PriceRanges();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.FirstPerson = $root.SearchForm.RangeIntUp.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.Total = $root.SearchForm.RangeIntUp.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PriceRanges message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchForm.PriceRanges} PriceRanges
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceRanges.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PriceRanges message.
         * @function verify
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PriceRanges.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.FirstPerson != null && message.hasOwnProperty("FirstPerson")) {
                let error = $root.SearchForm.RangeIntUp.verify(message.FirstPerson);
                if (error)
                    return "FirstPerson." + error;
            }
            if (message.Total != null && message.hasOwnProperty("Total")) {
                let error = $root.SearchForm.RangeIntUp.verify(message.Total);
                if (error)
                    return "Total." + error;
            }
            return null;
        };

        /**
         * Creates a PriceRanges message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchForm.PriceRanges} PriceRanges
         */
        PriceRanges.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchForm.PriceRanges)
                return object;
            let message = new $root.SearchForm.PriceRanges();
            if (object.FirstPerson != null) {
                if (typeof object.FirstPerson !== "object")
                    throw TypeError(".SearchForm.PriceRanges.FirstPerson: object expected");
                message.FirstPerson = $root.SearchForm.RangeIntUp.fromObject(object.FirstPerson);
            }
            if (object.Total != null) {
                if (typeof object.Total !== "object")
                    throw TypeError(".SearchForm.PriceRanges.Total: object expected");
                message.Total = $root.SearchForm.RangeIntUp.fromObject(object.Total);
            }
            return message;
        };

        /**
         * Creates a plain object from a PriceRanges message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {SearchForm.PriceRanges} message PriceRanges
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PriceRanges.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.FirstPerson = null;
                object.Total = null;
            }
            if (message.FirstPerson != null && message.hasOwnProperty("FirstPerson"))
                object.FirstPerson = $root.SearchForm.RangeIntUp.toObject(message.FirstPerson, options);
            if (message.Total != null && message.hasOwnProperty("Total"))
                object.Total = $root.SearchForm.RangeIntUp.toObject(message.Total, options);
            return object;
        };

        /**
         * Converts this PriceRanges to JSON.
         * @function toJSON
         * @memberof SearchForm.PriceRanges
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PriceRanges.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PriceRanges
         * @function getTypeUrl
         * @memberof SearchForm.PriceRanges
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PriceRanges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/SearchForm.PriceRanges";
        };

        return PriceRanges;
    })();

    return SearchForm;
})();

export const Filters = $root.Filters = (() => {

    /**
     * Properties of a Filters.
     * @exports IFilters
     * @interface IFilters
     * @property {string|null} [Base_StartDate] Filters Base_StartDate
     * @property {Filters.DayOfWeek|null} [Base_StartDateDayOfWeek] Filters Base_StartDateDayOfWeek
     * @property {number|null} [Base_NightsBeforeReturn] Filters Base_NightsBeforeReturn
     * @property {string|null} [Base_XCity] Filters Base_XCity
     * @property {string|null} [Base_DepartureLocation] Filters Base_DepartureLocation
     * @property {string|null} [Accommodation_Room] Filters Accommodation_Room
     * @property {number|null} [Accommodation_Category] Filters Accommodation_Category
     * @property {string|null} [Base_Catalog] Filters Base_Catalog
     * @property {string|null} [Base_Operator] Filters Base_Operator
     * @property {number|null} [Accommodation_XService] Filters Accommodation_XService
     * @property {string|null} [Accommodation_XCode] Filters Accommodation_XCode
     * @property {Array.<string>|null} [Base_DestinationLocation] Filters Base_DestinationLocation
     * @property {Array.<Filters.Attribute>|null} [Accommodation_Attributes] Filters Accommodation_Attributes
     * @property {number|null} [Accommodation_BuildRenovationYear] Filters Accommodation_BuildRenovationYear
     */

    /**
     * Constructs a new Filters.
     * @exports Filters
     * @classdesc Represents a Filters.
     * @implements IFilters
     * @constructor
     * @param {IFilters=} [properties] Properties to set
     */
    function Filters(properties) {
        this.Base_DestinationLocation = [];
        this.Accommodation_Attributes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Filters Base_StartDate.
     * @member {string} Base_StartDate
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_StartDate = "";

    /**
     * Filters Base_StartDateDayOfWeek.
     * @member {Filters.DayOfWeek} Base_StartDateDayOfWeek
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_StartDateDayOfWeek = undefined;

    /**
     * Filters Base_NightsBeforeReturn.
     * @member {number} Base_NightsBeforeReturn
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_NightsBeforeReturn = -1;

    /**
     * Filters Base_XCity.
     * @member {string} Base_XCity
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_XCity = "";

    /**
     * Filters Base_DepartureLocation.
     * @member {string} Base_DepartureLocation
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_DepartureLocation = "";

    /**
     * Filters Accommodation_Room.
     * @member {string} Accommodation_Room
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_Room = "";

    /**
     * Filters Accommodation_Category.
     * @member {number} Accommodation_Category
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_Category = 0;

    /**
     * Filters Base_Catalog.
     * @member {string} Base_Catalog
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_Catalog = "";

    /**
     * Filters Base_Operator.
     * @member {string} Base_Operator
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_Operator = "";

    /**
     * Filters Accommodation_XService.
     * @member {number} Accommodation_XService
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_XService = 0;

    /**
     * Filters Accommodation_XCode.
     * @member {string} Accommodation_XCode
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_XCode = "";

    /**
     * Filters Base_DestinationLocation.
     * @member {Array.<string>} Base_DestinationLocation
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Base_DestinationLocation = $util.emptyArray;

    /**
     * Filters Accommodation_Attributes.
     * @member {Array.<Filters.Attribute>} Accommodation_Attributes
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_Attributes = $util.emptyArray;

    /**
     * Filters Accommodation_BuildRenovationYear.
     * @member {number} Accommodation_BuildRenovationYear
     * @memberof Filters
     * @instance
     */
    Filters.prototype.Accommodation_BuildRenovationYear = 0;

    /**
     * Creates a new Filters instance using the specified properties.
     * @function create
     * @memberof Filters
     * @static
     * @param {IFilters=} [properties] Properties to set
     * @returns {Filters} Filters instance
     */
    Filters.create = function create(properties) {
        return new Filters(properties);
    };

    /**
     * Encodes the specified Filters message. Does not implicitly {@link Filters.verify|verify} messages.
     * @function encode
     * @memberof Filters
     * @static
     * @param {IFilters} message Filters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Filters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Base_StartDate != null && Object.hasOwnProperty.call(message, "Base_StartDate"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Base_StartDate);
        if (message.Base_StartDateDayOfWeek != null && Object.hasOwnProperty.call(message, "Base_StartDateDayOfWeek"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Base_StartDateDayOfWeek);
        if (message.Base_NightsBeforeReturn != null && Object.hasOwnProperty.call(message, "Base_NightsBeforeReturn"))
            writer.uint32(/* id 3, wireType 0 =*/24).sint32(message.Base_NightsBeforeReturn);
        if (message.Base_XCity != null && Object.hasOwnProperty.call(message, "Base_XCity"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Base_XCity);
        if (message.Base_DepartureLocation != null && Object.hasOwnProperty.call(message, "Base_DepartureLocation"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Base_DepartureLocation);
        if (message.Accommodation_Room != null && Object.hasOwnProperty.call(message, "Accommodation_Room"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Accommodation_Room);
        if (message.Accommodation_Category != null && Object.hasOwnProperty.call(message, "Accommodation_Category"))
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.Accommodation_Category);
        if (message.Base_Catalog != null && Object.hasOwnProperty.call(message, "Base_Catalog"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.Base_Catalog);
        if (message.Base_Operator != null && Object.hasOwnProperty.call(message, "Base_Operator"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.Base_Operator);
        if (message.Accommodation_XService != null && Object.hasOwnProperty.call(message, "Accommodation_XService"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.Accommodation_XService);
        if (message.Accommodation_XCode != null && Object.hasOwnProperty.call(message, "Accommodation_XCode"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.Accommodation_XCode);
        if (message.Base_DestinationLocation != null && message.Base_DestinationLocation.length)
            for (let i = 0; i < message.Base_DestinationLocation.length; ++i)
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.Base_DestinationLocation[i]);
        if (message.Accommodation_Attributes != null && message.Accommodation_Attributes.length) {
            writer.uint32(/* id 13, wireType 2 =*/106).fork();
            for (let i = 0; i < message.Accommodation_Attributes.length; ++i)
                writer.int32(message.Accommodation_Attributes[i]);
            writer.ldelim();
        }
        if (message.Accommodation_BuildRenovationYear != null && Object.hasOwnProperty.call(message, "Accommodation_BuildRenovationYear"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.Accommodation_BuildRenovationYear);
        return writer;
    };

    /**
     * Encodes the specified Filters message, length delimited. Does not implicitly {@link Filters.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Filters
     * @static
     * @param {IFilters} message Filters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Filters.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Filters message from the specified reader or buffer.
     * @function decode
     * @memberof Filters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Filters} Filters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Filters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Filters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Base_StartDate = reader.string();
                    break;
                }
            case 2: {
                    message.Base_StartDateDayOfWeek = reader.int32();
                    break;
                }
            case 3: {
                    message.Base_NightsBeforeReturn = reader.sint32();
                    break;
                }
            case 4: {
                    message.Base_XCity = reader.string();
                    break;
                }
            case 5: {
                    message.Base_DepartureLocation = reader.string();
                    break;
                }
            case 6: {
                    message.Accommodation_Room = reader.string();
                    break;
                }
            case 7: {
                    message.Accommodation_Category = reader.double();
                    break;
                }
            case 8: {
                    message.Base_Catalog = reader.string();
                    break;
                }
            case 9: {
                    message.Base_Operator = reader.string();
                    break;
                }
            case 10: {
                    message.Accommodation_XService = reader.int32();
                    break;
                }
            case 11: {
                    message.Accommodation_XCode = reader.string();
                    break;
                }
            case 12: {
                    if (!(message.Base_DestinationLocation && message.Base_DestinationLocation.length))
                        message.Base_DestinationLocation = [];
                    message.Base_DestinationLocation.push(reader.string());
                    break;
                }
            case 13: {
                    if (!(message.Accommodation_Attributes && message.Accommodation_Attributes.length))
                        message.Accommodation_Attributes = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Accommodation_Attributes.push(reader.int32());
                    } else
                        message.Accommodation_Attributes.push(reader.int32());
                    break;
                }
            case 14: {
                    message.Accommodation_BuildRenovationYear = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Filters message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Filters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Filters} Filters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Filters.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Filters message.
     * @function verify
     * @memberof Filters
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Filters.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Base_StartDate != null && message.hasOwnProperty("Base_StartDate"))
            if (!$util.isString(message.Base_StartDate))
                return "Base_StartDate: string expected";
        if (message.Base_StartDateDayOfWeek != null && message.hasOwnProperty("Base_StartDateDayOfWeek"))
            switch (message.Base_StartDateDayOfWeek) {
            default:
                return "Base_StartDateDayOfWeek: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.Base_NightsBeforeReturn != null && message.hasOwnProperty("Base_NightsBeforeReturn"))
            if (!$util.isInteger(message.Base_NightsBeforeReturn))
                return "Base_NightsBeforeReturn: integer expected";
        if (message.Base_XCity != null && message.hasOwnProperty("Base_XCity"))
            if (!$util.isString(message.Base_XCity))
                return "Base_XCity: string expected";
        if (message.Base_DepartureLocation != null && message.hasOwnProperty("Base_DepartureLocation"))
            if (!$util.isString(message.Base_DepartureLocation))
                return "Base_DepartureLocation: string expected";
        if (message.Accommodation_Room != null && message.hasOwnProperty("Accommodation_Room"))
            if (!$util.isString(message.Accommodation_Room))
                return "Accommodation_Room: string expected";
        if (message.Accommodation_Category != null && message.hasOwnProperty("Accommodation_Category"))
            if (typeof message.Accommodation_Category !== "number")
                return "Accommodation_Category: number expected";
        if (message.Base_Catalog != null && message.hasOwnProperty("Base_Catalog"))
            if (!$util.isString(message.Base_Catalog))
                return "Base_Catalog: string expected";
        if (message.Base_Operator != null && message.hasOwnProperty("Base_Operator"))
            if (!$util.isString(message.Base_Operator))
                return "Base_Operator: string expected";
        if (message.Accommodation_XService != null && message.hasOwnProperty("Accommodation_XService"))
            if (!$util.isInteger(message.Accommodation_XService))
                return "Accommodation_XService: integer expected";
        if (message.Accommodation_XCode != null && message.hasOwnProperty("Accommodation_XCode"))
            if (!$util.isString(message.Accommodation_XCode))
                return "Accommodation_XCode: string expected";
        if (message.Base_DestinationLocation != null && message.hasOwnProperty("Base_DestinationLocation")) {
            if (!Array.isArray(message.Base_DestinationLocation))
                return "Base_DestinationLocation: array expected";
            for (let i = 0; i < message.Base_DestinationLocation.length; ++i)
                if (!$util.isString(message.Base_DestinationLocation[i]))
                    return "Base_DestinationLocation: string[] expected";
        }
        if (message.Accommodation_Attributes != null && message.hasOwnProperty("Accommodation_Attributes")) {
            if (!Array.isArray(message.Accommodation_Attributes))
                return "Accommodation_Attributes: array expected";
            for (let i = 0; i < message.Accommodation_Attributes.length; ++i)
                switch (message.Accommodation_Attributes[i]) {
                default:
                    return "Accommodation_Attributes: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                    break;
                }
        }
        if (message.Accommodation_BuildRenovationYear != null && message.hasOwnProperty("Accommodation_BuildRenovationYear"))
            if (!$util.isInteger(message.Accommodation_BuildRenovationYear))
                return "Accommodation_BuildRenovationYear: integer expected";
        return null;
    };

    /**
     * Creates a Filters message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Filters
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Filters} Filters
     */
    Filters.fromObject = function fromObject(object) {
        if (object instanceof $root.Filters)
            return object;
        let message = new $root.Filters();
        if (object.Base_StartDate != null)
            message.Base_StartDate = String(object.Base_StartDate);
        switch (object.Base_StartDateDayOfWeek) {
        case "Mon":
        case 0:
            message.Base_StartDateDayOfWeek = 0;
            break;
        case "Tue":
        case 1:
            message.Base_StartDateDayOfWeek = 1;
            break;
        case "Wed":
        case 2:
            message.Base_StartDateDayOfWeek = 2;
            break;
        case "Thu":
        case 3:
            message.Base_StartDateDayOfWeek = 3;
            break;
        case "Fri":
        case 4:
            message.Base_StartDateDayOfWeek = 4;
            break;
        case "Sat":
        case 5:
            message.Base_StartDateDayOfWeek = 5;
            break;
        case "Sun":
        case 6:
            message.Base_StartDateDayOfWeek = 6;
            break;
        }
        if (object.Base_NightsBeforeReturn != null)
            message.Base_NightsBeforeReturn = object.Base_NightsBeforeReturn | 0;
        if (object.Base_XCity != null)
            message.Base_XCity = String(object.Base_XCity);
        if (object.Base_DepartureLocation != null)
            message.Base_DepartureLocation = String(object.Base_DepartureLocation);
        if (object.Accommodation_Room != null)
            message.Accommodation_Room = String(object.Accommodation_Room);
        if (object.Accommodation_Category != null)
            message.Accommodation_Category = Number(object.Accommodation_Category);
        if (object.Base_Catalog != null)
            message.Base_Catalog = String(object.Base_Catalog);
        if (object.Base_Operator != null)
            message.Base_Operator = String(object.Base_Operator);
        if (object.Accommodation_XService != null)
            message.Accommodation_XService = object.Accommodation_XService | 0;
        if (object.Accommodation_XCode != null)
            message.Accommodation_XCode = String(object.Accommodation_XCode);
        if (object.Base_DestinationLocation) {
            if (!Array.isArray(object.Base_DestinationLocation))
                throw TypeError(".Filters.Base_DestinationLocation: array expected");
            message.Base_DestinationLocation = [];
            for (let i = 0; i < object.Base_DestinationLocation.length; ++i)
                message.Base_DestinationLocation[i] = String(object.Base_DestinationLocation[i]);
        }
        if (object.Accommodation_Attributes) {
            if (!Array.isArray(object.Accommodation_Attributes))
                throw TypeError(".Filters.Accommodation_Attributes: array expected");
            message.Accommodation_Attributes = [];
            for (let i = 0; i < object.Accommodation_Attributes.length; ++i)
                switch (object.Accommodation_Attributes[i]) {
                default:
                    if (typeof object.Accommodation_Attributes[i] === "number") {
                        message.Accommodation_Attributes[i] = object.Accommodation_Attributes[i];
                        break;
                    }
                case "location_mountains":
                case 0:
                    message.Accommodation_Attributes[i] = 0;
                    break;
                case "location_near_the_slope":
                case 1:
                    message.Accommodation_Attributes[i] = 1;
                    break;
                case "location_exotic_offers":
                case 2:
                    message.Accommodation_Attributes[i] = 2;
                    break;
                case "location_lakes":
                case 3:
                    message.Accommodation_Attributes[i] = 3;
                    break;
                case "location_ski_resorts":
                case 4:
                    message.Accommodation_Attributes[i] = 4;
                    break;
                case "location_seaside_resorts":
                case 5:
                    message.Accommodation_Attributes[i] = 5;
                    break;
                case "location_city_breaks":
                case 6:
                    message.Accommodation_Attributes[i] = 6;
                    break;
                case "location_directly_on_the_beach":
                case 7:
                    message.Accommodation_Attributes[i] = 7;
                    break;
                case "location_near_airport":
                case 8:
                    message.Accommodation_Attributes[i] = 8;
                    break;
                case "location_near_the_center":
                case 9:
                    message.Accommodation_Attributes[i] = 9;
                    break;
                case "location_without_passport_from_pl":
                case 10:
                    message.Accommodation_Attributes[i] = 10;
                    break;
                case "facility_free_wifi":
                case 11:
                    message.Accommodation_Attributes[i] = 11;
                    break;
                case "facility_for_adult":
                case 12:
                    message.Accommodation_Attributes[i] = 12;
                    break;
                case "facility_wellness":
                case 13:
                    message.Accommodation_Attributes[i] = 13;
                    break;
                case "facility_gay_friendly":
                case 14:
                    message.Accommodation_Attributes[i] = 14;
                    break;
                case "facility_barbecue":
                case 15:
                    message.Accommodation_Attributes[i] = 15;
                    break;
                case "facility_home_appliances":
                case 16:
                    message.Accommodation_Attributes[i] = 16;
                    break;
                case "facility_fireplace":
                case 17:
                    message.Accommodation_Attributes[i] = 17;
                    break;
                case "facility_parking":
                case 18:
                    message.Accommodation_Attributes[i] = 18;
                    break;
                case "facility_for_families_with_childrens":
                case 19:
                    message.Accommodation_Attributes[i] = 19;
                    break;
                case "facility_club_hotel":
                case 20:
                    message.Accommodation_Attributes[i] = 20;
                    break;
                case "facility_fitness_or_gym":
                case 21:
                    message.Accommodation_Attributes[i] = 21;
                    break;
                case "facility_outdoor_pool":
                case 22:
                    message.Accommodation_Attributes[i] = 22;
                    break;
                case "facility_indoor_pool":
                case 23:
                    message.Accommodation_Attributes[i] = 23;
                    break;
                case "facility_kindergarten":
                case 24:
                    message.Accommodation_Attributes[i] = 24;
                    break;
                case "facility_water_slides":
                case 25:
                    message.Accommodation_Attributes[i] = 25;
                    break;
                case "facility_facilities_for_disabled":
                case 26:
                    message.Accommodation_Attributes[i] = 26;
                    break;
                case "facility_pets":
                case 27:
                    message.Accommodation_Attributes[i] = 27;
                    break;
                case "facility_internet_access":
                case 28:
                    message.Accommodation_Attributes[i] = 28;
                    break;
                case "facility_air_conditioning":
                case 29:
                    message.Accommodation_Attributes[i] = 29;
                    break;
                case "facility_night_life":
                case 30:
                    message.Accommodation_Attributes[i] = 30;
                    break;
                case "facility_winter_equipment_rental":
                case 31:
                    message.Accommodation_Attributes[i] = 31;
                    break;
                case "activity_bicycles":
                case 32:
                    message.Accommodation_Attributes[i] = 32;
                    break;
                case "activity_animation_for_adults":
                case 33:
                    message.Accommodation_Attributes[i] = 33;
                    break;
                case "activity_sport":
                case 34:
                    message.Accommodation_Attributes[i] = 34;
                    break;
                case "activity_tennis":
                case 35:
                    message.Accommodation_Attributes[i] = 35;
                    break;
                case "activity_golf":
                case 36:
                    message.Accommodation_Attributes[i] = 36;
                    break;
                case "activity_minigolf":
                case 37:
                    message.Accommodation_Attributes[i] = 37;
                    break;
                case "activity_water_sports":
                case 38:
                    message.Accommodation_Attributes[i] = 38;
                    break;
                case "activity_diving":
                case 39:
                    message.Accommodation_Attributes[i] = 39;
                    break;
                case "activity_riding":
                case 40:
                    message.Accommodation_Attributes[i] = 40;
                    break;
                case "activity_nordic_walking":
                case 41:
                    message.Accommodation_Attributes[i] = 41;
                    break;
                case "activity_windsurfing":
                case 42:
                    message.Accommodation_Attributes[i] = 42;
                    break;
                case "activity_sailing":
                case 43:
                    message.Accommodation_Attributes[i] = 43;
                    break;
                case "activity_winter_sports":
                case 44:
                    message.Accommodation_Attributes[i] = 44;
                    break;
                }
        }
        if (object.Accommodation_BuildRenovationYear != null)
            message.Accommodation_BuildRenovationYear = object.Accommodation_BuildRenovationYear | 0;
        return message;
    };

    /**
     * Creates a plain object from a Filters message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Filters
     * @static
     * @param {Filters} message Filters
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Filters.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.Base_DestinationLocation = [];
            object.Accommodation_Attributes = [];
        }
        if (options.defaults) {
            object.Base_StartDate = "";
            object.Base_StartDateDayOfWeek = options.enums === String ? undefined : undefined;
            object.Base_NightsBeforeReturn = -1;
            object.Base_XCity = "";
            object.Base_DepartureLocation = "";
            object.Accommodation_Room = "";
            object.Accommodation_Category = 0;
            object.Base_Catalog = "";
            object.Base_Operator = "";
            object.Accommodation_XService = 0;
            object.Accommodation_XCode = "";
            object.Accommodation_BuildRenovationYear = 0;
        }
        if (message.Base_StartDate != null && message.hasOwnProperty("Base_StartDate"))
            object.Base_StartDate = message.Base_StartDate;
        if (message.Base_StartDateDayOfWeek != null && message.hasOwnProperty("Base_StartDateDayOfWeek"))
            object.Base_StartDateDayOfWeek = options.enums === String ? $root.Filters.DayOfWeek[message.Base_StartDateDayOfWeek] === undefined ? message.Base_StartDateDayOfWeek : $root.Filters.DayOfWeek[message.Base_StartDateDayOfWeek] : message.Base_StartDateDayOfWeek;
        if (message.Base_NightsBeforeReturn != null && message.hasOwnProperty("Base_NightsBeforeReturn"))
            object.Base_NightsBeforeReturn = message.Base_NightsBeforeReturn;
        if (message.Base_XCity != null && message.hasOwnProperty("Base_XCity"))
            object.Base_XCity = message.Base_XCity;
        if (message.Base_DepartureLocation != null && message.hasOwnProperty("Base_DepartureLocation"))
            object.Base_DepartureLocation = message.Base_DepartureLocation;
        if (message.Accommodation_Room != null && message.hasOwnProperty("Accommodation_Room"))
            object.Accommodation_Room = message.Accommodation_Room;
        if (message.Accommodation_Category != null && message.hasOwnProperty("Accommodation_Category"))
            object.Accommodation_Category = options.json && !isFinite(message.Accommodation_Category) ? String(message.Accommodation_Category) : message.Accommodation_Category;
        if (message.Base_Catalog != null && message.hasOwnProperty("Base_Catalog"))
            object.Base_Catalog = message.Base_Catalog;
        if (message.Base_Operator != null && message.hasOwnProperty("Base_Operator"))
            object.Base_Operator = message.Base_Operator;
        if (message.Accommodation_XService != null && message.hasOwnProperty("Accommodation_XService"))
            object.Accommodation_XService = message.Accommodation_XService;
        if (message.Accommodation_XCode != null && message.hasOwnProperty("Accommodation_XCode"))
            object.Accommodation_XCode = message.Accommodation_XCode;
        if (message.Base_DestinationLocation && message.Base_DestinationLocation.length) {
            object.Base_DestinationLocation = [];
            for (let j = 0; j < message.Base_DestinationLocation.length; ++j)
                object.Base_DestinationLocation[j] = message.Base_DestinationLocation[j];
        }
        if (message.Accommodation_Attributes && message.Accommodation_Attributes.length) {
            object.Accommodation_Attributes = [];
            for (let j = 0; j < message.Accommodation_Attributes.length; ++j)
                object.Accommodation_Attributes[j] = options.enums === String ? $root.Filters.Attribute[message.Accommodation_Attributes[j]] === undefined ? message.Accommodation_Attributes[j] : $root.Filters.Attribute[message.Accommodation_Attributes[j]] : message.Accommodation_Attributes[j];
        }
        if (message.Accommodation_BuildRenovationYear != null && message.hasOwnProperty("Accommodation_BuildRenovationYear"))
            object.Accommodation_BuildRenovationYear = message.Accommodation_BuildRenovationYear;
        return object;
    };

    /**
     * Converts this Filters to JSON.
     * @function toJSON
     * @memberof Filters
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Filters.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Filters
     * @function getTypeUrl
     * @memberof Filters
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Filters.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Filters";
    };

    /**
     * DayOfWeek enum.
     * @name Filters.DayOfWeek
     * @enum {number}
     * @property {number} Mon=0 Mon value
     * @property {number} Tue=1 Tue value
     * @property {number} Wed=2 Wed value
     * @property {number} Thu=3 Thu value
     * @property {number} Fri=4 Fri value
     * @property {number} Sat=5 Sat value
     * @property {number} Sun=6 Sun value
     */
    Filters.DayOfWeek = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Mon"] = 0;
        values[valuesById[1] = "Tue"] = 1;
        values[valuesById[2] = "Wed"] = 2;
        values[valuesById[3] = "Thu"] = 3;
        values[valuesById[4] = "Fri"] = 4;
        values[valuesById[5] = "Sat"] = 5;
        values[valuesById[6] = "Sun"] = 6;
        return values;
    })();

    /**
     * Attribute enum.
     * @name Filters.Attribute
     * @enum {number}
     * @property {number} location_mountains=0 location_mountains value
     * @property {number} location_near_the_slope=1 location_near_the_slope value
     * @property {number} location_exotic_offers=2 location_exotic_offers value
     * @property {number} location_lakes=3 location_lakes value
     * @property {number} location_ski_resorts=4 location_ski_resorts value
     * @property {number} location_seaside_resorts=5 location_seaside_resorts value
     * @property {number} location_city_breaks=6 location_city_breaks value
     * @property {number} location_directly_on_the_beach=7 location_directly_on_the_beach value
     * @property {number} location_near_airport=8 location_near_airport value
     * @property {number} location_near_the_center=9 location_near_the_center value
     * @property {number} location_without_passport_from_pl=10 location_without_passport_from_pl value
     * @property {number} facility_free_wifi=11 facility_free_wifi value
     * @property {number} facility_for_adult=12 facility_for_adult value
     * @property {number} facility_wellness=13 facility_wellness value
     * @property {number} facility_gay_friendly=14 facility_gay_friendly value
     * @property {number} facility_barbecue=15 facility_barbecue value
     * @property {number} facility_home_appliances=16 facility_home_appliances value
     * @property {number} facility_fireplace=17 facility_fireplace value
     * @property {number} facility_parking=18 facility_parking value
     * @property {number} facility_for_families_with_childrens=19 facility_for_families_with_childrens value
     * @property {number} facility_club_hotel=20 facility_club_hotel value
     * @property {number} facility_fitness_or_gym=21 facility_fitness_or_gym value
     * @property {number} facility_outdoor_pool=22 facility_outdoor_pool value
     * @property {number} facility_indoor_pool=23 facility_indoor_pool value
     * @property {number} facility_kindergarten=24 facility_kindergarten value
     * @property {number} facility_water_slides=25 facility_water_slides value
     * @property {number} facility_facilities_for_disabled=26 facility_facilities_for_disabled value
     * @property {number} facility_pets=27 facility_pets value
     * @property {number} facility_internet_access=28 facility_internet_access value
     * @property {number} facility_air_conditioning=29 facility_air_conditioning value
     * @property {number} facility_night_life=30 facility_night_life value
     * @property {number} facility_winter_equipment_rental=31 facility_winter_equipment_rental value
     * @property {number} activity_bicycles=32 activity_bicycles value
     * @property {number} activity_animation_for_adults=33 activity_animation_for_adults value
     * @property {number} activity_sport=34 activity_sport value
     * @property {number} activity_tennis=35 activity_tennis value
     * @property {number} activity_golf=36 activity_golf value
     * @property {number} activity_minigolf=37 activity_minigolf value
     * @property {number} activity_water_sports=38 activity_water_sports value
     * @property {number} activity_diving=39 activity_diving value
     * @property {number} activity_riding=40 activity_riding value
     * @property {number} activity_nordic_walking=41 activity_nordic_walking value
     * @property {number} activity_windsurfing=42 activity_windsurfing value
     * @property {number} activity_sailing=43 activity_sailing value
     * @property {number} activity_winter_sports=44 activity_winter_sports value
     */
    Filters.Attribute = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "location_mountains"] = 0;
        values[valuesById[1] = "location_near_the_slope"] = 1;
        values[valuesById[2] = "location_exotic_offers"] = 2;
        values[valuesById[3] = "location_lakes"] = 3;
        values[valuesById[4] = "location_ski_resorts"] = 4;
        values[valuesById[5] = "location_seaside_resorts"] = 5;
        values[valuesById[6] = "location_city_breaks"] = 6;
        values[valuesById[7] = "location_directly_on_the_beach"] = 7;
        values[valuesById[8] = "location_near_airport"] = 8;
        values[valuesById[9] = "location_near_the_center"] = 9;
        values[valuesById[10] = "location_without_passport_from_pl"] = 10;
        values[valuesById[11] = "facility_free_wifi"] = 11;
        values[valuesById[12] = "facility_for_adult"] = 12;
        values[valuesById[13] = "facility_wellness"] = 13;
        values[valuesById[14] = "facility_gay_friendly"] = 14;
        values[valuesById[15] = "facility_barbecue"] = 15;
        values[valuesById[16] = "facility_home_appliances"] = 16;
        values[valuesById[17] = "facility_fireplace"] = 17;
        values[valuesById[18] = "facility_parking"] = 18;
        values[valuesById[19] = "facility_for_families_with_childrens"] = 19;
        values[valuesById[20] = "facility_club_hotel"] = 20;
        values[valuesById[21] = "facility_fitness_or_gym"] = 21;
        values[valuesById[22] = "facility_outdoor_pool"] = 22;
        values[valuesById[23] = "facility_indoor_pool"] = 23;
        values[valuesById[24] = "facility_kindergarten"] = 24;
        values[valuesById[25] = "facility_water_slides"] = 25;
        values[valuesById[26] = "facility_facilities_for_disabled"] = 26;
        values[valuesById[27] = "facility_pets"] = 27;
        values[valuesById[28] = "facility_internet_access"] = 28;
        values[valuesById[29] = "facility_air_conditioning"] = 29;
        values[valuesById[30] = "facility_night_life"] = 30;
        values[valuesById[31] = "facility_winter_equipment_rental"] = 31;
        values[valuesById[32] = "activity_bicycles"] = 32;
        values[valuesById[33] = "activity_animation_for_adults"] = 33;
        values[valuesById[34] = "activity_sport"] = 34;
        values[valuesById[35] = "activity_tennis"] = 35;
        values[valuesById[36] = "activity_golf"] = 36;
        values[valuesById[37] = "activity_minigolf"] = 37;
        values[valuesById[38] = "activity_water_sports"] = 38;
        values[valuesById[39] = "activity_diving"] = 39;
        values[valuesById[40] = "activity_riding"] = 40;
        values[valuesById[41] = "activity_nordic_walking"] = 41;
        values[valuesById[42] = "activity_windsurfing"] = 42;
        values[valuesById[43] = "activity_sailing"] = 43;
        values[valuesById[44] = "activity_winter_sports"] = 44;
        return values;
    })();

    return Filters;
})();

export const Offer = $root.Offer = (() => {

    /**
     * Properties of an Offer.
     * @exports IOffer
     * @interface IOffer
     * @property {string|null} [id] Offer id
     * @property {string|null} [operator] Offer operator
     * @property {number|null} [adt] Offer adt
     * @property {Array.<string>|null} [chdDates] Offer chdDates
     * @property {Array.<string>|null} [infDates] Offer infDates
     * @property {Offer.IExtraHotel|null} [extraHotel] Offer extraHotel
     * @property {Offer.IChangeBook|null} [changeBook] Offer changeBook
     */

    /**
     * Constructs a new Offer.
     * @exports Offer
     * @classdesc Represents an Offer.
     * @implements IOffer
     * @constructor
     * @param {IOffer=} [properties] Properties to set
     */
    function Offer(properties) {
        this.chdDates = [];
        this.infDates = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Offer id.
     * @member {string} id
     * @memberof Offer
     * @instance
     */
    Offer.prototype.id = "";

    /**
     * Offer operator.
     * @member {string} operator
     * @memberof Offer
     * @instance
     */
    Offer.prototype.operator = "";

    /**
     * Offer adt.
     * @member {number} adt
     * @memberof Offer
     * @instance
     */
    Offer.prototype.adt = 0;

    /**
     * Offer chdDates.
     * @member {Array.<string>} chdDates
     * @memberof Offer
     * @instance
     */
    Offer.prototype.chdDates = $util.emptyArray;

    /**
     * Offer infDates.
     * @member {Array.<string>} infDates
     * @memberof Offer
     * @instance
     */
    Offer.prototype.infDates = $util.emptyArray;

    /**
     * Offer extraHotel.
     * @member {Offer.IExtraHotel|null|undefined} extraHotel
     * @memberof Offer
     * @instance
     */
    Offer.prototype.extraHotel = null;

    /**
     * Offer changeBook.
     * @member {Offer.IChangeBook|null|undefined} changeBook
     * @memberof Offer
     * @instance
     */
    Offer.prototype.changeBook = null;

    /**
     * Creates a new Offer instance using the specified properties.
     * @function create
     * @memberof Offer
     * @static
     * @param {IOffer=} [properties] Properties to set
     * @returns {Offer} Offer instance
     */
    Offer.create = function create(properties) {
        return new Offer(properties);
    };

    /**
     * Encodes the specified Offer message. Does not implicitly {@link Offer.verify|verify} messages.
     * @function encode
     * @memberof Offer
     * @static
     * @param {IOffer} message Offer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Offer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.operator);
        if (message.adt != null && Object.hasOwnProperty.call(message, "adt"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.adt);
        if (message.chdDates != null && message.chdDates.length)
            for (let i = 0; i < message.chdDates.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.chdDates[i]);
        if (message.infDates != null && message.infDates.length)
            for (let i = 0; i < message.infDates.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.infDates[i]);
        if (message.extraHotel != null && Object.hasOwnProperty.call(message, "extraHotel"))
            $root.Offer.ExtraHotel.encode(message.extraHotel, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.changeBook != null && Object.hasOwnProperty.call(message, "changeBook"))
            $root.Offer.ChangeBook.encode(message.changeBook, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Offer message, length delimited. Does not implicitly {@link Offer.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Offer
     * @static
     * @param {IOffer} message Offer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Offer.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Offer message from the specified reader or buffer.
     * @function decode
     * @memberof Offer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Offer} Offer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Offer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Offer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.operator = reader.string();
                    break;
                }
            case 3: {
                    message.adt = reader.int32();
                    break;
                }
            case 4: {
                    if (!(message.chdDates && message.chdDates.length))
                        message.chdDates = [];
                    message.chdDates.push(reader.string());
                    break;
                }
            case 5: {
                    if (!(message.infDates && message.infDates.length))
                        message.infDates = [];
                    message.infDates.push(reader.string());
                    break;
                }
            case 6: {
                    message.extraHotel = $root.Offer.ExtraHotel.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.changeBook = $root.Offer.ChangeBook.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Offer message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Offer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Offer} Offer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Offer.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Offer message.
     * @function verify
     * @memberof Offer
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Offer.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.operator != null && message.hasOwnProperty("operator"))
            if (!$util.isString(message.operator))
                return "operator: string expected";
        if (message.adt != null && message.hasOwnProperty("adt"))
            if (!$util.isInteger(message.adt))
                return "adt: integer expected";
        if (message.chdDates != null && message.hasOwnProperty("chdDates")) {
            if (!Array.isArray(message.chdDates))
                return "chdDates: array expected";
            for (let i = 0; i < message.chdDates.length; ++i)
                if (!$util.isString(message.chdDates[i]))
                    return "chdDates: string[] expected";
        }
        if (message.infDates != null && message.hasOwnProperty("infDates")) {
            if (!Array.isArray(message.infDates))
                return "infDates: array expected";
            for (let i = 0; i < message.infDates.length; ++i)
                if (!$util.isString(message.infDates[i]))
                    return "infDates: string[] expected";
        }
        if (message.extraHotel != null && message.hasOwnProperty("extraHotel")) {
            let error = $root.Offer.ExtraHotel.verify(message.extraHotel);
            if (error)
                return "extraHotel." + error;
        }
        if (message.changeBook != null && message.hasOwnProperty("changeBook")) {
            let error = $root.Offer.ChangeBook.verify(message.changeBook);
            if (error)
                return "changeBook." + error;
        }
        return null;
    };

    /**
     * Creates an Offer message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Offer
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Offer} Offer
     */
    Offer.fromObject = function fromObject(object) {
        if (object instanceof $root.Offer)
            return object;
        let message = new $root.Offer();
        if (object.id != null)
            message.id = String(object.id);
        if (object.operator != null)
            message.operator = String(object.operator);
        if (object.adt != null)
            message.adt = object.adt | 0;
        if (object.chdDates) {
            if (!Array.isArray(object.chdDates))
                throw TypeError(".Offer.chdDates: array expected");
            message.chdDates = [];
            for (let i = 0; i < object.chdDates.length; ++i)
                message.chdDates[i] = String(object.chdDates[i]);
        }
        if (object.infDates) {
            if (!Array.isArray(object.infDates))
                throw TypeError(".Offer.infDates: array expected");
            message.infDates = [];
            for (let i = 0; i < object.infDates.length; ++i)
                message.infDates[i] = String(object.infDates[i]);
        }
        if (object.extraHotel != null) {
            if (typeof object.extraHotel !== "object")
                throw TypeError(".Offer.extraHotel: object expected");
            message.extraHotel = $root.Offer.ExtraHotel.fromObject(object.extraHotel);
        }
        if (object.changeBook != null) {
            if (typeof object.changeBook !== "object")
                throw TypeError(".Offer.changeBook: object expected");
            message.changeBook = $root.Offer.ChangeBook.fromObject(object.changeBook);
        }
        return message;
    };

    /**
     * Creates a plain object from an Offer message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Offer
     * @static
     * @param {Offer} message Offer
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Offer.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.chdDates = [];
            object.infDates = [];
        }
        if (options.defaults) {
            object.id = "";
            object.operator = "";
            object.adt = 0;
            object.extraHotel = null;
            object.changeBook = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.operator != null && message.hasOwnProperty("operator"))
            object.operator = message.operator;
        if (message.adt != null && message.hasOwnProperty("adt"))
            object.adt = message.adt;
        if (message.chdDates && message.chdDates.length) {
            object.chdDates = [];
            for (let j = 0; j < message.chdDates.length; ++j)
                object.chdDates[j] = message.chdDates[j];
        }
        if (message.infDates && message.infDates.length) {
            object.infDates = [];
            for (let j = 0; j < message.infDates.length; ++j)
                object.infDates[j] = message.infDates[j];
        }
        if (message.extraHotel != null && message.hasOwnProperty("extraHotel"))
            object.extraHotel = $root.Offer.ExtraHotel.toObject(message.extraHotel, options);
        if (message.changeBook != null && message.hasOwnProperty("changeBook"))
            object.changeBook = $root.Offer.ChangeBook.toObject(message.changeBook, options);
        return object;
    };

    /**
     * Converts this Offer to JSON.
     * @function toJSON
     * @memberof Offer
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Offer.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Offer
     * @function getTypeUrl
     * @memberof Offer
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Offer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Offer";
    };

    Offer.ExtraHotel = (function() {

        /**
         * Properties of an ExtraHotel.
         * @memberof Offer
         * @interface IExtraHotel
         * @property {string|null} [code] ExtraHotel code
         * @property {string|null} [id] ExtraHotel id
         */

        /**
         * Constructs a new ExtraHotel.
         * @memberof Offer
         * @classdesc Represents an ExtraHotel.
         * @implements IExtraHotel
         * @constructor
         * @param {Offer.IExtraHotel=} [properties] Properties to set
         */
        function ExtraHotel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExtraHotel code.
         * @member {string} code
         * @memberof Offer.ExtraHotel
         * @instance
         */
        ExtraHotel.prototype.code = "";

        /**
         * ExtraHotel id.
         * @member {string} id
         * @memberof Offer.ExtraHotel
         * @instance
         */
        ExtraHotel.prototype.id = "";

        /**
         * Creates a new ExtraHotel instance using the specified properties.
         * @function create
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Offer.IExtraHotel=} [properties] Properties to set
         * @returns {Offer.ExtraHotel} ExtraHotel instance
         */
        ExtraHotel.create = function create(properties) {
            return new ExtraHotel(properties);
        };

        /**
         * Encodes the specified ExtraHotel message. Does not implicitly {@link Offer.ExtraHotel.verify|verify} messages.
         * @function encode
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Offer.IExtraHotel} message ExtraHotel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtraHotel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified ExtraHotel message, length delimited. Does not implicitly {@link Offer.ExtraHotel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Offer.IExtraHotel} message ExtraHotel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtraHotel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExtraHotel message from the specified reader or buffer.
         * @function decode
         * @memberof Offer.ExtraHotel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Offer.ExtraHotel} ExtraHotel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtraHotel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Offer.ExtraHotel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.string();
                        break;
                    }
                case 2: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExtraHotel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Offer.ExtraHotel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Offer.ExtraHotel} ExtraHotel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtraHotel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExtraHotel message.
         * @function verify
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtraHotel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates an ExtraHotel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Offer.ExtraHotel} ExtraHotel
         */
        ExtraHotel.fromObject = function fromObject(object) {
            if (object instanceof $root.Offer.ExtraHotel)
                return object;
            let message = new $root.Offer.ExtraHotel();
            if (object.code != null)
                message.code = String(object.code);
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from an ExtraHotel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Offer.ExtraHotel
         * @static
         * @param {Offer.ExtraHotel} message ExtraHotel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtraHotel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = "";
                object.id = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this ExtraHotel to JSON.
         * @function toJSON
         * @memberof Offer.ExtraHotel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtraHotel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExtraHotel
         * @function getTypeUrl
         * @memberof Offer.ExtraHotel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExtraHotel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Offer.ExtraHotel";
        };

        return ExtraHotel;
    })();

    Offer.ChangeBook = (function() {

        /**
         * Properties of a ChangeBook.
         * @memberof Offer
         * @interface IChangeBook
         * @property {string|null} [bnr] ChangeBook bnr
         * @property {string|null} [bdate] ChangeBook bdate
         */

        /**
         * Constructs a new ChangeBook.
         * @memberof Offer
         * @classdesc Represents a ChangeBook.
         * @implements IChangeBook
         * @constructor
         * @param {Offer.IChangeBook=} [properties] Properties to set
         */
        function ChangeBook(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeBook bnr.
         * @member {string} bnr
         * @memberof Offer.ChangeBook
         * @instance
         */
        ChangeBook.prototype.bnr = "";

        /**
         * ChangeBook bdate.
         * @member {string} bdate
         * @memberof Offer.ChangeBook
         * @instance
         */
        ChangeBook.prototype.bdate = "";

        /**
         * Creates a new ChangeBook instance using the specified properties.
         * @function create
         * @memberof Offer.ChangeBook
         * @static
         * @param {Offer.IChangeBook=} [properties] Properties to set
         * @returns {Offer.ChangeBook} ChangeBook instance
         */
        ChangeBook.create = function create(properties) {
            return new ChangeBook(properties);
        };

        /**
         * Encodes the specified ChangeBook message. Does not implicitly {@link Offer.ChangeBook.verify|verify} messages.
         * @function encode
         * @memberof Offer.ChangeBook
         * @static
         * @param {Offer.IChangeBook} message ChangeBook message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeBook.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bnr != null && Object.hasOwnProperty.call(message, "bnr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.bnr);
            if (message.bdate != null && Object.hasOwnProperty.call(message, "bdate"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.bdate);
            return writer;
        };

        /**
         * Encodes the specified ChangeBook message, length delimited. Does not implicitly {@link Offer.ChangeBook.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Offer.ChangeBook
         * @static
         * @param {Offer.IChangeBook} message ChangeBook message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeBook.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeBook message from the specified reader or buffer.
         * @function decode
         * @memberof Offer.ChangeBook
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Offer.ChangeBook} ChangeBook
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeBook.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Offer.ChangeBook();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bnr = reader.string();
                        break;
                    }
                case 2: {
                        message.bdate = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeBook message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Offer.ChangeBook
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Offer.ChangeBook} ChangeBook
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeBook.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeBook message.
         * @function verify
         * @memberof Offer.ChangeBook
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeBook.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bnr != null && message.hasOwnProperty("bnr"))
                if (!$util.isString(message.bnr))
                    return "bnr: string expected";
            if (message.bdate != null && message.hasOwnProperty("bdate"))
                if (!$util.isString(message.bdate))
                    return "bdate: string expected";
            return null;
        };

        /**
         * Creates a ChangeBook message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Offer.ChangeBook
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Offer.ChangeBook} ChangeBook
         */
        ChangeBook.fromObject = function fromObject(object) {
            if (object instanceof $root.Offer.ChangeBook)
                return object;
            let message = new $root.Offer.ChangeBook();
            if (object.bnr != null)
                message.bnr = String(object.bnr);
            if (object.bdate != null)
                message.bdate = String(object.bdate);
            return message;
        };

        /**
         * Creates a plain object from a ChangeBook message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Offer.ChangeBook
         * @static
         * @param {Offer.ChangeBook} message ChangeBook
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeBook.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bnr = "";
                object.bdate = "";
            }
            if (message.bnr != null && message.hasOwnProperty("bnr"))
                object.bnr = message.bnr;
            if (message.bdate != null && message.hasOwnProperty("bdate"))
                object.bdate = message.bdate;
            return object;
        };

        /**
         * Converts this ChangeBook to JSON.
         * @function toJSON
         * @memberof Offer.ChangeBook
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeBook.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeBook
         * @function getTypeUrl
         * @memberof Offer.ChangeBook
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeBook.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Offer.ChangeBook";
        };

        return ChangeBook;
    })();

    return Offer;
})();

export const Basket = $root.Basket = (() => {

    /**
     * Properties of a Basket.
     * @exports IBasket
     * @interface IBasket
     * @property {number|null} [bid] Basket bid
     * @property {Array.<string>|null} [checked] Basket checked
     */

    /**
     * Constructs a new Basket.
     * @exports Basket
     * @classdesc Represents a Basket.
     * @implements IBasket
     * @constructor
     * @param {IBasket=} [properties] Properties to set
     */
    function Basket(properties) {
        this.checked = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Basket bid.
     * @member {number} bid
     * @memberof Basket
     * @instance
     */
    Basket.prototype.bid = 0;

    /**
     * Basket checked.
     * @member {Array.<string>} checked
     * @memberof Basket
     * @instance
     */
    Basket.prototype.checked = $util.emptyArray;

    /**
     * Creates a new Basket instance using the specified properties.
     * @function create
     * @memberof Basket
     * @static
     * @param {IBasket=} [properties] Properties to set
     * @returns {Basket} Basket instance
     */
    Basket.create = function create(properties) {
        return new Basket(properties);
    };

    /**
     * Encodes the specified Basket message. Does not implicitly {@link Basket.verify|verify} messages.
     * @function encode
     * @memberof Basket
     * @static
     * @param {IBasket} message Basket message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Basket.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.bid != null && Object.hasOwnProperty.call(message, "bid"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bid);
        if (message.checked != null && message.checked.length)
            for (let i = 0; i < message.checked.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.checked[i]);
        return writer;
    };

    /**
     * Encodes the specified Basket message, length delimited. Does not implicitly {@link Basket.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Basket
     * @static
     * @param {IBasket} message Basket message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Basket.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Basket message from the specified reader or buffer.
     * @function decode
     * @memberof Basket
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Basket} Basket
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Basket.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Basket();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.bid = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.checked && message.checked.length))
                        message.checked = [];
                    message.checked.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Basket message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Basket
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Basket} Basket
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Basket.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Basket message.
     * @function verify
     * @memberof Basket
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Basket.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.bid != null && message.hasOwnProperty("bid"))
            if (!$util.isInteger(message.bid))
                return "bid: integer expected";
        if (message.checked != null && message.hasOwnProperty("checked")) {
            if (!Array.isArray(message.checked))
                return "checked: array expected";
            for (let i = 0; i < message.checked.length; ++i)
                if (!$util.isString(message.checked[i]))
                    return "checked: string[] expected";
        }
        return null;
    };

    /**
     * Creates a Basket message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Basket
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Basket} Basket
     */
    Basket.fromObject = function fromObject(object) {
        if (object instanceof $root.Basket)
            return object;
        let message = new $root.Basket();
        if (object.bid != null)
            message.bid = object.bid | 0;
        if (object.checked) {
            if (!Array.isArray(object.checked))
                throw TypeError(".Basket.checked: array expected");
            message.checked = [];
            for (let i = 0; i < object.checked.length; ++i)
                message.checked[i] = String(object.checked[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a Basket message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Basket
     * @static
     * @param {Basket} message Basket
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Basket.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.checked = [];
        if (options.defaults)
            object.bid = 0;
        if (message.bid != null && message.hasOwnProperty("bid"))
            object.bid = message.bid;
        if (message.checked && message.checked.length) {
            object.checked = [];
            for (let j = 0; j < message.checked.length; ++j)
                object.checked[j] = message.checked[j];
        }
        return object;
    };

    /**
     * Converts this Basket to JSON.
     * @function toJSON
     * @memberof Basket
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Basket.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Basket
     * @function getTypeUrl
     * @memberof Basket
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Basket.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Basket";
    };

    return Basket;
})();

export const PrintOffers = $root.PrintOffers = (() => {

    /**
     * Properties of a PrintOffers.
     * @exports IPrintOffers
     * @interface IPrintOffers
     * @property {string|null} [template] PrintOffers template
     * @property {string|null} [font] PrintOffers font
     * @property {string|null} [background] PrintOffers background
     * @property {string|null} [priceType] PrintOffers priceType
     * @property {boolean|null} [roundedPrice] PrintOffers roundedPrice
     * @property {boolean|null} [priceDetails] PrintOffers priceDetails
     * @property {boolean|null} [previousPrice] PrintOffers previousPrice
     * @property {boolean|null} [omnibusPrice] PrintOffers omnibusPrice
     * @property {boolean|null} [description] PrintOffers description
     * @property {boolean|null} [pictures] PrintOffers pictures
     * @property {boolean|null} [operator] PrintOffers operator
     * @property {boolean|null} [luggage] PrintOffers luggage
     * @property {boolean|null} [airline] PrintOffers airline
     * @property {boolean|null} [airportCode] PrintOffers airportCode
     * @property {boolean|null} [roomCode] PrintOffers roomCode
     * @property {boolean|null} [candies] PrintOffers candies
     * @property {boolean|null} [tripAdvisor] PrintOffers tripAdvisor
     * @property {Array.<string>|null} [offerAttributes] PrintOffers offerAttributes
     * @property {boolean|null} [facilities] PrintOffers facilities
     * @property {boolean|null} [map] PrintOffers map
     * @property {boolean|null} [logo] PrintOffers logo
     * @property {boolean|null} [legalNotice] PrintOffers legalNotice
     * @property {string|null} [sloganText] PrintOffers sloganText
     * @property {boolean|null} [qrCode] PrintOffers qrCode
     * @property {string|null} [qrCodeText] PrintOffers qrCodeText
     * @property {boolean|null} [bookingConditions] PrintOffers bookingConditions
     * @property {boolean|null} [beachDistance] PrintOffers beachDistance
     * @property {boolean|null} [showStamp] PrintOffers showStamp
     * @property {string|null} [stampColor] PrintOffers stampColor
     * @property {string|null} [stamp] PrintOffers stamp
     * @property {boolean|null} [showAgencyData] PrintOffers showAgencyData
     * @property {PrintOffers.IMapDetails|null} [mapDetails] PrintOffers mapDetails
     * @property {boolean|null} [offerUrl] PrintOffers offerUrl
     * @property {PrintOffers.IOfferUrlDetails|null} [offerUrlDetails] PrintOffers offerUrlDetails
     * @property {boolean|null} [renovationYear] PrintOffers renovationYear
     * @property {boolean|null} [showAuthor] PrintOffers showAuthor
     * @property {boolean|null} [showAgentPhoto] PrintOffers showAgentPhoto
     * @property {string|null} [fontColor] PrintOffers fontColor
     * @property {string|null} [borderColor] PrintOffers borderColor
     * @property {string|null} [footerFontColor] PrintOffers footerFontColor
     * @property {string|null} [footerBackgroundColor] PrintOffers footerBackgroundColor
     * @property {boolean|null} [childBirthdate] PrintOffers childBirthdate
     * @property {boolean|null} [slogan] PrintOffers slogan
     * @property {string|null} [dateType] PrintOffers dateType
     */

    /**
     * Constructs a new PrintOffers.
     * @exports PrintOffers
     * @classdesc Represents a PrintOffers.
     * @implements IPrintOffers
     * @constructor
     * @param {IPrintOffers=} [properties] Properties to set
     */
    function PrintOffers(properties) {
        this.offerAttributes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PrintOffers template.
     * @member {string} template
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.template = "";

    /**
     * PrintOffers font.
     * @member {string} font
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.font = "";

    /**
     * PrintOffers background.
     * @member {string} background
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.background = "";

    /**
     * PrintOffers priceType.
     * @member {string} priceType
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.priceType = "";

    /**
     * PrintOffers roundedPrice.
     * @member {boolean} roundedPrice
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.roundedPrice = false;

    /**
     * PrintOffers priceDetails.
     * @member {boolean} priceDetails
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.priceDetails = false;

    /**
     * PrintOffers previousPrice.
     * @member {boolean} previousPrice
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.previousPrice = false;

    /**
     * PrintOffers omnibusPrice.
     * @member {boolean} omnibusPrice
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.omnibusPrice = false;

    /**
     * PrintOffers description.
     * @member {boolean} description
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.description = false;

    /**
     * PrintOffers pictures.
     * @member {boolean} pictures
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.pictures = false;

    /**
     * PrintOffers operator.
     * @member {boolean} operator
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.operator = false;

    /**
     * PrintOffers luggage.
     * @member {boolean} luggage
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.luggage = false;

    /**
     * PrintOffers airline.
     * @member {boolean} airline
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.airline = false;

    /**
     * PrintOffers airportCode.
     * @member {boolean} airportCode
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.airportCode = false;

    /**
     * PrintOffers roomCode.
     * @member {boolean} roomCode
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.roomCode = false;

    /**
     * PrintOffers candies.
     * @member {boolean} candies
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.candies = false;

    /**
     * PrintOffers tripAdvisor.
     * @member {boolean} tripAdvisor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.tripAdvisor = false;

    /**
     * PrintOffers offerAttributes.
     * @member {Array.<string>} offerAttributes
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.offerAttributes = $util.emptyArray;

    /**
     * PrintOffers facilities.
     * @member {boolean} facilities
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.facilities = false;

    /**
     * PrintOffers map.
     * @member {boolean} map
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.map = false;

    /**
     * PrintOffers logo.
     * @member {boolean} logo
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.logo = false;

    /**
     * PrintOffers legalNotice.
     * @member {boolean} legalNotice
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.legalNotice = false;

    /**
     * PrintOffers sloganText.
     * @member {string} sloganText
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.sloganText = "";

    /**
     * PrintOffers qrCode.
     * @member {boolean} qrCode
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.qrCode = false;

    /**
     * PrintOffers qrCodeText.
     * @member {string} qrCodeText
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.qrCodeText = "";

    /**
     * PrintOffers bookingConditions.
     * @member {boolean} bookingConditions
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.bookingConditions = false;

    /**
     * PrintOffers beachDistance.
     * @member {boolean} beachDistance
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.beachDistance = false;

    /**
     * PrintOffers showStamp.
     * @member {boolean} showStamp
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.showStamp = false;

    /**
     * PrintOffers stampColor.
     * @member {string} stampColor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.stampColor = "";

    /**
     * PrintOffers stamp.
     * @member {string} stamp
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.stamp = "";

    /**
     * PrintOffers showAgencyData.
     * @member {boolean} showAgencyData
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.showAgencyData = false;

    /**
     * PrintOffers mapDetails.
     * @member {PrintOffers.IMapDetails|null|undefined} mapDetails
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.mapDetails = null;

    /**
     * PrintOffers offerUrl.
     * @member {boolean} offerUrl
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.offerUrl = false;

    /**
     * PrintOffers offerUrlDetails.
     * @member {PrintOffers.IOfferUrlDetails|null|undefined} offerUrlDetails
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.offerUrlDetails = null;

    /**
     * PrintOffers renovationYear.
     * @member {boolean} renovationYear
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.renovationYear = false;

    /**
     * PrintOffers showAuthor.
     * @member {boolean} showAuthor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.showAuthor = false;

    /**
     * PrintOffers showAgentPhoto.
     * @member {boolean} showAgentPhoto
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.showAgentPhoto = false;

    /**
     * PrintOffers fontColor.
     * @member {string} fontColor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.fontColor = "";

    /**
     * PrintOffers borderColor.
     * @member {string} borderColor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.borderColor = "";

    /**
     * PrintOffers footerFontColor.
     * @member {string} footerFontColor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.footerFontColor = "";

    /**
     * PrintOffers footerBackgroundColor.
     * @member {string} footerBackgroundColor
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.footerBackgroundColor = "";

    /**
     * PrintOffers childBirthdate.
     * @member {boolean} childBirthdate
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.childBirthdate = false;

    /**
     * PrintOffers slogan.
     * @member {boolean} slogan
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.slogan = false;

    /**
     * PrintOffers dateType.
     * @member {string} dateType
     * @memberof PrintOffers
     * @instance
     */
    PrintOffers.prototype.dateType = "";

    /**
     * Creates a new PrintOffers instance using the specified properties.
     * @function create
     * @memberof PrintOffers
     * @static
     * @param {IPrintOffers=} [properties] Properties to set
     * @returns {PrintOffers} PrintOffers instance
     */
    PrintOffers.create = function create(properties) {
        return new PrintOffers(properties);
    };

    /**
     * Encodes the specified PrintOffers message. Does not implicitly {@link PrintOffers.verify|verify} messages.
     * @function encode
     * @memberof PrintOffers
     * @static
     * @param {IPrintOffers} message PrintOffers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PrintOffers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.template != null && Object.hasOwnProperty.call(message, "template"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.template);
        if (message.font != null && Object.hasOwnProperty.call(message, "font"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.font);
        if (message.background != null && Object.hasOwnProperty.call(message, "background"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.background);
        if (message.priceType != null && Object.hasOwnProperty.call(message, "priceType"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.priceType);
        if (message.roundedPrice != null && Object.hasOwnProperty.call(message, "roundedPrice"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.roundedPrice);
        if (message.priceDetails != null && Object.hasOwnProperty.call(message, "priceDetails"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.priceDetails);
        if (message.previousPrice != null && Object.hasOwnProperty.call(message, "previousPrice"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.previousPrice);
        if (message.omnibusPrice != null && Object.hasOwnProperty.call(message, "omnibusPrice"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.omnibusPrice);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.description);
        if (message.pictures != null && Object.hasOwnProperty.call(message, "pictures"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.pictures);
        if (message.operator != null && Object.hasOwnProperty.call(message, "operator"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.operator);
        if (message.luggage != null && Object.hasOwnProperty.call(message, "luggage"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.luggage);
        if (message.airline != null && Object.hasOwnProperty.call(message, "airline"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.airline);
        if (message.airportCode != null && Object.hasOwnProperty.call(message, "airportCode"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.airportCode);
        if (message.roomCode != null && Object.hasOwnProperty.call(message, "roomCode"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.roomCode);
        if (message.candies != null && Object.hasOwnProperty.call(message, "candies"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.candies);
        if (message.tripAdvisor != null && Object.hasOwnProperty.call(message, "tripAdvisor"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.tripAdvisor);
        if (message.offerAttributes != null && message.offerAttributes.length)
            for (let i = 0; i < message.offerAttributes.length; ++i)
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.offerAttributes[i]);
        if (message.facilities != null && Object.hasOwnProperty.call(message, "facilities"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.facilities);
        if (message.map != null && Object.hasOwnProperty.call(message, "map"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.map);
        if (message.logo != null && Object.hasOwnProperty.call(message, "logo"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.logo);
        if (message.legalNotice != null && Object.hasOwnProperty.call(message, "legalNotice"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.legalNotice);
        if (message.sloganText != null && Object.hasOwnProperty.call(message, "sloganText"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.sloganText);
        if (message.qrCode != null && Object.hasOwnProperty.call(message, "qrCode"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.qrCode);
        if (message.qrCodeText != null && Object.hasOwnProperty.call(message, "qrCodeText"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.qrCodeText);
        if (message.bookingConditions != null && Object.hasOwnProperty.call(message, "bookingConditions"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.bookingConditions);
        if (message.beachDistance != null && Object.hasOwnProperty.call(message, "beachDistance"))
            writer.uint32(/* id 27, wireType 0 =*/216).bool(message.beachDistance);
        if (message.showStamp != null && Object.hasOwnProperty.call(message, "showStamp"))
            writer.uint32(/* id 28, wireType 0 =*/224).bool(message.showStamp);
        if (message.stampColor != null && Object.hasOwnProperty.call(message, "stampColor"))
            writer.uint32(/* id 29, wireType 2 =*/234).string(message.stampColor);
        if (message.stamp != null && Object.hasOwnProperty.call(message, "stamp"))
            writer.uint32(/* id 30, wireType 2 =*/242).string(message.stamp);
        if (message.showAgencyData != null && Object.hasOwnProperty.call(message, "showAgencyData"))
            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.showAgencyData);
        if (message.mapDetails != null && Object.hasOwnProperty.call(message, "mapDetails"))
            $root.PrintOffers.MapDetails.encode(message.mapDetails, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.offerUrl != null && Object.hasOwnProperty.call(message, "offerUrl"))
            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.offerUrl);
        if (message.offerUrlDetails != null && Object.hasOwnProperty.call(message, "offerUrlDetails"))
            $root.PrintOffers.OfferUrlDetails.encode(message.offerUrlDetails, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
        if (message.renovationYear != null && Object.hasOwnProperty.call(message, "renovationYear"))
            writer.uint32(/* id 35, wireType 0 =*/280).bool(message.renovationYear);
        if (message.showAuthor != null && Object.hasOwnProperty.call(message, "showAuthor"))
            writer.uint32(/* id 36, wireType 0 =*/288).bool(message.showAuthor);
        if (message.showAgentPhoto != null && Object.hasOwnProperty.call(message, "showAgentPhoto"))
            writer.uint32(/* id 37, wireType 0 =*/296).bool(message.showAgentPhoto);
        if (message.fontColor != null && Object.hasOwnProperty.call(message, "fontColor"))
            writer.uint32(/* id 38, wireType 2 =*/306).string(message.fontColor);
        if (message.borderColor != null && Object.hasOwnProperty.call(message, "borderColor"))
            writer.uint32(/* id 39, wireType 2 =*/314).string(message.borderColor);
        if (message.footerFontColor != null && Object.hasOwnProperty.call(message, "footerFontColor"))
            writer.uint32(/* id 40, wireType 2 =*/322).string(message.footerFontColor);
        if (message.footerBackgroundColor != null && Object.hasOwnProperty.call(message, "footerBackgroundColor"))
            writer.uint32(/* id 41, wireType 2 =*/330).string(message.footerBackgroundColor);
        if (message.childBirthdate != null && Object.hasOwnProperty.call(message, "childBirthdate"))
            writer.uint32(/* id 42, wireType 0 =*/336).bool(message.childBirthdate);
        if (message.slogan != null && Object.hasOwnProperty.call(message, "slogan"))
            writer.uint32(/* id 43, wireType 0 =*/344).bool(message.slogan);
        if (message.dateType != null && Object.hasOwnProperty.call(message, "dateType"))
            writer.uint32(/* id 44, wireType 2 =*/354).string(message.dateType);
        return writer;
    };

    /**
     * Encodes the specified PrintOffers message, length delimited. Does not implicitly {@link PrintOffers.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PrintOffers
     * @static
     * @param {IPrintOffers} message PrintOffers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PrintOffers.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PrintOffers message from the specified reader or buffer.
     * @function decode
     * @memberof PrintOffers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PrintOffers} PrintOffers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PrintOffers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PrintOffers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.template = reader.string();
                    break;
                }
            case 2: {
                    message.font = reader.string();
                    break;
                }
            case 3: {
                    message.background = reader.string();
                    break;
                }
            case 4: {
                    message.priceType = reader.string();
                    break;
                }
            case 5: {
                    message.roundedPrice = reader.bool();
                    break;
                }
            case 6: {
                    message.priceDetails = reader.bool();
                    break;
                }
            case 7: {
                    message.previousPrice = reader.bool();
                    break;
                }
            case 8: {
                    message.omnibusPrice = reader.bool();
                    break;
                }
            case 9: {
                    message.description = reader.bool();
                    break;
                }
            case 10: {
                    message.pictures = reader.bool();
                    break;
                }
            case 11: {
                    message.operator = reader.bool();
                    break;
                }
            case 12: {
                    message.luggage = reader.bool();
                    break;
                }
            case 13: {
                    message.airline = reader.bool();
                    break;
                }
            case 14: {
                    message.airportCode = reader.bool();
                    break;
                }
            case 15: {
                    message.roomCode = reader.bool();
                    break;
                }
            case 16: {
                    message.candies = reader.bool();
                    break;
                }
            case 17: {
                    message.tripAdvisor = reader.bool();
                    break;
                }
            case 18: {
                    if (!(message.offerAttributes && message.offerAttributes.length))
                        message.offerAttributes = [];
                    message.offerAttributes.push(reader.string());
                    break;
                }
            case 19: {
                    message.facilities = reader.bool();
                    break;
                }
            case 20: {
                    message.map = reader.bool();
                    break;
                }
            case 21: {
                    message.logo = reader.bool();
                    break;
                }
            case 22: {
                    message.legalNotice = reader.bool();
                    break;
                }
            case 23: {
                    message.sloganText = reader.string();
                    break;
                }
            case 24: {
                    message.qrCode = reader.bool();
                    break;
                }
            case 25: {
                    message.qrCodeText = reader.string();
                    break;
                }
            case 26: {
                    message.bookingConditions = reader.bool();
                    break;
                }
            case 27: {
                    message.beachDistance = reader.bool();
                    break;
                }
            case 28: {
                    message.showStamp = reader.bool();
                    break;
                }
            case 29: {
                    message.stampColor = reader.string();
                    break;
                }
            case 30: {
                    message.stamp = reader.string();
                    break;
                }
            case 31: {
                    message.showAgencyData = reader.bool();
                    break;
                }
            case 32: {
                    message.mapDetails = $root.PrintOffers.MapDetails.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.offerUrl = reader.bool();
                    break;
                }
            case 34: {
                    message.offerUrlDetails = $root.PrintOffers.OfferUrlDetails.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    message.renovationYear = reader.bool();
                    break;
                }
            case 36: {
                    message.showAuthor = reader.bool();
                    break;
                }
            case 37: {
                    message.showAgentPhoto = reader.bool();
                    break;
                }
            case 38: {
                    message.fontColor = reader.string();
                    break;
                }
            case 39: {
                    message.borderColor = reader.string();
                    break;
                }
            case 40: {
                    message.footerFontColor = reader.string();
                    break;
                }
            case 41: {
                    message.footerBackgroundColor = reader.string();
                    break;
                }
            case 42: {
                    message.childBirthdate = reader.bool();
                    break;
                }
            case 43: {
                    message.slogan = reader.bool();
                    break;
                }
            case 44: {
                    message.dateType = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PrintOffers message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PrintOffers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PrintOffers} PrintOffers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PrintOffers.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PrintOffers message.
     * @function verify
     * @memberof PrintOffers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PrintOffers.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.template != null && message.hasOwnProperty("template"))
            if (!$util.isString(message.template))
                return "template: string expected";
        if (message.font != null && message.hasOwnProperty("font"))
            if (!$util.isString(message.font))
                return "font: string expected";
        if (message.background != null && message.hasOwnProperty("background"))
            if (!$util.isString(message.background))
                return "background: string expected";
        if (message.priceType != null && message.hasOwnProperty("priceType"))
            if (!$util.isString(message.priceType))
                return "priceType: string expected";
        if (message.roundedPrice != null && message.hasOwnProperty("roundedPrice"))
            if (typeof message.roundedPrice !== "boolean")
                return "roundedPrice: boolean expected";
        if (message.priceDetails != null && message.hasOwnProperty("priceDetails"))
            if (typeof message.priceDetails !== "boolean")
                return "priceDetails: boolean expected";
        if (message.previousPrice != null && message.hasOwnProperty("previousPrice"))
            if (typeof message.previousPrice !== "boolean")
                return "previousPrice: boolean expected";
        if (message.omnibusPrice != null && message.hasOwnProperty("omnibusPrice"))
            if (typeof message.omnibusPrice !== "boolean")
                return "omnibusPrice: boolean expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (typeof message.description !== "boolean")
                return "description: boolean expected";
        if (message.pictures != null && message.hasOwnProperty("pictures"))
            if (typeof message.pictures !== "boolean")
                return "pictures: boolean expected";
        if (message.operator != null && message.hasOwnProperty("operator"))
            if (typeof message.operator !== "boolean")
                return "operator: boolean expected";
        if (message.luggage != null && message.hasOwnProperty("luggage"))
            if (typeof message.luggage !== "boolean")
                return "luggage: boolean expected";
        if (message.airline != null && message.hasOwnProperty("airline"))
            if (typeof message.airline !== "boolean")
                return "airline: boolean expected";
        if (message.airportCode != null && message.hasOwnProperty("airportCode"))
            if (typeof message.airportCode !== "boolean")
                return "airportCode: boolean expected";
        if (message.roomCode != null && message.hasOwnProperty("roomCode"))
            if (typeof message.roomCode !== "boolean")
                return "roomCode: boolean expected";
        if (message.candies != null && message.hasOwnProperty("candies"))
            if (typeof message.candies !== "boolean")
                return "candies: boolean expected";
        if (message.tripAdvisor != null && message.hasOwnProperty("tripAdvisor"))
            if (typeof message.tripAdvisor !== "boolean")
                return "tripAdvisor: boolean expected";
        if (message.offerAttributes != null && message.hasOwnProperty("offerAttributes")) {
            if (!Array.isArray(message.offerAttributes))
                return "offerAttributes: array expected";
            for (let i = 0; i < message.offerAttributes.length; ++i)
                if (!$util.isString(message.offerAttributes[i]))
                    return "offerAttributes: string[] expected";
        }
        if (message.facilities != null && message.hasOwnProperty("facilities"))
            if (typeof message.facilities !== "boolean")
                return "facilities: boolean expected";
        if (message.map != null && message.hasOwnProperty("map"))
            if (typeof message.map !== "boolean")
                return "map: boolean expected";
        if (message.logo != null && message.hasOwnProperty("logo"))
            if (typeof message.logo !== "boolean")
                return "logo: boolean expected";
        if (message.legalNotice != null && message.hasOwnProperty("legalNotice"))
            if (typeof message.legalNotice !== "boolean")
                return "legalNotice: boolean expected";
        if (message.sloganText != null && message.hasOwnProperty("sloganText"))
            if (!$util.isString(message.sloganText))
                return "sloganText: string expected";
        if (message.qrCode != null && message.hasOwnProperty("qrCode"))
            if (typeof message.qrCode !== "boolean")
                return "qrCode: boolean expected";
        if (message.qrCodeText != null && message.hasOwnProperty("qrCodeText"))
            if (!$util.isString(message.qrCodeText))
                return "qrCodeText: string expected";
        if (message.bookingConditions != null && message.hasOwnProperty("bookingConditions"))
            if (typeof message.bookingConditions !== "boolean")
                return "bookingConditions: boolean expected";
        if (message.beachDistance != null && message.hasOwnProperty("beachDistance"))
            if (typeof message.beachDistance !== "boolean")
                return "beachDistance: boolean expected";
        if (message.showStamp != null && message.hasOwnProperty("showStamp"))
            if (typeof message.showStamp !== "boolean")
                return "showStamp: boolean expected";
        if (message.stampColor != null && message.hasOwnProperty("stampColor"))
            if (!$util.isString(message.stampColor))
                return "stampColor: string expected";
        if (message.stamp != null && message.hasOwnProperty("stamp"))
            if (!$util.isString(message.stamp))
                return "stamp: string expected";
        if (message.showAgencyData != null && message.hasOwnProperty("showAgencyData"))
            if (typeof message.showAgencyData !== "boolean")
                return "showAgencyData: boolean expected";
        if (message.mapDetails != null && message.hasOwnProperty("mapDetails")) {
            let error = $root.PrintOffers.MapDetails.verify(message.mapDetails);
            if (error)
                return "mapDetails." + error;
        }
        if (message.offerUrl != null && message.hasOwnProperty("offerUrl"))
            if (typeof message.offerUrl !== "boolean")
                return "offerUrl: boolean expected";
        if (message.offerUrlDetails != null && message.hasOwnProperty("offerUrlDetails")) {
            let error = $root.PrintOffers.OfferUrlDetails.verify(message.offerUrlDetails);
            if (error)
                return "offerUrlDetails." + error;
        }
        if (message.renovationYear != null && message.hasOwnProperty("renovationYear"))
            if (typeof message.renovationYear !== "boolean")
                return "renovationYear: boolean expected";
        if (message.showAuthor != null && message.hasOwnProperty("showAuthor"))
            if (typeof message.showAuthor !== "boolean")
                return "showAuthor: boolean expected";
        if (message.showAgentPhoto != null && message.hasOwnProperty("showAgentPhoto"))
            if (typeof message.showAgentPhoto !== "boolean")
                return "showAgentPhoto: boolean expected";
        if (message.fontColor != null && message.hasOwnProperty("fontColor"))
            if (!$util.isString(message.fontColor))
                return "fontColor: string expected";
        if (message.borderColor != null && message.hasOwnProperty("borderColor"))
            if (!$util.isString(message.borderColor))
                return "borderColor: string expected";
        if (message.footerFontColor != null && message.hasOwnProperty("footerFontColor"))
            if (!$util.isString(message.footerFontColor))
                return "footerFontColor: string expected";
        if (message.footerBackgroundColor != null && message.hasOwnProperty("footerBackgroundColor"))
            if (!$util.isString(message.footerBackgroundColor))
                return "footerBackgroundColor: string expected";
        if (message.childBirthdate != null && message.hasOwnProperty("childBirthdate"))
            if (typeof message.childBirthdate !== "boolean")
                return "childBirthdate: boolean expected";
        if (message.slogan != null && message.hasOwnProperty("slogan"))
            if (typeof message.slogan !== "boolean")
                return "slogan: boolean expected";
        if (message.dateType != null && message.hasOwnProperty("dateType"))
            if (!$util.isString(message.dateType))
                return "dateType: string expected";
        return null;
    };

    /**
     * Creates a PrintOffers message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PrintOffers
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PrintOffers} PrintOffers
     */
    PrintOffers.fromObject = function fromObject(object) {
        if (object instanceof $root.PrintOffers)
            return object;
        let message = new $root.PrintOffers();
        if (object.template != null)
            message.template = String(object.template);
        if (object.font != null)
            message.font = String(object.font);
        if (object.background != null)
            message.background = String(object.background);
        if (object.priceType != null)
            message.priceType = String(object.priceType);
        if (object.roundedPrice != null)
            message.roundedPrice = Boolean(object.roundedPrice);
        if (object.priceDetails != null)
            message.priceDetails = Boolean(object.priceDetails);
        if (object.previousPrice != null)
            message.previousPrice = Boolean(object.previousPrice);
        if (object.omnibusPrice != null)
            message.omnibusPrice = Boolean(object.omnibusPrice);
        if (object.description != null)
            message.description = Boolean(object.description);
        if (object.pictures != null)
            message.pictures = Boolean(object.pictures);
        if (object.operator != null)
            message.operator = Boolean(object.operator);
        if (object.luggage != null)
            message.luggage = Boolean(object.luggage);
        if (object.airline != null)
            message.airline = Boolean(object.airline);
        if (object.airportCode != null)
            message.airportCode = Boolean(object.airportCode);
        if (object.roomCode != null)
            message.roomCode = Boolean(object.roomCode);
        if (object.candies != null)
            message.candies = Boolean(object.candies);
        if (object.tripAdvisor != null)
            message.tripAdvisor = Boolean(object.tripAdvisor);
        if (object.offerAttributes) {
            if (!Array.isArray(object.offerAttributes))
                throw TypeError(".PrintOffers.offerAttributes: array expected");
            message.offerAttributes = [];
            for (let i = 0; i < object.offerAttributes.length; ++i)
                message.offerAttributes[i] = String(object.offerAttributes[i]);
        }
        if (object.facilities != null)
            message.facilities = Boolean(object.facilities);
        if (object.map != null)
            message.map = Boolean(object.map);
        if (object.logo != null)
            message.logo = Boolean(object.logo);
        if (object.legalNotice != null)
            message.legalNotice = Boolean(object.legalNotice);
        if (object.sloganText != null)
            message.sloganText = String(object.sloganText);
        if (object.qrCode != null)
            message.qrCode = Boolean(object.qrCode);
        if (object.qrCodeText != null)
            message.qrCodeText = String(object.qrCodeText);
        if (object.bookingConditions != null)
            message.bookingConditions = Boolean(object.bookingConditions);
        if (object.beachDistance != null)
            message.beachDistance = Boolean(object.beachDistance);
        if (object.showStamp != null)
            message.showStamp = Boolean(object.showStamp);
        if (object.stampColor != null)
            message.stampColor = String(object.stampColor);
        if (object.stamp != null)
            message.stamp = String(object.stamp);
        if (object.showAgencyData != null)
            message.showAgencyData = Boolean(object.showAgencyData);
        if (object.mapDetails != null) {
            if (typeof object.mapDetails !== "object")
                throw TypeError(".PrintOffers.mapDetails: object expected");
            message.mapDetails = $root.PrintOffers.MapDetails.fromObject(object.mapDetails);
        }
        if (object.offerUrl != null)
            message.offerUrl = Boolean(object.offerUrl);
        if (object.offerUrlDetails != null) {
            if (typeof object.offerUrlDetails !== "object")
                throw TypeError(".PrintOffers.offerUrlDetails: object expected");
            message.offerUrlDetails = $root.PrintOffers.OfferUrlDetails.fromObject(object.offerUrlDetails);
        }
        if (object.renovationYear != null)
            message.renovationYear = Boolean(object.renovationYear);
        if (object.showAuthor != null)
            message.showAuthor = Boolean(object.showAuthor);
        if (object.showAgentPhoto != null)
            message.showAgentPhoto = Boolean(object.showAgentPhoto);
        if (object.fontColor != null)
            message.fontColor = String(object.fontColor);
        if (object.borderColor != null)
            message.borderColor = String(object.borderColor);
        if (object.footerFontColor != null)
            message.footerFontColor = String(object.footerFontColor);
        if (object.footerBackgroundColor != null)
            message.footerBackgroundColor = String(object.footerBackgroundColor);
        if (object.childBirthdate != null)
            message.childBirthdate = Boolean(object.childBirthdate);
        if (object.slogan != null)
            message.slogan = Boolean(object.slogan);
        if (object.dateType != null)
            message.dateType = String(object.dateType);
        return message;
    };

    /**
     * Creates a plain object from a PrintOffers message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PrintOffers
     * @static
     * @param {PrintOffers} message PrintOffers
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PrintOffers.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.offerAttributes = [];
        if (options.defaults) {
            object.template = "";
            object.font = "";
            object.background = "";
            object.priceType = "";
            object.roundedPrice = false;
            object.priceDetails = false;
            object.previousPrice = false;
            object.omnibusPrice = false;
            object.description = false;
            object.pictures = false;
            object.operator = false;
            object.luggage = false;
            object.airline = false;
            object.airportCode = false;
            object.roomCode = false;
            object.candies = false;
            object.tripAdvisor = false;
            object.facilities = false;
            object.map = false;
            object.logo = false;
            object.legalNotice = false;
            object.sloganText = "";
            object.qrCode = false;
            object.qrCodeText = "";
            object.bookingConditions = false;
            object.beachDistance = false;
            object.showStamp = false;
            object.stampColor = "";
            object.stamp = "";
            object.showAgencyData = false;
            object.mapDetails = null;
            object.offerUrl = false;
            object.offerUrlDetails = null;
            object.renovationYear = false;
            object.showAuthor = false;
            object.showAgentPhoto = false;
            object.fontColor = "";
            object.borderColor = "";
            object.footerFontColor = "";
            object.footerBackgroundColor = "";
            object.childBirthdate = false;
            object.slogan = false;
            object.dateType = "";
        }
        if (message.template != null && message.hasOwnProperty("template"))
            object.template = message.template;
        if (message.font != null && message.hasOwnProperty("font"))
            object.font = message.font;
        if (message.background != null && message.hasOwnProperty("background"))
            object.background = message.background;
        if (message.priceType != null && message.hasOwnProperty("priceType"))
            object.priceType = message.priceType;
        if (message.roundedPrice != null && message.hasOwnProperty("roundedPrice"))
            object.roundedPrice = message.roundedPrice;
        if (message.priceDetails != null && message.hasOwnProperty("priceDetails"))
            object.priceDetails = message.priceDetails;
        if (message.previousPrice != null && message.hasOwnProperty("previousPrice"))
            object.previousPrice = message.previousPrice;
        if (message.omnibusPrice != null && message.hasOwnProperty("omnibusPrice"))
            object.omnibusPrice = message.omnibusPrice;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.pictures != null && message.hasOwnProperty("pictures"))
            object.pictures = message.pictures;
        if (message.operator != null && message.hasOwnProperty("operator"))
            object.operator = message.operator;
        if (message.luggage != null && message.hasOwnProperty("luggage"))
            object.luggage = message.luggage;
        if (message.airline != null && message.hasOwnProperty("airline"))
            object.airline = message.airline;
        if (message.airportCode != null && message.hasOwnProperty("airportCode"))
            object.airportCode = message.airportCode;
        if (message.roomCode != null && message.hasOwnProperty("roomCode"))
            object.roomCode = message.roomCode;
        if (message.candies != null && message.hasOwnProperty("candies"))
            object.candies = message.candies;
        if (message.tripAdvisor != null && message.hasOwnProperty("tripAdvisor"))
            object.tripAdvisor = message.tripAdvisor;
        if (message.offerAttributes && message.offerAttributes.length) {
            object.offerAttributes = [];
            for (let j = 0; j < message.offerAttributes.length; ++j)
                object.offerAttributes[j] = message.offerAttributes[j];
        }
        if (message.facilities != null && message.hasOwnProperty("facilities"))
            object.facilities = message.facilities;
        if (message.map != null && message.hasOwnProperty("map"))
            object.map = message.map;
        if (message.logo != null && message.hasOwnProperty("logo"))
            object.logo = message.logo;
        if (message.legalNotice != null && message.hasOwnProperty("legalNotice"))
            object.legalNotice = message.legalNotice;
        if (message.sloganText != null && message.hasOwnProperty("sloganText"))
            object.sloganText = message.sloganText;
        if (message.qrCode != null && message.hasOwnProperty("qrCode"))
            object.qrCode = message.qrCode;
        if (message.qrCodeText != null && message.hasOwnProperty("qrCodeText"))
            object.qrCodeText = message.qrCodeText;
        if (message.bookingConditions != null && message.hasOwnProperty("bookingConditions"))
            object.bookingConditions = message.bookingConditions;
        if (message.beachDistance != null && message.hasOwnProperty("beachDistance"))
            object.beachDistance = message.beachDistance;
        if (message.showStamp != null && message.hasOwnProperty("showStamp"))
            object.showStamp = message.showStamp;
        if (message.stampColor != null && message.hasOwnProperty("stampColor"))
            object.stampColor = message.stampColor;
        if (message.stamp != null && message.hasOwnProperty("stamp"))
            object.stamp = message.stamp;
        if (message.showAgencyData != null && message.hasOwnProperty("showAgencyData"))
            object.showAgencyData = message.showAgencyData;
        if (message.mapDetails != null && message.hasOwnProperty("mapDetails"))
            object.mapDetails = $root.PrintOffers.MapDetails.toObject(message.mapDetails, options);
        if (message.offerUrl != null && message.hasOwnProperty("offerUrl"))
            object.offerUrl = message.offerUrl;
        if (message.offerUrlDetails != null && message.hasOwnProperty("offerUrlDetails"))
            object.offerUrlDetails = $root.PrintOffers.OfferUrlDetails.toObject(message.offerUrlDetails, options);
        if (message.renovationYear != null && message.hasOwnProperty("renovationYear"))
            object.renovationYear = message.renovationYear;
        if (message.showAuthor != null && message.hasOwnProperty("showAuthor"))
            object.showAuthor = message.showAuthor;
        if (message.showAgentPhoto != null && message.hasOwnProperty("showAgentPhoto"))
            object.showAgentPhoto = message.showAgentPhoto;
        if (message.fontColor != null && message.hasOwnProperty("fontColor"))
            object.fontColor = message.fontColor;
        if (message.borderColor != null && message.hasOwnProperty("borderColor"))
            object.borderColor = message.borderColor;
        if (message.footerFontColor != null && message.hasOwnProperty("footerFontColor"))
            object.footerFontColor = message.footerFontColor;
        if (message.footerBackgroundColor != null && message.hasOwnProperty("footerBackgroundColor"))
            object.footerBackgroundColor = message.footerBackgroundColor;
        if (message.childBirthdate != null && message.hasOwnProperty("childBirthdate"))
            object.childBirthdate = message.childBirthdate;
        if (message.slogan != null && message.hasOwnProperty("slogan"))
            object.slogan = message.slogan;
        if (message.dateType != null && message.hasOwnProperty("dateType"))
            object.dateType = message.dateType;
        return object;
    };

    /**
     * Converts this PrintOffers to JSON.
     * @function toJSON
     * @memberof PrintOffers
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PrintOffers.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PrintOffers
     * @function getTypeUrl
     * @memberof PrintOffers
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PrintOffers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PrintOffers";
    };

    PrintOffers.MapDetails = (function() {

        /**
         * Properties of a MapDetails.
         * @memberof PrintOffers
         * @interface IMapDetails
         * @property {number|null} [position] MapDetails position
         */

        /**
         * Constructs a new MapDetails.
         * @memberof PrintOffers
         * @classdesc Represents a MapDetails.
         * @implements IMapDetails
         * @constructor
         * @param {PrintOffers.IMapDetails=} [properties] Properties to set
         */
        function MapDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapDetails position.
         * @member {number} position
         * @memberof PrintOffers.MapDetails
         * @instance
         */
        MapDetails.prototype.position = 0;

        /**
         * Creates a new MapDetails instance using the specified properties.
         * @function create
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {PrintOffers.IMapDetails=} [properties] Properties to set
         * @returns {PrintOffers.MapDetails} MapDetails instance
         */
        MapDetails.create = function create(properties) {
            return new MapDetails(properties);
        };

        /**
         * Encodes the specified MapDetails message. Does not implicitly {@link PrintOffers.MapDetails.verify|verify} messages.
         * @function encode
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {PrintOffers.IMapDetails} message MapDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.position);
            return writer;
        };

        /**
         * Encodes the specified MapDetails message, length delimited. Does not implicitly {@link PrintOffers.MapDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {PrintOffers.IMapDetails} message MapDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapDetails message from the specified reader or buffer.
         * @function decode
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PrintOffers.MapDetails} MapDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PrintOffers.MapDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.position = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PrintOffers.MapDetails} MapDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapDetails message.
         * @function verify
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.position != null && message.hasOwnProperty("position"))
                if (!$util.isInteger(message.position))
                    return "position: integer expected";
            return null;
        };

        /**
         * Creates a MapDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PrintOffers.MapDetails} MapDetails
         */
        MapDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.PrintOffers.MapDetails)
                return object;
            let message = new $root.PrintOffers.MapDetails();
            if (object.position != null)
                message.position = object.position | 0;
            return message;
        };

        /**
         * Creates a plain object from a MapDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {PrintOffers.MapDetails} message MapDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.position = 0;
            if (message.position != null && message.hasOwnProperty("position"))
                object.position = message.position;
            return object;
        };

        /**
         * Converts this MapDetails to JSON.
         * @function toJSON
         * @memberof PrintOffers.MapDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapDetails
         * @function getTypeUrl
         * @memberof PrintOffers.MapDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PrintOffers.MapDetails";
        };

        return MapDetails;
    })();

    PrintOffers.OfferUrlDetails = (function() {

        /**
         * Properties of an OfferUrlDetails.
         * @memberof PrintOffers
         * @interface IOfferUrlDetails
         * @property {string|null} [affiliate] OfferUrlDetails affiliate
         * @property {string|null} [type] OfferUrlDetails type
         */

        /**
         * Constructs a new OfferUrlDetails.
         * @memberof PrintOffers
         * @classdesc Represents an OfferUrlDetails.
         * @implements IOfferUrlDetails
         * @constructor
         * @param {PrintOffers.IOfferUrlDetails=} [properties] Properties to set
         */
        function OfferUrlDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OfferUrlDetails affiliate.
         * @member {string} affiliate
         * @memberof PrintOffers.OfferUrlDetails
         * @instance
         */
        OfferUrlDetails.prototype.affiliate = "";

        /**
         * OfferUrlDetails type.
         * @member {string} type
         * @memberof PrintOffers.OfferUrlDetails
         * @instance
         */
        OfferUrlDetails.prototype.type = "";

        /**
         * Creates a new OfferUrlDetails instance using the specified properties.
         * @function create
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {PrintOffers.IOfferUrlDetails=} [properties] Properties to set
         * @returns {PrintOffers.OfferUrlDetails} OfferUrlDetails instance
         */
        OfferUrlDetails.create = function create(properties) {
            return new OfferUrlDetails(properties);
        };

        /**
         * Encodes the specified OfferUrlDetails message. Does not implicitly {@link PrintOffers.OfferUrlDetails.verify|verify} messages.
         * @function encode
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {PrintOffers.IOfferUrlDetails} message OfferUrlDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferUrlDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.affiliate != null && Object.hasOwnProperty.call(message, "affiliate"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.affiliate);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            return writer;
        };

        /**
         * Encodes the specified OfferUrlDetails message, length delimited. Does not implicitly {@link PrintOffers.OfferUrlDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {PrintOffers.IOfferUrlDetails} message OfferUrlDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OfferUrlDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OfferUrlDetails message from the specified reader or buffer.
         * @function decode
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PrintOffers.OfferUrlDetails} OfferUrlDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferUrlDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PrintOffers.OfferUrlDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.affiliate = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OfferUrlDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PrintOffers.OfferUrlDetails} OfferUrlDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OfferUrlDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OfferUrlDetails message.
         * @function verify
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OfferUrlDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.affiliate != null && message.hasOwnProperty("affiliate"))
                if (!$util.isString(message.affiliate))
                    return "affiliate: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            return null;
        };

        /**
         * Creates an OfferUrlDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PrintOffers.OfferUrlDetails} OfferUrlDetails
         */
        OfferUrlDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.PrintOffers.OfferUrlDetails)
                return object;
            let message = new $root.PrintOffers.OfferUrlDetails();
            if (object.affiliate != null)
                message.affiliate = String(object.affiliate);
            if (object.type != null)
                message.type = String(object.type);
            return message;
        };

        /**
         * Creates a plain object from an OfferUrlDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {PrintOffers.OfferUrlDetails} message OfferUrlDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OfferUrlDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.affiliate = "";
                object.type = "";
            }
            if (message.affiliate != null && message.hasOwnProperty("affiliate"))
                object.affiliate = message.affiliate;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            return object;
        };

        /**
         * Converts this OfferUrlDetails to JSON.
         * @function toJSON
         * @memberof PrintOffers.OfferUrlDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OfferUrlDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OfferUrlDetails
         * @function getTypeUrl
         * @memberof PrintOffers.OfferUrlDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OfferUrlDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/PrintOffers.OfferUrlDetails";
        };

        return OfferUrlDetails;
    })();

    return PrintOffers;
})();

export { $root as default };
