import React, {
  forwardRef, useEffect, useState, memo, useImperativeHandle, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FieldError } from '@ess/types';

import Text from '@ess/ui/Text';

import { floor, isEmpty, round } from 'lodash-es';
import { Styled } from './TextArea.styles';

export type TextAreaProps = {
  resizeToContent?: boolean
  disableInterActivity?: boolean
  disableEnter?: boolean
  name?: string
  value?: any
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onClick?: (event: React.PointerEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent) => void
  onFocus?: (event: React.FocusEvent) => void
  placeholder?: string
  error?: FieldError
  readOnly?: boolean
  maxLength?: number
  showErrorMessage?: boolean;
  style?: React.CSSProperties
  unControlled?: boolean
};

const defaultProps = {
  resizeToContent: false,
  unControlled: true,
  disableInterActivity: false,
  disableEnter: false,
  name: '',
  value: '',
  onChange: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  placeholder: '',
  error: '',
  showErrorMessage: true,
  readOnly: false,
  maxLength: undefined,
  style: undefined,
};

const TextInput = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  resizeToContent,
  unControlled,
  disableInterActivity,
  disableEnter,
  name,
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  placeholder,
  readOnly,
  error,
  showErrorMessage,
  maxLength,
  style,
  ...props
}, ref) => {
  const { t } = useTranslation();
  const [textAreaValue, setTextAreaValue] = useState<any>('');
  const hasError: any = error && error.length;
  const inputElement = useRef<any>(null);

  useImperativeHandle(ref, () => inputElement.current);

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setTextAreaValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  const clickHandler = (event: React.PointerEvent<HTMLTextAreaElement>): void => {
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    setTextAreaValue(value || '');
  }, [value]);

  const lineCounter = (value: any) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as any;
    ctx.font = '13px Arial';
    const lineMaxWidth = Number(inputElement?.current?.getBoundingClientRect().width) - 14;
    const words : any = value.split(' ') as any;
    let lineCount = 0;
    let currentLineWidth = lineMaxWidth;
    words.map((word: string, index: number) => {
      let wordWidth = ctx.measureText((index === 0 && isEmpty(words[1])) || index === words.length - 1 || isEmpty(word) ? `${word}` : `${word} `).width;
      if (currentLineWidth <= wordWidth) {
        lineCount += 1;
        currentLineWidth = lineMaxWidth;
        const neededLines = wordWidth / lineMaxWidth;
        if (neededLines >= 1) {
          lineCount += floor(neededLines);
          wordWidth -= lineMaxWidth * floor(neededLines);
          currentLineWidth -= wordWidth;
        } else {
          currentLineWidth -= wordWidth;
        }
      } else {
        currentLineWidth -= wordWidth;
      }
    });
    return lineCount;
  };

  const getHeight = () => {
    const baseHeight = 35;
    const additionalLineHeight = 18;

    if (!resizeToContent) {
      return '100%';
    }
    const lineCount = lineCounter(textAreaValue);

    if (isEmpty(textAreaValue)) {
      return `${baseHeight}px`;
    }
    if (lineCount >= 1) {
      return `${baseHeight + additionalLineHeight * lineCount}px`;
    }
    return `${baseHeight}px`;
  };

  return (
    <Styled.TextArea__Wrapper>
      <Styled.TextArea
        disableInterActivity={!!disableInterActivity}
        ref={inputElement}
        hasError={hasError}
        onKeyPress={disableEnter ? (event: any) => {
          if (event.keyCode === 13 || event.which === 13) {
            event.preventDefault();
          }
        } : () => false}
        onChange={changeHandler}
        onClick={clickHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        style={{
          height: getHeight(),
          minHeight: resizeToContent ? 'unset' : '150px',
          ...style,
        }}
        value={unControlled ? textAreaValue : value}
        {...{ ...name ? { name, id: name } : {} }}
        {...{ ...placeholder ? { placeholder } : {} }}
        {...{ ...maxLength ? { maxLength } : {} }}
        {...props}
      />
      {hasError && showErrorMessage && (<Text fontSize="12px" my="2px" color="errorRed">{t(error as string)}</Text>)}
    </Styled.TextArea__Wrapper>

  );
});

TextInput.defaultProps = defaultProps;

export default memo(TextInput);
