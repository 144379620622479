import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { UrlParams } from '@liveroom/types';

import { Page } from '@liveroom/layout';
import { OffersContainer } from '@liveroom/containers';
import { useLiveRoomStore } from '@liveroom/hooks';

import OfferListSkeleton from '../../components/OfferList/OfferListSkeleton';

type IbeLinkProps = {
  params?: any
  isFavouritesEnabled?: boolean
}

const IbeLink = ({ params = undefined, isFavouritesEnabled = true }: IbeLinkProps) => {
  const routeParams = useParams<UrlParams>();
  const { fetchConfig, status } = useLiveRoomStore(useShallow((state) => ({
    fetchConfig: state.fetchConfig,
    status: state.config.status,
  })));

  const searchParams = params ?? routeParams;

  useEffect(() => {
    fetchConfig(searchParams.affiliate);
  }, []);

  return (
    <Page>
      {status === 'ready' ? (
        <OffersContainer
          params={searchParams}
          isFavouritesEnabled={isFavouritesEnabled}
        />
      ) : <OfferListSkeleton/>}
    </Page>
  );
};

export {
  IbeLink,
};
