import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faList, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';

import { useReactBasket } from '@basket/hooks/useReactBasket';

import { ButtonGroup, IconButton } from '@ess/ui/Button';
import Checkbox from '@ess/ui/Form/Checkbox';
import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

import { useAppConfig } from '@ess/context/AppConfigContext';
import { useAgentSettings } from '@ess/components/AgentSettings/hooks';

import Tooltip from '@ess/ui/Tooltip';
import FeaturedActions from './FeaturedActions';

type ControlsProps = {
  onViewChange: any
  offerView: any
}

const Controls = ({ onViewChange, offerView }: ControlsProps) => {
  const { t } = useTranslation();
  const basketOfferView = useAgentSettings((state) => state.values.basket.basketOfferView);
  const currentView = useRef(basketOfferView);
  const { maxBasketItems } = useAppConfig();

  const {
    basket,
    selectAllItems,
  } = useReactBasket();

  const viewChangeHandler = (view: string) => {
    currentView.current = view;
    onViewChange(view);
  };

  useEffect(() => {
    if (basketOfferView !== currentView.current) {
      onViewChange(basketOfferView);
      currentView.current = basketOfferView;
    }
  }, [basketOfferView]);

  return (
    <FlexBox
      py="small"
      px="medium"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexBox alignItems="center">
        <FlexBox width={150}>
          <Checkbox
            checked={basket.allSelected}
            onChange={selectAllItems}
            label={(
              <Text color="darkGray">
                {basket.allSelected ? t('lbl_uncheck_all') : t('lbl_check_all')}
              </Text>
            )}
          />
        </FlexBox>
        <FeaturedActions/>
      </FlexBox>

      <FlexBox alignItems="center">
        <FlexBox alignItems="center">
          <Text ml="tiny" color="textColor">
            {`${t('lbl_basket_items_count')}: ${basket.count}/${maxBasketItems}`}
          </Text>
        </FlexBox>

        {basket.isBlocked && (
          <FlexBox ml="small" alignItems="center">
            <Tooltip theme="bubble-wide" content={t('lbl_basket_blocked')} placement="bottom-end">
              <FontAwesomeIcon icon={faLock}/>
            </Tooltip>
          </FlexBox>
        )}

        <FlexBox ml="small" alignItems="center">
          <ButtonGroup>
            <IconButton
              size="small"
              variant={offerView === 'list' ? 'primary' : 'secondary'}
              icon={<FontAwesomeIcon icon={faList}/>}
              onClick={() => viewChangeHandler('list')}
              disabled={isMobileOnly}
            />
            <IconButton
              size="small"
              variant={offerView === 'grid' ? 'primary' : 'secondary'}
              icon={<FontAwesomeIcon icon={faGrid2}/>}
              onClick={() => viewChangeHandler('grid')}
            />
          </ButtonGroup>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Controls;
