import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

import Modal from '@ess/ui/Modal/ModalsTypes/Modal';

import PriceListConent from '@tourop/components/OfferElements/NewPriceList/PriceListConent';

import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import Drawer from '@ess/ui/Drawer';

type PriceListProps = {
  appendRef?: any
  status?: any
  isAppendAble?: boolean
  offerData:any
  isOpen:boolean
  isLoading: boolean
  onClose:() => void
}

const PriceList = ({
  status = {},
  appendRef = {},
  isAppendAble = false,
  offerData,
  isLoading,
  isOpen,
  onClose,
}:PriceListProps,
) => {
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

  useOnClickOutside(modalElement, onClose);

  const { styles, attributes } = usePopperPositioning({
    targetElementRef: appendRef,
    popperElementRef: modalElement,
    applyMaxSizeEnabled: true,
    placement: 'bottom-end',
    zIndex: 9999,
    padding: 15,
  });

  const { t } = useTranslation();

  const content = (
    <PriceListConent
      isBigContent
      status={status}
      offerData={offerData}
      isLoading={isLoading}
    />
  );

  return !isMobileOnly ? (
    <Modal
      ref={setModalElement}
      isOpen={isOpen}
      onClose={onClose}
      theme="white"
      showOverlay={!isAppendAble}
      title={t('lbl_menu_pricelist')}
      style={isAppendAble ? { ...styles.popper, border: '2px solid rgb(16, 44, 88' } : { border: '2px solid rgb(16, 44, 88' }}
      {...isAppendAble ? attributes.popper : {}}
    >
      {content}
    </Modal>
  ) : (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position="bottom"
      title={t('lbl_menu_pricelist')}
    >
      {content}
    </Drawer>
  );
};

export default PriceList;
