import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import {
  find, has, includes, isEmpty, partition, uniq,
} from 'lodash-es';

import { OptionTypeBase } from 'react-select';
import { IOption } from '@ess/types';

import useStorage from '@ess/hooks/useStorage';

import Field from './Field';
import Controls from './Controls';
import OptionsList from './OptionsList';
import SearchInput from './SearchInput';

import Dropdown, { DropdownProps } from './Views/Dropdown';
import Mobile, { MobileProps } from './Views/Mobile';

import { Styled } from './MultiSelectV2.styles';

const DEFAULT_VIEW = 'dropdown';

const viewMode: Record<string, any> = {
  dropdown: (props: DropdownProps) => <Dropdown {...props}>{props.children}</Dropdown>,
  mobile: (props: MobileProps) => <Mobile {...props}>{props.children}</Mobile>,
};

type ViewModes = 'dropdown | modal | mobile';

type MultiSelectV2Props = {
  label?: string
  name: string
  value: any[]
  options: any
  view?: ViewModes
  target?: React.ReactElement
  headerElement?: React.ReactNode
  placeholder?: string
  isLoading?: boolean
  isDisabled?: boolean
  isFavouritesEnabled?: boolean
  isSearchable?: boolean
  appendTo?: Element | null
  onOpen?: () => void
  onClose?: () => void
  transformCheckedValues?: (values: any[], currentValue: any) => any
  onChange?: (value: any[]) => void
  onApply?: (value: any[]) => void
  maxHeight?: number
  width?: number
}

const getOptionsWithFavourites = (options: OptionTypeBase[], favourites: any) => {
  const [favouriteOptions, rest] = partition(
    options, (item) => find(favourites, (favourite) => favourite.value === item.value),
  );

  return {
    favouriteOptions,
    rest,
  };
};

const getOptions = (t: any, options: any, favourites: any) => {
  let newOptions: any[] = [];
  const hasGroups = options.length && has(options[0], 'options');

  if (hasGroups) {
    let favouriteTemp: any[] = [];

    options.map((item: any) => {
      const label = item.label;
      const { favouriteOptions, rest } = getOptionsWithFavourites(item.options, favourites);

      favouriteTemp = [...favouriteTemp, ...favouriteOptions];
      newOptions = rest.length > 0
        ? [...newOptions, { label, options: rest }]
        : newOptions;
    });

    newOptions = favouriteTemp.length > 0
      ? [{ label: t('lbl_favourites'), options: favouriteTemp }, ...newOptions]
      : newOptions;
  } else {
    const { favouriteOptions, rest } = getOptionsWithFavourites(options, favourites);

    newOptions = rest.length > 0 && favouriteOptions.length > 0
      ? [...newOptions, { label: t('lbl_rest_options'), options: rest }]
      : newOptions;

    newOptions = favouriteOptions.length > 0
      ? [{ label: t('lbl_favourites'), options: favouriteOptions }, ...newOptions]
      : newOptions;
  }

  return newOptions.length > 0 ? newOptions : options;
};

const getUngroupedOptions = (options: OptionTypeBase[]) => {
  const isGrouped = has(options[0], 'options');

  if (isGrouped) {
    let ungroupedOptions: OptionTypeBase[] = [];

    options.map((item) => {
      ungroupedOptions = [...ungroupedOptions, ...item.options];
    });

    return ungroupedOptions;
  }

  return options;
};

const MultiSelectV2 = ({
  name,
  value,
  options,
  label = '',
  view = DEFAULT_VIEW as ViewModes,
  target = undefined,
  placeholder = undefined,
  isLoading = false,
  isDisabled = false,
  isSearchable = true,
  isFavouritesEnabled = true,
  transformCheckedValues = undefined,
  headerElement = undefined,
  appendTo = undefined,
  onOpen = undefined,
  onClose = undefined,
  onChange = undefined,
  onApply = undefined,
  maxHeight = 340,
  width = undefined,
}: MultiSelectV2Props) => {
  const { t } = useTranslation();
  const targetElement = useRef<HTMLElement>(null);
  const values = useMemo(() => uniq(value.filter((item) => ((typeof item) === 'number' ? true : !isEmpty(item)))
    .map((item) => item.toString())), [value]);
  const [currentView, setCurrentView] = useState<ViewModes>(view as ViewModes);
  const [checkedOptions, setCheckedOptions] = useState<any[]>([]);
  const [currentOptions, setCurrentOptions] = useState<any[]>([]);
  const [favourites, setFavourites] = useStorage<{ [index: string]: any }>('localStorage', 'FormSelectsFavorites', {});
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const getSelectedOptions = useCallback((options: OptionTypeBase[]): any => {
    const ungroupedOptions = getUngroupedOptions(options);

    return ungroupedOptions.filter((item: any) => includes(checkedOptions, item.value));
  }, [checkedOptions, options]);

  /**
   * Search options handler.
   * @param value
   */
  const onSearchHandler = (value: string) => {
    const filteredOptions = getUngroupedOptions(options).filter(
      (item) => includes(item.label.toUpperCase(), value.toUpperCase()),
    );

    setIsFiltered(!!value);
    setCurrentOptions(value ? filteredOptions : getOptions(t, options, favourites[name]));
  };

  /**
   * Option add to favouriteHandler
   * @param value
   */
  const addToFavouriteHandler = useCallback((event: React.MouseEvent, option: IOption) => {
    event.stopPropagation();
    event.preventDefault();

    const isInFavourites = find(favourites[name], (favourite) => option.value === favourite.value);

    setFavourites((state: any) => ({
      ...state,
      [name]: isInFavourites
        ? state[name].filter((favourite: IOption) => favourite.value !== option.value)
        : [...state[name], option],
    }));
  }, [favourites[name]]);

  /**
   * Option click handler.
   * @param value
   */

  const checkedOptionsParser = (value : any, state: any) => {
    const isChecked = includes(state, value);

    if (transformCheckedValues) {
      return transformCheckedValues([...state], value);
    }

    return isChecked
      ? [...state].filter((item) => (item !== value))
      : [...state, value];
  };

  const optionClickHandler = useCallback((value: any) => {
    setCheckedOptions((state) => {
      const isChecked = includes(state, value);

      if (transformCheckedValues) {
        return transformCheckedValues([...state], value);
      }

      return isChecked
        ? [...state].filter((item) => (item !== value))
        : [...state, value];
    });
    if (onChange) {
      onChange(checkedOptionsParser(value, checkedOptions));
    }
  }, [checkedOptions]);

  /**
   * Open multiselect handler.
   */
  const openMultiSelectHandler = useCallback(() => {
    if (!isDisabled) {
      setIsOpen(true);

      if (onOpen) {
        onOpen();
      }
    }
  }, [onOpen]);

  /**
   * Apply value handler.
   */
  const applyHandler = useCallback(() => {
    if (onApply) {
      onApply(checkedOptions);
    }

    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  }, [checkedOptions]);

  /**
   * Close multiselect handler.
   */
  const closeHandler = useCallback(() => {
    applyHandler();
  }, [applyHandler]);

  /**
   * Reset value handler.
   */
  const resetHandler = useCallback(() => {
    if (onApply) {
      onApply([]);
    }
    if (onChange) {
      onChange([]);
    }

    setCheckedOptions([]);
  }, [onApply]);

  useEffect(() => {
    setCheckedOptions(values);
  }, [values]);

  useEffect(() => {
    if (isMobileOnly) {
      setCurrentView('mobile' as ViewModes);
    } else {
      setCurrentView(view as ViewModes);
    }
  }, [isMobileOnly]);

  useEffect(() => {
    const hasFavourites = has(favourites, name);

    if (!hasFavourites) {
      setFavourites((state: any) => ({
        ...state,
        [name]: [],
      }));
    }
  }, [favourites]);

  useEffect(() => {
    if (!isFiltered) {
      setCurrentOptions(getOptions(t, options, favourites[name] ?? []));
    }
  }, [options, favourites[name], isFiltered]);

  const controlsBottomElement = !isLoading && options.length > 0 ? (
    <Controls onReset={resetHandler} onApply={applyHandler}/>
  ) : null;

  const controlsTopElement = !isLoading && getUngroupedOptions(options).length > 9 && isSearchable ? (
    <SearchInput onChange={onSearchHandler}/>
  ) : null;

  const contentElement = (
    <OptionsList
      options={currentOptions}
      isLoading={!!isLoading}
      isFavouritesEnabled={!!isFavouritesEnabled}
      checkedOptions={checkedOptions}
      favouriteOptions={favourites[name]}
      onClick={optionClickHandler}
      onStarClick={addToFavouriteHandler}
    />
  );

  return (
    <Styled.MultiSelect disabled={isDisabled} hasCustomElement={!!target}>
      {!target ? (
        <Field
          ref={targetElement as any}
          label={label}
          selectedOptions={getSelectedOptions(options)}
          placeholder={placeholder}
          isOpen={isOpen}
          isDisabled={isDisabled}
          onClick={openMultiSelectHandler}
          onClear={resetHandler}
        />
      ) : (
        React.cloneElement(target, {
          onClick: openMultiSelectHandler,
          ref: targetElement,
        })
      )}

      {viewMode[currentView]({
        label,
        isOpen,
        isLoading,
        targetElement,
        appendTo,
        maxHeight,
        width,
        headerElement,
        children: contentElement,
        controlsTop: controlsTopElement,
        controlsBottom: controlsBottomElement,
        onClose: closeHandler,
      })}
    </Styled.MultiSelect>
  );
};

export default MultiSelectV2;

export {
  Field,
};
