import React, {
  createContext, useEffect, useRef,
} from 'react';

import {
  LayoutSettingStore,
  createLayoutSettingsStore,
} from './store';

const LayoutSettingsContext = createContext<LayoutSettingStore | null>(null);

type LayoutSettingsProviderProps = {
  children: React.ReactNode;
}

const LayoutSettingsProvider = ({ children }: LayoutSettingsProviderProps) => {
  const storeRef = useRef<LayoutSettingStore>();

  if (!storeRef.current) {
    storeRef.current = createLayoutSettingsStore();
  }

  useEffect(() => {
    if (storeRef.current) {
      window.__REACT_APP_AGENCY__ = storeRef.current.getState().userData;
    }
  }, []);

  return (
    <LayoutSettingsContext.Provider value={storeRef.current}>
      {children}
    </LayoutSettingsContext.Provider>
  );
};

export {
  LayoutSettingsProvider,
  LayoutSettingsContext,
};
