import { useContext } from 'react';
import { useStore } from 'zustand';

import { LayoutSettingsContext } from './LayoutSettingsProvider';

import { Store } from './store';

const useLayoutSettings = <T>(selector: (state: Store) => T) => {
  const store = useContext(LayoutSettingsContext);

  if (!store) {
    throw new Error('useLayoutSettings hook must be used within LayoutSettingsProvider');
  }

  return useStore(store, selector!);
};

export {
  useLayoutSettings,
};
