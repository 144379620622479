import React, { useMemo } from 'react';

import { IOption } from '@ess/types';

import { Styled } from '../../MultiSelectV2.styles';

import Tag from './Item';

type TagsProps = {
  options: any[];
  inputWidth: number;
}

const Tags = ({ options, inputWidth }: TagsProps) => {
  const tags = useMemo(() => {
    const tempTags: IOption[] = [];
    let tagsWidth = 0;

    options.map((item: IOption) => {
      const itemWidth = (item.label.length * 8) + 4;

      if (inputWidth - 48 > tagsWidth + itemWidth) {
        tagsWidth += itemWidth;
        tempTags.push(item);
      }
    });

    if (!tempTags.length && options.length > 0) {
      return [options[0]];
    }

    return tempTags;
  }, [options, inputWidth]);

  return (
    <>
      {tags.length > 0 && (
        <Styled.MultiSelect__Field__Tags>
          {tags.map((item: IOption) => (
            <Tag value={item.label} key={item.value}/>
          ))}
          {options.length > tags.length && (
            <Tag value={`+ ${options.length - tags.length}`} isCount/>
          )}
        </Styled.MultiSelect__Field__Tags>
      )}
    </>
  );
};

export default Tags;
