import { get } from 'lodash-es';

const useUserData = () => (
  window.isCloudVersion
    ? get(window, '__REACT_APP_AGENCY__', {})
    : get(window, '__REACT_APP_AGENCY__.userData', {})
);

export {
  useUserData,
};
