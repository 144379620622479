import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileOnly } from 'react-device-detect';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/pro-light-svg-icons';

import { IOption } from '@ess/types';

import Checkbox from '@ess/ui/Form/Checkbox';

import { Styled } from '@tourop/components/MultiSelectV2/MultiSelectV2.styles';

type OptionProps = {
  option: IOption
  onClick: (value: string | number) => void
  onStarClick: (event: React.MouseEvent, option: IOption) => void
  isChecked: boolean
  isFavourite: boolean
  isFavouritesEnabled: boolean
}

const Option = ({
  option,
  onClick,
  onStarClick,
  isChecked,
  isFavourite,
  isFavouritesEnabled,
}: OptionProps) => {
  const optionClickHandler = () => {
    onClick(option.value);
  };

  const addToFavouritesHandler = (event: React.MouseEvent) => {
    onStarClick(event, option);
  };

  const starElement = isFavouritesEnabled && (
    <Styled.MultiSelect__Option__Favourite
      isMobileOnly={isMobileOnly}
      isActive={isFavourite}
      onClick={addToFavouritesHandler}
    >
      <Styled.MultiSelect__Option__Favourite__IconContainer isActive={isFavourite}>
        <FontAwesomeIcon icon={isFavourite ? faStar : faStarEmpty}/>
      </Styled.MultiSelect__Option__Favourite__IconContainer>
    </Styled.MultiSelect__Option__Favourite>
  );

  return (
    <Styled.MultiSelect__Option
      onClick={optionClickHandler}
      isMobileOnly={isMobileOnly}
    >
      {starElement}
      <Checkbox
        checked={isChecked}
        spacing="10px"
        label={(
          <Styled.MultiSelect__Option__Label>
            {option.label}
          </Styled.MultiSelect__Option__Label>
        )}
      />
    </Styled.MultiSelect__Option>
  );
};

export default memo(Option);
