import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { OptionTypeBase } from 'react-select';
import {
  clone, sortBy, isEmpty, has,
} from 'lodash-es';

import { UrlParams } from '@liveroom/types';
import { IOption } from '@ess/types';

import { useLiveRoomStore } from '@liveroom/hooks';

import { OfferList } from '@liveroom/components/OfferList';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Select from '@ess/ui/Form/Select';

type OffersContainerProps = {
  params?: any;
  isFavouritesEnabled?: boolean;
  autoRefreshEnabled?: boolean;
  goToOffer?: (data: any) => void;
};

const OffersContainer = ({
  params = undefined,
  autoRefreshEnabled = false,
  isFavouritesEnabled = true,
  goToOffer = undefined,
}: OffersContainerProps) => {
  const routeParams = useParams<UrlParams>();
  const { t } = useTranslation();
  const [sortKey, setSortKey] = useState('DEFAULT');
  const { fetchOffers, offers } = useLiveRoomStore(useShallow((state) => ({
    fetchOffers: state.fetchOffers,
    offers: state.offers,
  })));
  const [sortedOffers, setSortedOffers] = useState<any>(null);

  const getOptions = () => {
    const options: IOption[] = [];

    options.push({
      label: t('lbl_sort_by_basket_order'),
      value: 'DEFAULT',
    });
    options.push({
      label: t('lbl_sort_price_asc'),
      value: 'PRICE',
    });
    options.push({
      label: t('lbl_hotel'),
      value: 'HOTEL',
    });
    options.push({
      label: t('country'),
      value: 'COUNTRY',
    });
    options.push({
      label: t('lbl_city'),
      value: 'CITY',
    });
    options.push({
      label: t('lbl_transport'),
      value: 'TRANSPORT',
    });
    options.push({
      label: t('operator'),
      value: 'OPERATOR',
    });

    return options;
  };

  const changeHandler = (selectedOption: OptionTypeBase) => {
    const type = selectedOption.value;
    setSortKey(type);
  };

  useEffect(() => {
    fetchOffers({
      params: params ?? routeParams,
      config: {
        autoRefreshEnabled: !!autoRefreshEnabled,
      },
      isFirstRequest: true,
    });
  }, []);

  useEffect(() => {
    setSortedOffers(offers);
  }, [offers]);

  useEffect(() => {
    const sortoffers = clone(offers);
    sortoffers.items.forEach((element, index) => {
      if (has(element.offer.Transport, 'Flight')) {
        sortoffers.items[index].offer.Transport.Mode = 'Aeroplane';
      } else if (has(element.offer.Transport, 'Bus')) {
        sortoffers.items[index].offer.Transport.Mode = 'Coach';
      } else if (isEmpty(element.offer.Transport)) {
        sortoffers.items[index].offer.Transport.Mode = 'Own Trasport';
      }
    });
    let sortOder: string = '';
    switch (sortKey) {
      case 'PRICE':
        sortOder = 'offer.Base.Price.Total.amount';
        break;
      case 'HOTEL':
        sortOder = 'offer.Accommodation.XCode.Name';
        break;
      case 'COUNTRY':
        sortOder = 'offer.Accommodation.XCountry.Name';
        break;
      case 'CITY':
        sortOder = 'offer.Accommodation.XCity.Name';
        break;
      case 'TRANSPORT':
        sortOder = 'offer.Transport.Mode';
        break;
      case 'OPERATOR':
        sortOder = 'offer.Base.OperatorDesc';
        break;
      default:
        break;
    }
    if (sortOder.length > 0) {
      sortoffers.items = sortBy(offers.items, sortOder);
    }
    setSortedOffers(sortoffers);
  }, [sortKey]);

  return (
    <FlexBox width="100%" flexDirection="column">
      {params.compareOffers && (
        <FlexBox
          width="100%"
          flexWrap="wrap"
          marginBottom="small"
          justifyContent={{
            xxs: 'flex-start',
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'space-between',
            lg: 'space-between',
          }}
        >
          <FlexBox>
            <Text py="small" lineHeight="34px" fontSize="24px" textAlign="left">
              {`${t('lbl_compare_offers')}: ${sortedOffers?.name ?? ''}`}
            </Text>
          </FlexBox>
          <FlexBox alignItems="center">
            <Text pr="small" whiteSpace="nowrap">
              {`${t('lbl_sort_by')}:`}
            </Text>
            <FlexBox width="200px">
              <Select
                name="SortBy"
                options={getOptions()}
                value={sortKey}
                isClearable={false}
                isSearchable={false}
                onChange={changeHandler}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      <OfferList
        goToOffer={goToOffer}
        offers={sortedOffers}
        isFavouritesEnabled={isFavouritesEnabled}
      />
    </FlexBox>
  );
};

export { OffersContainer };
