import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareMethod } from '@basket/types';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { showToast, TOAST_ERROR, getCurrentLanguage } from '@ess/utils';

import { promiseRequest } from '@ess/v5-data-provider/request';

import useBreakpoint from '@ess/hooks/useBreakpoint';
import { useReactBasket } from '@basket/hooks/useReactBasket';

import { IbeLink } from '@liveroom/pages/IbeLink';
import { LiveRoom } from '@liveroom/pages/LiveRoom';
import { LiveRoomStoreProvider } from '@liveroom/context';

import { Button } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal';
import Box from '@ess/ui/Box';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Select from '@ess/ui/Form/Select';
import Loader from '@ess/ui/Loader';

import { LinkGenerator } from './LinkGenerator';

type ShareBasketProps = {
  onClose: () => void
  basketId: number | null
  description: string
}

const ShareBasket = ({ onClose, basketId, description }: ShareBasketProps) => {
  const breakpoint = useBreakpoint();
  const { t } = useTranslation();
  const [method, setMethod] = useState<ShareMethod>(ShareMethod.IbeLink);
  const [params, setParams] = useState({
    id: '',
    affiliate: '',
    description,
    isLoading: false,
  });

  const { publishBasket } = useReactBasket();

  const isSmallScreen = ['xxs', 'xs', 'sm', 'md'].includes(breakpoint);

  const methodChangeHandler = (selectedOption: any) => {
    setMethod(selectedOption?.value ?? null);
    setParams({
      id: '',
      affiliate: '',
      description: '',
      isLoading: false,
    });
  };

  const onCloseHandler = () => {
    onClose();
  };

  const generateLink = async (affiliate?: number, description = '') => {
    let url = null;

    try {
      setParams((state) => ({
        ...state,
        isLoading: true,
      }));

      const publish: any = await publishBasket(basketId as number, false, description);

      if (publish?.status === 'OK') {
        const request = await promiseRequest(
          `${CONTENT_SERVICE_URL}IbeLinkAffiliateData`, {
            Language: getCurrentLanguage(),
            IbeLinkAffiliateData: {
              BasketId: basketId,
              ...affiliate ? {
                AffiliateId: affiliate,
              } : {},
            },
          }, 3, undefined,
        );

        url = request?.Sections?.IbeLinkAffiliateData?.LiveUrl;

        if (url) {
          const { pathname } = new URL(url);
          const paramsArray = pathname.slice(1).split('/');
          const params: any = paramsArray.length === 3 ? {
            id: paramsArray[2],
            affiliate: paramsArray[1],
          } : {
            id: paramsArray[1],
          };

          setParams((state) => ({
            ...state,
            ...params,
          }));
        }
      } else {
        throw new Error('PUBLISH_BASKET_ERROR');
      }
    } catch (error) {
      showToast(TOAST_ERROR, t('lbl_generate_link_fail'));
    } finally {
      setTimeout(() => {
        setParams((state) => ({
          ...state,
          isLoading: false,
        }));
      }, 0);
    }

    return url;
  };

  return (
    <Modal
      title={t('lbl_share_basket')}
      isOpen
      theme="white"
      width={!isSmallScreen ? '98%' : '450px'}
      maxHeight="98%"
      contentStyle={{
        overflow: 'hidden',
      }}
      onClose={onCloseHandler}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            width="90px"
            onClick={onCloseHandler}
          />
        </FlexBox>
      )}
    >
      <FlexBox width="100%">
        <FlexBox
          p="medium"
          flexDirection="column"
          width={!isSmallScreen ? '400px' : '100%'}
          style={!isSmallScreen ? {
            borderRight: '1px solid #dedede',
          } : {}}
        >
          <Text mb="medium">
            {t('lbl_share_basket_description')}
          </Text>
          <FlexBox width="100%" mb="small">
            <Select
              options={[
                {
                  label: t('ibe_link'),
                  value: ShareMethod.IbeLink,
                },
                {
                  label: t('lbl_liveroom'),
                  value: ShareMethod.LiveRoom,
                },
              ]}
              value={method}
              onChange={methodChangeHandler}
              isClearable={false}
            />
          </FlexBox>
          <LinkGenerator
            method={method}
            description={description}
            generateLink={generateLink}
            isLoading={params.isLoading}
          />
        </FlexBox>
        {!isSmallScreen && (
          <FlexBox flexDirection="column" width="calc(100% - 400px)" overflowY="auto">
            <FlexBox width="100%" flexDirection="column" height={800}>
              {params.isLoading ? (
                <FlexBox
                  height="100%"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Loader size="32px"/>
                </FlexBox>
              ) : params?.id ? (
                <LiveRoomStoreProvider cssVariablesContainer="#ibe-link-preview">
                  <Box id="ibe-link-preview">
                    {method === ShareMethod.IbeLink ? (
                      <IbeLink params={params} isFavouritesEnabled={false}/>
                    ) : (
                      <LiveRoom params={params} isFavouritesEnabled={false}/>
                    )}
                  </Box>
                </LiveRoomStoreProvider>
              ) : (
                <FlexBox
                  width="100%"
                  height="100%"
                  backgroundColor="#f8f8f8"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="heading" mb="medium">{t('lbl_preview')}</Text>
                  <Text fontSize="head">{t('lbl_preview_description')}</Text>
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </Modal>
  );
};

export {
  ShareBasket,
};
