import React from 'react';
import loadable from '@loadable/component';

import { IRoute } from '@ess/types';

import Layout, { PrintOffersLayout1 } from '@merlin/layout';

const NotFound = loadable(async () => {
  const { NotFound } = await import(/* webpackChunkName: "404-page" */'@ess/pages/NotFound');
  return NotFound;
});

const LoginPage = loadable(async () => {
  const { LoginPage } = await import(/* webpackChunkName: "login-page" */'@ess/pages/AuthPage');
  return LoginPage;
});

const SecurePage = loadable(async () => {
  const { SecurePage } = await import(/* webpackChunkName: "login-page" */'@ess/pages/AuthPage');
  return SecurePage;
});

const PasswordRecoveryPage = loadable(async () => {
  const { PasswordRecoveryPage } = await import(/* webpackChunkName: "login-page" */'@ess/pages/AuthPage');
  return PasswordRecoveryPage;
});

const PasswordResetPage = loadable(async () => {
  const { PasswordResetPage } = await import(/* webpackChunkName: "login-page" */'@ess/pages/AuthPage');
  return PasswordResetPage;
});

const PrintOffersPage = loadable(async () => {
  const { PrintOffersPage } = await import(/* webpackChunkName: "print-offers-page" */'@ess/pages/PrintOffersPage');
  return PrintOffersPage;
});

const DetailsPageModern = loadable(async () => {
  const { DetailsPageModern } = await import(/* webpackChunkName: "details-modern-page" */'@ess/pages/DetailsPageModern');
  return DetailsPageModern;
});

const ContentPage = loadable(async () => {
  const { ContentPage } = await import(/* webpackChunkName: "content-page" */'@ess/pages/ContentPage');
  return ContentPage;
});

const SearchPage = loadable(async () => {
  const { SearchPage } = await import(/* webpackChunkName: "search-page" */'@ess/pages/SearchPage');
  return SearchPage;
});

const SearchResultsPage = loadable(async () => {
  const { SearchResultsPage } = await import(/* webpackChunkName: "search-results-page" */'@ess/pages/SearchResultsPage');
  return SearchResultsPage;
});

const CompareOffersPage = loadable(async () => {
  const { CompareOffersPage } = await import(/* webpackChunkName: "search-results-page" */'@ess/pages/CompareOffersPage');
  return CompareOffersPage;
});

const routes: Record<string, IRoute[]> = {
  unAuthorized: [
    {
      name: 'login',
      path: '/login',
      element: <LoginPage />,
    },
    {
      name: 'secure',
      path: '/secure',
      element: <SecurePage/>,
    },
    {
      name: 'password-recovery',
      path: '/password-recovery',
      element: <PasswordRecoveryPage/>,
    },
    {
      name: 'password-reset',
      path: '/password-reset/:hash',
      element: <PasswordResetPage/>,
    },
  ],
  tourop: [
    {
      name: 'tourop',
      path: '/tourop/*',
      element: <></>,
    },
    {
      name: '404-not-found',
      path: '/404-not-found/',
      element: (
        <Layout>
          <NotFound/>
        </Layout>
      ),
    },
  ],
  authorized: [
    {
      name: 'search',
      path: '/:searchType/search/',
      breadCrumb: ':searchType',
      element: (
        <Layout>
          <SearchPage/>
        </Layout>
      ),
    },
    {
      name: 'results',
      path: '/:searchType/results/',
      breadCrumb: 'breadcrumb_results',
      element: (
        <Layout>
          <SearchResultsPage/>
        </Layout>
      ),
    },
    {
      name: 'details',
      path: '/:searchType/details/',
      breadCrumb: 'breadcrumb_details',
      element: (
        <Layout>
          <DetailsPageModern/>
        </Layout>
      ),
    },
    {
      name: 'content',
      path: '/:searchType/content/',
      element: <ContentPage/>,
    },
    {
      name: 'compareOffers',
      path: '/compare-offers/',
      breadCrumb: 'lbl_compare_offers',
      element: (
        <Layout>
          <CompareOffersPage/>
        </Layout>
      ),
    },
    {
      name: 'printOffers',
      path: '/print-offers/:basketId',
      breadCrumb: 'lbl_print_offers',
      element: (
        <PrintOffersLayout1>
          <PrintOffersPage/>
        </PrintOffersLayout1>
      ),
    },
  ],
};

export {
  routes,
};
