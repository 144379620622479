import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faLock } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarEmpty, faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import FlexBox from '@ess/ui/FlexBox';
import Checkbox from '@ess/ui/Form/Checkbox';
import Anchor from '@ess/ui/Anchor';
import Tooltip from '@ess/ui/Tooltip';

import BasketActionsMenu from '@basket/components/BasketActions';

import { Styled } from '../BasketList.styles';

type BasketItemProps = {
  data: {
    id: number;
    name: string;
    blocked: boolean;
    count: number;
    lastModified: {
      date: string;
      time: string;
    }
    consultant: string;
    description: string;
  }
  isChecked: boolean;
  isFavourite: boolean | undefined;
  onCheckboxChange: (isChecked: boolean, basket: { id: number, name: string }) => void;
  onStarClick: (isChecked: boolean, basket: { id: number, name: string }) => void;
  onNameClick: (basket: { id: number, name: string }) => void;
  onDropdownOpen: ((basketId: number | null) => void) | undefined;
  onAction: ((action: string, data: any) => any) | undefined;
  onLockClick: (basket: { id: number, name: string, blocked: boolean }) => void;
}

const BasketItem = ({
  data,
  isChecked,
  isFavourite = false,
  onNameClick,
  onStarClick,
  onAction = undefined,
  onCheckboxChange,
  onDropdownOpen = undefined,
  onLockClick,
}: BasketItemProps) => {
  const { t } = useTranslation();

  const onBasketNameClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    onNameClick(data);
  };

  const onCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(event.target.checked, data);
  };

  const onFavouritesChangeHandler = () => {
    onStarClick(!isFavourite, data);
  };

  const onLockClickHandler = () => {
    onLockClick(data);
  };

  return (
    <Styled.BasketItem>
      <FlexBox mr="tiny" style={{ cursor: 'pointer' }} onClick={onFavouritesChangeHandler}>
        <Tooltip theme="bubble-wide" content={isFavourite ? t('delete_from_favourites') : t('add_to_favourites')}>
          <FontAwesomeIcon
            icon={isFavourite ? faStar : faStarEmpty}
            size="lg"
          />
        </Tooltip>
      </FlexBox>
      <FlexBox mr="tiny">
        <Checkbox
          spacing="0px"
          checked={isChecked}
          disabled={data.blocked}
          onChange={onCheckboxChangeHandler}
        />
      </FlexBox>
      <FlexBox mr="tiny" onClick={onLockClickHandler} style={{ cursor: 'pointer' }}>
        <Tooltip theme="bubble-wide" content={data.blocked ? t('lbl_unblock_basket') : t('lbl_block_basket')}>
          <FontAwesomeIcon
            icon={data.blocked ? faLock : faUnlock}
            size="lg"
          />
        </Tooltip>
      </FlexBox>
      <FlexBox pr="small">
        <Anchor
          color="textColor"
          fontWeight="bold"
          style={{ overflowWrap: 'anywhere' }}
          onClick={onBasketNameClickHandler}
        >
          {data.name}
        </Anchor>
      </FlexBox>
      <FlexBox px="small">
        {`${moment(data.lastModified.date, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT)} ${data.lastModified.time}`}
      </FlexBox>
      <FlexBox px="small">
        {data.count}
      </FlexBox>
      <FlexBox px="small">
        {data.consultant}
      </FlexBox>
      <FlexBox>
        <BasketActionsMenu
          basket={data}
          onDropdownOpen={onDropdownOpen}
          onAction={onAction}
          excludedActions={['autoCheckStatus', 'deleteOffers', 'createBasket']}
        />
      </FlexBox>
    </Styled.BasketItem>
  );
};

export default BasketItem;
