import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons';

import { IExtAgentAttributes } from '@ess/v5-data-provider/interfaces';

import { useSelector } from '@ess/store/core';

import useContentWindow from '@ess/hooks/useContentWindow';
import { Sections } from '@ess/hooks/useOfferContentService';

import Tooltip from '@ess/ui/Tooltip';
import Chip, { ChipGroup } from '@ess/ui/Chip';
import FlexBox from '@ess/ui/FlexBox';
import Anchor from '@ess/ui/Anchor';

type AgentAttributesProps = {
  attributes: IExtAgentAttributes
  offerHash: string
}

const AgentAttributes = ({
  attributes,
  offerHash,
} : AgentAttributesProps) => {
  const { t } = useTranslation();
  const type = useSelector((state) => state.searchForm.type);
  const { openContentWindow } = useContentWindow(type);
  const { descriptionExists, attributes: attr } = attributes;

  const openHotelInfoWindow = (): void => {
    openContentWindow({
      offerHash,
      page: Sections.AgentAttributes,
    });
  };

  return (
    <>
      {((attr && attr?.length > 0) || descriptionExists) && (
        <FlexBox alignItems="center" width="100%">
          <FlexBox flexGrow={1}>
            {attr && attr?.length > 0 && (
              <ChipGroup>
                {attr?.map((item, index) => (
                  <Chip
                    key={`${index}_${item.name}`}
                    label={`#${item.name}`}
                    variant="border"
                    size="medium"
                  />
                ))}
              </ChipGroup>
            )}
          </FlexBox>
          {descriptionExists && (
            <Tooltip content={`${t('lbl_agentowndescription')}`}>
              <Anchor pl="small" onClick={openHotelInfoWindow}>
                <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#777777"/>
              </Anchor>
            </Tooltip>
          )}
        </FlexBox>
      )}
    </>
  );
};
export default AgentAttributes;
