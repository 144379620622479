import React, { CSSProperties, memo, useMemo } from 'react';
import { isEqual, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDoubleRight,
  faBedFront,
  faForkKnife,
  faGlobe,
  faHeart,
} from '@fortawesome/pro-solid-svg-icons';

import { CARD_STYLES, CONTENT_SERVICE_URL } from '@liveroom/constants';

import { LayeredIcons } from '@ess/ui/DuotoneFontAwesomeIcon';
import { Button } from '@ess/ui/Button';
import { Card } from '@ess/ui/Card';
import Tooltip from '@ess/ui/Tooltip';
import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

import { SupplementaryHotel } from '@tourop/components/OfferElements/SupplementaryHotel';
import { ImageSlider } from '@ess/components/ImageSlider';
import HotelStars from '@tourop/components/OfferElements/HotelStars';
import TransportIcon from '@tourop/components/OfferList/Offer/OfferElements/Transport';
import DateWithDuration from '@tourop/components/OfferList/Offer/OfferElements/DateWithDuration';
import OfferAttributesTags, {
  OfferAttributesTagsFunc,
} from '@tourop/components/OfferElements/OfferAttributes/OfferAttributesTags';

import { OfferIcon } from '@tourop/components/OfferElements/OfferIcon';

import { ImagesSizes } from '@ess/hooks/useFetchImages';
import TransportDetails from '@tourop/components/OfferElements/TransportDetails';
import { getTransportType } from '@ess/utils';
import { OWN_TRANSPORT } from '@ess/constants/transport';
import { Attributes } from './Attributes';

type OfferProps = {
  data: any;
  onClick: (rowid: number) => void;
  onFavouriteClick: (rowid: number) => void;
  isFavouritesEnabled: boolean | undefined;
  goToOffer?: (item: any) => void;
  isFavourite: boolean;
};

const ellipsisStyles: CSSProperties = {
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const Offer = memo(
  ({
    data,
    onClick,
    goToOffer = undefined,
    onFavouriteClick,
    isFavouritesEnabled,
    isFavourite,
  }: OfferProps) => {
    const { t } = useTranslation();
    const { Base, Accommodation } = data.offer;

    const destinationPath = useMemo(() => {
      const path = [Base?.XCountry?.Name, Base?.XRegion?.Name];

      if (Base?.XRegion?.Name !== Base?.XCity?.Name) {
        path.push(Base?.XCity?.Name);
      }

      const nonEmptyValues = path.filter((item) => !!item);

      return nonEmptyValues.length ? nonEmptyValues.join(' / ') : '';
    }, [Base]);
    const transportType = getTransportType(data?.offer?.Transport);

    const offerDetails = useMemo(
      () => [
        {
          key: 'serviceName',
          icon: faForkKnife,
          value: Accommodation?.Service?.Name,
        },
        {
          key: 'roomName',
          icon: faBedFront,
          value: Accommodation?.Room?.Name,
        },
        {
          key: 'operator',
          icon: faGlobe,
          value: Base?.OperatorDesc ?? Base?.Operator,
        },
      ],
      [],
    );

    const onButtonClickHandler = () => {
      if (goToOffer) {
        goToOffer(data);
      } else if (data?.extra?.shortLink) {
        window.open(data.extra.shortLink, '_blank');
      }
    };

    const addToFavourite = () => {
      onFavouriteClick(data.rowid);
    };

    const transportContent = (
      <>
        {transportType === OWN_TRANSPORT ? (
          <FlexBox p="8px">
            <Text>{t('own_transport')}</Text>
          </FlexBox>
        ) : (
          <FlexBox
            width={{
              xxs: '320px',
              xs: '360px',
              lg: '450px',
              md: '450px',
              _: '450px',
            }}
          >
            <FlexBox p="small" width="100%">
              <TransportDetails offerData={data.offer} />
            </FlexBox>
          </FlexBox>
        )}
      </>
    );

    return (
      <Card style={CARD_STYLES}>
        <FlexBox position="relative">
          {Accommodation?.Supplementary?.length && (
            <SupplementaryHotel data={Accommodation?.Supplementary[0]} />
          )}

          {['parking', 'insurance'].includes(data.offerType as string) ? (
            <OfferIcon offerType={data.offerType as string} height="190px" />
          ) : (
            <ImageSlider
              height={190}
              serviceUrl={CONTENT_SERVICE_URL}
              imageSize={ImagesSizes.Medium}
              image={Base?.ThumbUrl}
              operator={Base?.Operator}
              hotelCode={Accommodation?.Code}
              arrowStyle="light"
            />
          )}
          {isFavouritesEnabled && (
            <FlexBox
              position="absolute"
              top="10px"
              right="10px"
              zIndex={10}
              onClick={addToFavourite}
              style={{
                cursor: 'pointer',
              }}
            >
              <LayeredIcons>
                <FontAwesomeIcon
                  icon={faHeart}
                  color={isFavourite ? '#FF385C' : 'black'}
                  opacity={isFavourite ? 0.9 : 0.6}
                />
                <FontAwesomeIcon icon={faHeartLight} color="white" />
              </LayeredIcons>
            </FlexBox>
          )}
          <FlexBox position="absolute" bottom="10px" left="10px" zIndex={10}>
            {Accommodation?.ExtTripAdvisor
            && toNumber(Accommodation?.ExtTripAdvisor?.rating) !== 0 ? (
              <Tooltip theme="bubble-wide" content={t('tripadvisor_note')}>
                <FlexBox
                  width="50px"
                  height="30px"
                  bg="#12a180"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={6}
                >
                  <Text
                    fontSize="12px"
                    fontWeight="bold"
                    color="white"
                    whiteSpace="nowrap"
                  >
                    {`${
                      Accommodation.ExtTripAdvisor.rating
                        .toString()
                        .includes('.')
                        ? Accommodation.ExtTripAdvisor.rating
                        : `${Accommodation.ExtTripAdvisor.rating}.0`
                    } / 5`}
                  </Text>
                </FlexBox>
              </Tooltip>
              ) : null}
          </FlexBox>
        </FlexBox>
        <FlexBox
          p="small"
          height="100%"
          flexDirection="column"
          onClick={() => onClick(data.rowid)}
        >
          <FlexBox flexDirection="column" height="100%">
            <FlexBox mb="medium" flexDirection="column" flexGrow={1}>
              {destinationPath && (
                <FlexBox mb="tiny">
                  <Text color="textSecondaryColor" style={ellipsisStyles}>
                    {destinationPath}
                  </Text>
                </FlexBox>
              )}
              <FlexBox alignItems="center">
                <Text
                  mr="tiny"
                  fontSize="17px"
                  lineHeight={1.2}
                  fontWeight="bold"
                  style={ellipsisStyles}
                >
                  {Base?.XCode?.Name}
                </Text>
                {Accommodation?.Category && Accommodation.Category > 0 ? (
                  <HotelStars category={Accommodation.Category as number} />
                ) : null}
              </FlexBox>

              <FlexBox mt="medium">
                <FlexBox>
                  <TransportIcon
                    item={data.offer}
                    offerHashGetter={undefined}
                    content={transportContent}
                  />
                </FlexBox>
                <FlexBox>
                  <DateWithDuration item={data.offer} commonProps={undefined} />
                </FlexBox>
              </FlexBox>

              {offerDetails.map((item) => (item?.value ? (
                <FlexBox key={item.key} mt="small" alignItems="flex-start">
                  <FlexBox flexShrink={0} width={15} mr="7px">
                    <Text lineHeight="16px">
                      <FontAwesomeIcon icon={item.icon} />
                    </Text>
                  </FlexBox>
                  <Text lineHeight="16px" style={ellipsisStyles}>
                    {item.value}
                  </Text>
                </FlexBox>
              ) : null),
              )}

              <FlexBox mt="small" flexWrap="wrap">
                <OfferAttributesTags
                  offerAttributes={OfferAttributesTagsFunc(
                    t,
                    data.offer,
                    false,
                  )}
                  maxVisibleAttributes={2}
                />
              </FlexBox>
              {Accommodation?.Attributes?.length ? (
                <FlexBox mt="tiny">
                  <Attributes attributes={Accommodation?.Attributes} />
                </FlexBox>
              ) : null}
            </FlexBox>

            {Base?.Price?.FirstPerson?.amount && (
              <FlexBox
                width="100%"
                mb="medium"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Text mr="tiny" fontSize="22px" fontWeight="bold" color="price">
                  {Math.round(Number(Base.Price.FirstPerson.amount))}
                </Text>
                <Text fontWeight="bold" color="price" style={{ lineHeight: 1 }}>
                  {`${Base.Price.FirstPerson.currency} / os.`}
                </Text>
              </FlexBox>
            )}

            <Button
              label={t('lbl_go_to_offer')}
              onClick={onButtonClickHandler}
              endIcon={<FontAwesomeIcon icon={faAngleDoubleRight} size="1x" />}
            />
          </FlexBox>
        </FlexBox>
      </Card>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps?.data, nextProps?.data)
    && isEqual(prevProps?.isFavourite, nextProps?.isFavourite),
);

export { Offer };
