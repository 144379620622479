import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { motion } from 'framer-motion';
import { isNumber } from 'lodash-es';

/**
 * Modal Base Styles
 */

const BaseModalStyles = css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 100%;
    min-width: 320px;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    transform: translate(-50%, -50%);
`;

/**
 * Wait Modal Styles
 */

const WaitModal = styled.div<{ width: number }>`
    ${BaseModalStyles}
    align-items: center;
    justify-content: center;
    min-height: 150px;
    max-width: ${(props) => `${props.width}px`};
    background-color: ${({ theme }) => theme.colors.blue};
    z-index: ${({ theme }) => theme.zIndex.modal};
    border-radius: ${({ theme }) => theme.radii.modal};
`;

/**
 * Default Modal Styles
 */
const BaseHeaderStyles = css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    user-select: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
`;

const Modal__Header = styled.div<{ themeType?: string }>`
    ${BaseHeaderStyles}
    background-color: ${({ theme, themeType }) => (themeType === 'white' ? theme.colors.white : theme.colors.blue)};
    color: ${({ theme, themeType }) => (themeType === 'white' ? theme.colors.textColor : theme.colors.white)};
    padding-left: ${({ theme }) => theme.space.small};
    border-bottom: ${({ themeType, theme }) => (themeType === 'white' ? '1px solid #dedede' : 'none')};
    height: 50px;
`;

const Modal__Notification_Header = styled.div<{ themeType?: string }>`
    ${BaseHeaderStyles}
    background-color: rgb(26, 159, 255);
    color: white;
    padding-left: 10px;
    border-bottom: none;
    height: 40px;
`;

const Modal__Title = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.head};
    line-height: ${({ theme }) => theme.lineHeights.title};
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 40px);
    overflow: hidden;
`;

const Modal__CloseIcon = styled.div`
    ${(props) => (props.hidden ? '' : 'display: flex;')};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 100%;
    transition: all .3s ease-in;
    font-size: ${({ theme }) => theme.fontSizes.title};
`;

const Modal__Content = styled.div`
    position: relative;
    overflow: auto;
    backface-visibility: hidden;

    input, select {
      height: auto;
    }
`;

const BaseControlStyles = css`
    display: flex;
    align-items: center;
    border-top: 1px solid #dedede;
    flex-shrink: 0;
`;

const Modal__Controls = styled.div<{ isConfirmModal?: boolean }>`
    ${BaseControlStyles}
    height: 50px;

    ${({ isConfirmModal, theme }) => isConfirmModal && `
      padding: 0 ${theme.space.small};
      justify-content: flex-end;
    `}
`;

const Modal__Notification_Controls = styled.div`
    ${BaseControlStyles}
    height: 40px;
    padding: 0 10px;
    justify-content: flex-end;
`;

const Modal = styled(motion.div)<{ ref: any, width: any, maxHeight: number | undefined, themeType?: string, zIndex?: number }>`
    ${BaseModalStyles};
    max-height: ${(props) => (isNumber(props.maxHeight) ? `${props.maxHeight}px` : props.maxHeight)};
    max-width: ${(props) => (isNumber(props.width) ? `${props.width}px` : props.width)};
    background-color: ${({ theme, themeType }) => (themeType === 'white' ? theme.colors.white : theme.colors.lightGray)};
    z-index: ${({ theme, zIndex }) => zIndex || theme.zIndex.modal};
    border-radius: ${({ theme }) => theme.radii.modal};

    ${Modal__CloseIcon} {
      color: ${({ theme, themeType }) => (themeType !== 'white' ? theme.colors.white : '#666f7d')};

      &:hover {
        background-color: ${({ theme, themeType }) => (themeType !== 'white' ? darken(0.1, theme.colors.blue) : 'transparent')};
        color:  ${({ theme, themeType }) => (themeType !== 'white' ? theme.colors.white : theme.colors.textColor)};
      }
    }
`;

const Modal__Notification = styled.div`
    ${BaseModalStyles};
    border-radius: var(--modal-border-radius);
    z-index:9999;
    background-color: rgb(241, 243, 244);
    max-width: 400px;
    max-height:738px;
    width: 100%;
`;
const ModalOverlay_Notification = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 9998;
`;
export const Styled = {
  Modal,
  Modal__Header,
  Modal__Title,
  Modal__CloseIcon,
  Modal__Content,
  Modal__Controls,
  WaitModal,
  Modal__Notification,
  Modal__Notification_Header,
  Modal__Notification_Controls,
  ModalOverlay_Notification,
};
