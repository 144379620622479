import { get } from 'lodash-es';

const TOUROP_API_OBJECT = '__REACT_APP_TOUROP_API__';

/**
 * API Base
 */
export const PERSISTED_CONFIG_ITEM = 'MXReactAppConfig';
/**
 * TOUROP API
 */
export const CLOUD_SERVICE = `https://merlinx-cloud.com`;
export const BASE_URL = get(window, `${TOUROP_API_OBJECT}.urlBase`, `${window.location.origin}/`);
export const CONTENT_SERVICE_URL =  get(window, `${TOUROP_API_OBJECT}.urlContentService`, `${CLOUD_SERVICE}/wcs/`);

export const SEARCH_API =  get(window, `${TOUROP_API_OBJECT}.urlV5`, `${CLOUD_SERVICE}/mwsv5/`);

export const DEVELOPMENT = process.env.NODE_ENV === 'development';

// Endpoints
export const OFFER_RESERVATION_ENDPOINT = 'reserve.php';
export const BOOKING_DETAILS_ENDPOINT = get(window, `${TOUROP_API_OBJECT}.urlBookingDetails`, '/bookingdisplay/');
export const MERLINX_MASK_ENDPOINT = 'show_mask.php';
export const CHECK_BOOKING_SWAP_ACTION = 'checkBookingSwap';

export const AGENT_ATTRIBUTES_LIST_ACTION = 'listhotel';
export const AGENT_ATTRIBUTES_SAVE_DESC_ACTION = 'setdesc';
export const AGENT_ATTRIBUTES_SAVE_ATTRIBUTES_ACTION = 'setattr';

// Assets
export const OPERATOR_LOGO = `https://datacfstatic.merlinx.pl/operlogo/`;

// Endpoints
export const OFFER_DETAILS = 'v5/data/travel/details';
export const HOTEL_AUTO_COMPLETE_ENDPOINT = 'v5/data/travel/autocomplete';
export const CHECK_ONLINE_ENDPOINT = 'v5/data/travel/checkonline';
export const FORM_CHECK_ONLINE_ENDPOINT = 'v5/data/travel/checkdetailsonline';
export const SEARCHBASE_ENDPOINT = 'v5/data/travel/searchbase';
export const SEARCH_ENDPOINT = 'v5/data/travel/search';
export const TOKEN_ENDPOINT = '/trip/search/gettoken/';
export const CHECK_BOOKING_STATUS_ENDPOINT  = '/v5/booking/status';

/**
 * Answers from MERLINX AJAX
 */

export const MX_ANSWER_SUCCESS = 'SUCCESS';
export const MX_ANSWER_FAIL = 'FAIL';

/**
 * BASKET SETINGS
 */

export const FIXED_HEADER_HEIGHT = 88;
/**
 * EXTERNAL URL
 */

export const BROWSER_UPDATE = 'https://browser-update.org/update-browser.html';
export const GOOGLE_MAPS_API_STATICMAP = 'https://maps.googleapis.com/maps/api/staticmap';
export const GOOGLE_MAPS_API_TIMEZONE = 'https://maps.googleapis.com/maps/api/timezone/json?';

/**
 * DATE FORMATS
 */

export const DATE_DISPLAY_SHORT_FORMAT = 'DD.MM';
export const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY';
export const TRANSPORT_DATE_DISPLAY_FORMAT = 'dddd DD.MM.YYYY';
export const DATE_REQUEST_FORMAT = 'YYYY-MM-DD';

/**
 * SERVICE TIMEOUTS
*/
export const DEFAULT_TIMEOUT = 10;
export const SEARCH_TIMEOUT = 10;
export const ONLINE_SERVICE_TIMEOUT = 65;
export const BOOKING_SERVICE_TIMEOUT = 125;
