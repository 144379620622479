import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { includes, toNumber } from 'lodash-es';
import * as Sentry from '@sentry/react';

import { SEARCH_API, SEARCH_ENDPOINT } from '@ess/constants/api';

import { IGroupedListResponseItem } from '@ess/v5-data-provider/interfaces';
import { promiseRequest } from '@ess/v5-data-provider/request';

import { SearchBaseContext } from '@ess/context/SearchBaseContext';

import useFavouriteHotels from '@ess/hooks/useFavouriteHotels';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import { Modal } from '@ess/ui/Modal/ModalsTypes';
import { Button } from '@ess/ui/Button';
import { Row, Col } from '@ess/ui/FlexGrid';
import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';

import { Styled } from './FavouriteHotelsList.styles';

type FavouriteHotelsListProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const FavouriteHotelsList = ({ isOpen, onClose, onOpen }: FavouriteHotelsListProps) => {
  const { t } = useTranslation();
  const { getOperatorDefaultValue } = useContext(SearchBaseContext);
  const { favouriteHotels, remove } = useFavouriteHotels();
  const [items, setItems] = useState<IGroupedListResponseItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

  const onRemoveHandler = (XCode: any) => {
    const { Id, Name } = XCode;

    setItems((state) => (
      [...state].filter((item) => item.groupKeyValue !== Id.toString())),
    );

    remove(Id.toString(), Name);
  };

  const fetchFavouriteHotels = () => {
    const XCodes = favouriteHotels.map((item: string) => toNumber(item));

    setIsLoading(true);

    (async () => {
      try {
        const request = await promiseRequest(`${SEARCH_API}${SEARCH_ENDPOINT}`, {
          conditions: {
            search: {
              Base: {
                Operator: getOperatorDefaultValue(),
                ParticipantsList: [
                  {
                    code: 'ADULT',
                    roomIndex: 0,
                  },
                  {
                    code: 'ADULT',
                    roomIndex: 0,
                  },
                ],
              },
              Accommodation: {
                XCode: XCodes,
              },
            },
          },
          views: {
            groupedList: {
              fieldList: [
                'Base.XCountry',
                'Base.XRegion',
                'Base.XCity',
                'Accommodation.XCode',
              ],
              limit: 500,
              orderBy: ['Base.Price.FirstPerson'],
            },
          },
        }, 3);

        const items = request?.groupedList?.items;

        setItems(items ? Object.keys(items).map((groupKeyValue) => items[groupKeyValue]) : []);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  useOnClickOutside(modalElement, onClose);

  useEffect(() => {
    if (isOpen) {
      fetchFavouriteHotels();
    }
  }, [isOpen]);

  return (
    <Modal
      ref={setModalElement as any}
      title={t('lbl_favourite_hotels')}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      width={1000}
      theme="white"
      controls={(
        <FlexBox
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          px="small"
        >
          <Button
            size="small"
            width={90}
            label={t('lbl_close')}
            onClick={onClose}
            variant="secondary"
          />
        </FlexBox>
      )}
    >
      <Styled.FavouriteHotels__Wrapper>
        {isLoading ? (
          <Styled.FavouriteHotels__Loader>
            <Loader color="darkGray" size="32px"/>
          </Styled.FavouriteHotels__Loader>
        ) : items.length > 0 ? (
          <Row gapX={5} gapY={5} flexWrap="wrap" alignItems="flex-start">
            {items.map((item) => {
              const { Accommodation, Base } = item.offer;
              const city = Base?.XCity?.Name && Base?.XRegion?.Name !== Base.XCity.Name ? Base.XCity.Name : false;

              return includes(favouriteHotels, Accommodation?.XCode?.Id?.toString()) ? (
                <Col
                  key={item.groupKeyValue}
                  width={{
                    lg: 4 / 12,
                    md: 6 / 12,
                    xs: 1,
                    xxs: 1,
                  }}
                >
                  <Styled.FavouriteHotels__Item>
                    <Styled.FavouriteHotels__Item__Name>
                      {Accommodation?.XCode?.Name}
                    </Styled.FavouriteHotels__Item__Name>
                    <Styled.FavouriteHotels__Item__Region>
                      {`${Base?.XCountry?.Name} / ${Base?.XRegion?.Name}${city ? ` / ${city}` : ''}`}
                    </Styled.FavouriteHotels__Item__Region>
                    <Styled.FavouriteHotels__Item__RemoveIcon onClick={() => onRemoveHandler(Accommodation?.XCode)}>
                      <FontAwesomeIcon icon={faTimes} size="1x"/>
                    </Styled.FavouriteHotels__Item__RemoveIcon>
                  </Styled.FavouriteHotels__Item>
                </Col>
              ) : null;
            })}
          </Row>
        ) : (
          <Styled.FavouriteHotels__Empty>
            {t('favourite_hotels_empty')}
          </Styled.FavouriteHotels__Empty>
        )}
      </Styled.FavouriteHotels__Wrapper>
    </Modal>
  );
};

export { FavouriteHotelsList };
