import React, {
  useCallback, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';

import { useFetchImages, ImagesSizes } from '@ess/hooks/useFetchImages';

import { NoPrint, PrintOnly } from '@ess/react-print';

import { SwiperContainer, SwiperSlide } from '@ess/ui/Swiper';
import Skeleton from '@ess/ui/Skeleton';
import FlexBox from '@ess/ui/FlexBox';
import Img from '@ess/ui/Image';

import { NavigationArrow } from './NavigationArrow';

import { Styled } from './ImageSlider.styles';

type ImageSliderProps = {
  image?: string | undefined;
  operator: string;
  xCode?: number | undefined;
  hotelCode: string;
  showCounter?: boolean;
  imageSize?: ImagesSizes;
  height?: number;
  width?: number;
  onClick?: (event?: React.MouseEvent) => void;
  serviceUrl?: string;
  lazyFetch?: boolean;
  arrowStyle?: string;
}

const ImageSlider = ({
  operator,
  hotelCode,
  showCounter = false,
  onClick = undefined,
  arrowStyle = 'regular',
  lazyFetch = true,
  xCode = undefined,
  height = undefined,
  width = undefined,
  serviceUrl = undefined,
  image = undefined,
  imageSize = ImagesSizes.Full,
}: ImageSliderProps) => {
  const swiperRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    isLoading,
    isReady,
    data,
    fetch,
  } = useFetchImages({
    lazy: lazyFetch,
    serviceUrl,
    imageSize,
    hotelCode,
    xCode,
    operator,
  });

  /**
   * Next image handler.
   * @param event
   */
  const nextImageHandler = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    if (!data.length) {
      (async () => {
        await fetch();

        setTimeout(() => {
          swiperRef?.current?.swiper?.slideNext();
        }, 250);
      })();
    } else {
      swiperRef?.current?.swiper?.slideNext();
    }
  }, [data]);

  /**
   * Previous image handler.
   */
  const previousImageHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    swiperRef?.current?.swiper?.slidePrev();
  };

  const fetchImagesIfNeeded = () => {
    if (!data.length) {
      fetch();
    }
  };

  const onActiveIndexChange = (swiper: any) => {
    setCurrentIndex(swiper?.activeIndex ?? 0);
  };

  const onImageError = () => {
    setIsDisabled(true);
  };

  /**
   * Slider click handler.
   */
  const onSliderClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  if (isLoading) {
    return (
      <Styled.ImageSlider
        height={height}
        width={width}
      >
        <FlexBox position="absolute" width="100%" height="100%">
          <Skeleton width="100%" height="100%">
            <FontAwesomeIcon icon={faImage} color="#bfbfbf" size="4x"/>
          </Skeleton>
        </FlexBox>
      </Styled.ImageSlider>
    );
  }

  return (
    <Styled.ImageSlider
      height={height}
      width={width}
      onClick={onSliderClick}
    >
      <>
        {(data.length > 0 || (image && !isLoading)) && (
          <PrintOnly>
            <Img
              alt={operator}
              width="100%"
              height="100%"
              loading="lazy"
              src={data[0] ?? image}
            />
          </PrintOnly>
        )}
      </>
      <NoPrint>
        <FlexBox position="relative" width="100%" height="100%">
          {showCounter && data.length > 0 && (
          <Styled.ImageSlider__Counter>
            {`${currentIndex + 1} / ${data.length}`}
          </Styled.ImageSlider__Counter>
          )}
          <SwiperContainer
            ref={swiperRef}
            onDragEnter={fetchImagesIfNeeded}
            onActiveIndexChange={onActiveIndexChange}
            onClick={fetchImagesIfNeeded}
          >
            {isReady && data.length ? data?.map((_, index) => (
              <SwiperSlide key={`image-slide-${index}`} style={{ width: '100%' }}>
                <Img
                  alt={operator}
                  width="100%"
                  height="100%"
                  loading="lazy"
                  src={data[index]}
                  onError={onImageError}
                  imageValidationEnabled={index === 0}
                />
              </SwiperSlide>
            )) : image && !isLoading ? (
              <SwiperSlide>
                <Img
                  alt={operator}
                  width="100%"
                  height="100%"
                  loading="lazy"
                  src={image}
                  onError={onImageError}
                />
              </SwiperSlide>
            ) : null}
          </SwiperContainer>

          {((isReady && data.length > 0) || lazyFetch) && !isDisabled && (
          <Styled.ImageSlider__Controls>
            {currentIndex > 0 && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="left" onClick={previousImageHandler}/>
            )}
            {(!isReady || currentIndex < data.length - 1) && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="right" onClick={nextImageHandler}/>
            )}
          </Styled.ImageSlider__Controls>
          )}
        </FlexBox>
      </NoPrint>
    </Styled.ImageSlider>
  );
};

export {
  ImageSlider,
};
