import React, { memo } from 'react';
import { has } from 'lodash-es';

import { IDictionary } from '@ess/types';
import { IOfferAccommodation } from '@ess/v5-data-provider/interfaces';

import FullRating from './FullRating';
import NoteOnly from './NoteOnly';

const DEFAULT_VIEW = 'fullRating';

const VIEWS: IDictionary<(props: any) => React.ReactComponentElement<any>> = {
  fullRating: (props) => <FullRating {...props}/>,
  noteOnly: (props) => <NoteOnly {...props}/>,
};

type TripAdvisorNoteViewTypes = 'fullRating' | 'noteOnly';

type TripAdvisorNoteProps = {
  data: IOfferAccommodation['ExtTripAdvisor']
  type?: TripAdvisorNoteViewTypes
  onClick?: () => void
}

const TripAdvisorNote = ({
  type = DEFAULT_VIEW as TripAdvisorNoteViewTypes,
  onClick = undefined,
  ...props
}: TripAdvisorNoteProps) => (
  <>
    {type && has(VIEWS, type)
      ? (VIEWS[type]({ ...props, onClick }))
      : (VIEWS[DEFAULT_VIEW]({ ...props, onClick }))}
  </>
);

export default memo(TripAdvisorNote);
