import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { find, has, includes } from 'lodash-es';
import { isMobileOnly } from 'react-device-detect';

import { IOption } from '@ess/types';

import Loader from '@ess/ui/Loader';

import Option from './Option';

import { Styled } from '../MultiSelectV2.styles';

type OptionsListProps = {
  options: any
  isLoading: boolean
  isFavouritesEnabled: boolean
  onClick: (value: string | number) => void
  onStarClick: (event: React.MouseEvent, option: IOption) => void
  checkedOptions: any[]
  favouriteOptions: any[]
}

const OptionsList = ({
  options,
  isLoading,
  isFavouritesEnabled,
  onClick,
  onStarClick,
  checkedOptions,
  favouriteOptions,
}: OptionsListProps) => {
  const { t } = useTranslation();

  /**
   * Indicates if option is checked.
   */
  const isCheckedOption = useCallback(
    (value: string | number) => includes(checkedOptions, value), [checkedOptions],
  );

  /**
   * Indicates if option is marked as favourite.
   */
  const isFavouriteOption = useCallback(
    (value: string | number) => find(favouriteOptions, (item) => item.value === value), [favouriteOptions],
  );

  return (
    <>
      {isLoading ? (
        <Styled.MultiSelect__Loader>
          <Loader size="15px"/>
        </Styled.MultiSelect__Loader>
      ) : (
        <>
          {options.length > 0 ? (
            <>
              <Styled.MultiSelect__Options>
                {options.map((option: any, index: number) => {
                  const hasGroup = has(option, 'options');

                  return (
                    <Styled.MultiSelect__Options__Inner key={hasGroup ? `group_${index}` : option.value}>
                      {hasGroup ? (
                        <>
                          <Styled.MultiSelect__Options__Group>
                            {option.label}
                          </Styled.MultiSelect__Options__Group>
                          {option.options.map((groupedOption: any) => (
                            <Option
                              key={groupedOption.value}
                              option={groupedOption}
                              onClick={onClick}
                              onStarClick={onStarClick}
                              isChecked={isCheckedOption(groupedOption.value)}
                              isFavourite={isFavouriteOption(groupedOption.value)}
                              isFavouritesEnabled={isFavouritesEnabled}
                            />
                          ))}
                        </>
                      ) : (
                        <Option
                          option={option}
                          onClick={onClick}
                          onStarClick={onStarClick}
                          isChecked={isCheckedOption(option.value)}
                          isFavourite={isFavouriteOption(option.value)}
                          isFavouritesEnabled={isFavouritesEnabled}
                        />
                      )}
                    </Styled.MultiSelect__Options__Inner>
                  );
                })}
              </Styled.MultiSelect__Options>
            </>
          ) : (
            <Styled.MultiSelect__Empty isMobileOnly={isMobileOnly}>
              {t('no_options')}
            </Styled.MultiSelect__Empty>
          )}
        </>
      )}
    </>
  );
};

export default OptionsList;
