import React, { SyntheticEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { isMobile } from 'react-device-detect';
import { has } from 'lodash-es';

import useKeyPress from '@ess/hooks/useKeyPress';
import useClickPreventionOnDoubleClick from '@ess/hooks/useClickPreventionOnDoubleClick';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import useWindowSize from '@ess/hooks/useWindowSize';

import Item from './Item';

import { Styled } from './Dropdown.styles';

type Placement = 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'
    | 'right-start' | 'right-end' | 'left-start' | 'left-end';

export type DropdownItem = {
  label: string
  value: string | number
  disabled?: boolean
  icon?: React.ReactNode
}

export type DropdownGroup = {
  group: string,
  label?: string,
  options: DropdownItem[]
}

export type DropdownProps = {
  target: React.ReactElement;
  options: DropdownItem[] | DropdownGroup[];
  menuMinWidth?: number;
  menuMaxWidth?: number;
  appendTo?: Element | null;
  placement?: Placement;
  offsetTop?: number | null | undefined;
  isOpen?: boolean;
  isDisabled?: boolean;
  onItemClick?: (value: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  header?: React.ReactElement;
  hoverOpensDropdown?: boolean;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
};

const Dropdown = ({
  target,
  options,
  appendTo = undefined,
  header = undefined,
  placement = 'bottom-end',
  offsetTop = 5,
  menuMaxWidth = 250,
  menuMinWidth = 150,
  isOpen = false,
  isDisabled = false,
  onItemClick = undefined,
  onClose = undefined,
  onOpen = undefined,
  hoverOpensDropdown = false,
}: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean | undefined>(false);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [isDropdownHovered, setIsDropdownHovered] = useState<boolean>(false);
  const [isHandlerHovered, setIsHandlerHovered] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string>('');
  const escapePress = useKeyPress('Escape', targetElement);
  const hasIcons = options.some((item) => (
    ('group' in item) ? item.options.some((item) => (has(item, 'icon'))) : has(item, 'icon')
  ));
  const hoverOpensDropdownNonMobile = hoverOpensDropdown && !isMobile;
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, offsetTop],
        },
      },
    ],
  }) as any;
  const { height } = useWindowSize();

  /**
   * Dropdown item click handler.
   * @param event
   * @param item
   */
  const dropDownItemClickHandler = (event: SyntheticEvent, item: DropdownItem): void => {
    event.stopPropagation();

    const { value, disabled } = item;

    setIsDropdownOpen(disabled);

    if (onItemClick && !disabled) {
      onItemClick(value);
    }
  };

  /**
   * Toggle dropdown handler.
   * @param event
   */
  const toggleDropdownHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!hoverOpensDropdownNonMobile && !isDisabled) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  useOnClickOutside(popperElement, () => {
    setIsDropdownOpen(false);
  }, targetElement !== null ? [targetElement] : []);

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (height < 600) {
      setMaxHeight('240px');
    } else {
      setMaxHeight('');
    }
  }, [height]);

  useEffect(() => {
    if (!isDropdownOpen && onClose) {
      onClose();
    } else {
      targetElement?.focus();

      if (onOpen && isDropdownOpen) {
        onOpen();
      }
    }

    if (!isDropdownOpen) {
      setIsDropdownHovered(false);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (escapePress && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  }, [escapePress]);

  useEffect(() => {
    if (hoverOpensDropdownNonMobile && !isDisabled) {
      if (!isHandlerHovered) {
        const timer = setTimeout(() => {
          if (!isDropdownHovered) {
            setIsDropdownOpen(false);
          }
        }, 100);
        return () => clearTimeout(timer);
      }
      setIsDropdownOpen(true);

      if (!isDropdownHovered) {
        const intendedReleaseTimer = setTimeout(() => {
          if (!isDropdownHovered && !isHandlerHovered) {
            setIsDropdownOpen(false);
          }
        }, 50);
        return () => clearTimeout(intendedReleaseTimer);
      }
    }

    return () => null;
  }, [isHandlerHovered, isDropdownHovered]);

  const handlerConditionalProps: React.HTMLAttributes<HTMLButtonElement> = {};
  if (hoverOpensDropdownNonMobile) {
    handlerConditionalProps.onMouseEnter = () => setIsHandlerHovered(true);
    handlerConditionalProps.onMouseLeave = () => setIsHandlerHovered(false);
  }

  const [handleClick] = useClickPreventionOnDoubleClick(
    ({ event, item } : {event: SyntheticEvent, item: DropdownItem}) => {
      dropDownItemClickHandler(event, item);
    },
  );

  return (
    <>
      {React.cloneElement(target, {
        onClick: toggleDropdownHandler,
        ref: setTargetElement,
        ...handlerConditionalProps,
      })}

      {createPortal(
        <>
          {isDropdownOpen && (
            <Styled.Dropdown
              ref={setPopperElement}
              menuMinWidth={menuMinWidth as number}
              menuMaxWidth={menuMaxWidth as number}
              maxHeight={maxHeight}
              style={styles.popper}
              onMouseEnter={() => setIsDropdownHovered(true)}
              onMouseLeave={() => setIsDropdownHovered(false)}
              onClick={(event: React.MouseEvent) => event.stopPropagation()}
              {...attributes.popper}
            >
              {header && header}
              {options.map((data, index) => {
                if ('group' in data) {
                  return (
                    <Styled.Dropdown__Group
                      key={data.group}
                      isFirstGroup={index === 0}
                    >
                      {data?.label && (
                        <Styled.Dropdown__Group__Name>
                          {data.label}
                        </Styled.Dropdown__Group__Name>
                      )}
                      <Styled.Dropdown__Group__Items>
                        {data.options.map((item) => (
                          <Item
                            key={item.value}
                            data={item}
                            onClick={handleClick}
                            hasIcons={hasIcons}
                          />
                        ))}
                      </Styled.Dropdown__Group__Items>
                    </Styled.Dropdown__Group>
                  );
                }
                return (
                  <Item
                    key={`${index}_${data.value}`}
                    data={data}
                    onClick={handleClick}
                    hasIcons={hasIcons}
                  />
                );
              })}
            </Styled.Dropdown>
          )}
        </>,
        (!appendTo ? document.querySelector('body') : appendTo) as HTMLElement,
      )}
    </>
  );
};

export default Dropdown;
